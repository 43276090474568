<template>
  <div>
    <div class="filters">
      <div class="checkboxes">
        <div style="display:flex;">
          <v-checkbox v-model="no_showerheads" class="checkbox gray-text" id="cnsh">
            <label for="cnsh" class="nowrap"> {{ $t("no_showerheads") }}</label>
          </v-checkbox>
          <v-checkbox id="cnd" v-model="no_devices" class="checkbox gray-text">
            <label for="cnd" class="nowrap"> {{ $t("no_devices") }} </label>
          </v-checkbox>
        </div>

        <div>
          <v-chip
            style="margin-bottom: 20px"
            v-if="device"
            class="deviceChip"
            closable
            label
            @click:close="device = ''"
          >
            {{ $t("device") + " : " + device }}
          </v-chip>
        </div>
      </div>

      <div class="search-input">
        <div class="input-icons">
          <i>
            <svgIconNonReactive type="mdi" class="gray-icon" :path="pathMagnify"></svgIconNonReactive>
          </i>

          <input v-on:keyup="callSearch" v-model="search" class="input-field" type="text" :placeholder="$t('search')" />
          <i id="closeCross" v-if="search" v-on:click="search = ''">
            <svgIconNonReactive type="mdi" :path="pathClose"></svgIconNonReactive>
          </i>
        </div>
      </div>
    </div>

    <div class="ag-grid-div">
      <ag-grid-vue oncontextmenu="return false;" style="width: 100%; height: 100%" class="ag-theme-material" id="usersGrid"
        :gridOptions="gridOptions" @grid-ready="onGridReady" :columnDefs="columnDefs" :defaultColDef="defaultColDef"
        :components="components" :rowBuffer="rowBuffer" :rowSelection="rowSelection" :rowDeselection="true"
        :rowModelType="rowModelType" :paginationPageSize="paginationPageSize" :cacheOverflowSize="cacheOverflowSize"
        :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests" :infiniteInitialRowCount="infiniteInitialRowCount"
        :frameworkComponents="frameworkComponents">
      </ag-grid-vue>
    </div>

    <div>

      <span class="row-count light-gray-text">
        <pre><code><span>{{ currentRow }}/</span>{{ lastRow }}</code> {{ $t('results') }}</pre>
      </span>
    </div>
    <v-snackbar :timeout="2000" v-model="copied" persistent>
      <span style="color: white">{{ $t("copied") }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import DateRenderer from "../components/cellRenderers/DateRenderer.vue";
import LastSeenRenderer from "../components/cellRenderers/LastSeenRenderer.vue";
import NbShowerheadsRenderer from "../components/cellRenderers/NbShowerheadsRenderer.vue";
import NbMetersRenderer from "../components/cellRenderers/NbMetersRenderer.vue";
import EmailRenderer from "../components/cellRenderers/EmailRenderer.vue";
import HeadersRenderer from "../components/cellRenderers/HeadersRenderer.vue";
import DetailButtonRenderer from "../components/cellRenderers/DetailButtonRenderer.vue";
import IconRenderer from "../components/cellRenderers/IconRenderer.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClose, mdiMagnify } from "@mdi/js";

import { markRaw } from "vue";
const svgIconNonReactive = markRaw(SvgIcon);

export default {
  inject: ["$global_error", "$apiHydrao"],
  name: "Users",
  components: {
    "ag-grid-vue": AgGridVue,
    svgIconNonReactive,
  },
  data: function () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      components: null,
      rowBuffer: null,
      rowSelection: null,
      rowModelType: null,
      paginationPageSize: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      frameworkComponents: null,

      currentRow: "-",
      lastRow: "-",
      search: "",
      device: "",
      searchTimeout: null,
      query_row: null,
      no_showerheads: false,
      no_devices: false,
      copied: false,
      pathClose: mdiClose,
      pathMagnify: mdiMagnify,
      isGridDestroyed: false,
    };
  },
  beforeMount() {
    this.gridOptions = {
      cacheBlockSize: 25,
      headerHeight: 25,
      suppressBrowserResizeObserver: true, // see https://github.com/ag-grid/ag-grid/issues/6562

      onBodyScroll: () => {
        if (!this.isGridDestroyed && this.gridApi) {
          let newCurrentRow = this.gridApi.getLastDisplayedRow() + 1;
          if (newCurrentRow != this.currentRow) {
            this.currentRow = newCurrentRow;
            this.updateQuery();
          }
        }
      },
      onSortChanged: () => {
        if (this.gridApi) {
          this.gridApi.ensureIndexVisible(0, "top");
          this.updateQuery();
        }
      },
      onRowClicked: (params) => {
        if (params.data && params.data.email) {
          this.$router.push({
            path:
              "users/" +
              window.btoa(unescape(encodeURIComponent(params.data.email))),
          });
        }
      },
      onFilterChanged: () => {
        if (this.gridApi) {
          this.gridApi.ensureIndexVisible(0, "top");
          this.updateQuery();
        }
      },
      onGridSizeChanged: (params) => {
        var grid_width = document.getElementById("usersGrid").offsetWidth;
        if (grid_width) {
          var columnsToShow = ["email", "detailsButtons"];
          var columnsToHide = [
            "registration_date",
            "last_seen",
            "nb_gateways",
            "nb_features",
            "nb_showerheads",
            "nb_meters",
          ];

          if (grid_width > 350) {
            columnsToShow.push("registration_date");
          }

          if (grid_width > 480) {
            columnsToShow.push("last_seen");
          }

          if (grid_width > 650) {
            columnsToShow.push("nb_showerheads");
          }

          if (grid_width > 940) {
            columnsToShow.push("nb_meters");
          }

          if (grid_width > 1020) {
            columnsToShow.push("nb_features");
          }

          if (grid_width > 1200) {
            columnsToShow.push("nb_gateways");
          }

          params.columnApi.setColumnsVisible(columnsToShow, true);
          params.columnApi.setColumnsVisible(
            columnsToHide.filter((col) => !columnsToShow.includes(col)),
            false
          );

          params.api.sizeColumnsToFit();
        }
      },
    };
    this.frameworkComponents = {
      dateRenderer: DateRenderer,
      lastSeenRenderer: LastSeenRenderer,
      nbShowerheadsRenderer: NbShowerheadsRenderer,
      nbMetersRenderer: NbMetersRenderer,
      emailRenderer: EmailRenderer,
      headersRenderer: HeadersRenderer,
      detailButtonRenderer: DetailButtonRenderer,
      iconRenderer: IconRenderer,
    };
    this.columnDefs = [
      {
        headerName: "email",
        headerComponent: "headersRenderer",
        field: "email",
        cellRenderer: "emailRenderer",
        onCellContextMenu: async (params) => {
          if (params.value) {
            this.copyAndShowSnackbar(params.value);
          }
        },
      },
      {
        headerName: "registration_date",
        headerComponent: "headersRenderer",
        field: "registration_date",
        cellRenderer: "dateRenderer",
        sort: "desc",
      },
      {
        headerName: "last_seen",
        headerComponent: "headersRenderer",
        field: "last_seen",
        cellRenderer: "lastSeenRenderer",
        maxWidth: 150,
      },
      {
        headerName: "nb_gateways",
        headerComponent: "headersRenderer",
        colId: "nb_gateways",
        valueGetter: (params) => {
          if (params.data && params.data.gateways) {
            return {
              value: params.data.gateways.length,
              icon: "mdiCellphone",
              list: params.data.gateways,
            };
          }
        },
        cellRenderer: "iconRenderer",
        maxWidth: 150,
      },
      {
        headerName: "nb_features",
        headerComponent: "headersRenderer",
        colId: "nb_features",
        valueGetter: (params) => {
          if (params.data && params.data.features) {
            return {
              value: params.data.features.length,
              icon: "mdiPuzzle",
              list: params.data.features,
              type: "feature",
            };
          }
        },
        cellRenderer: "iconRenderer",
        maxWidth: 150,
      },
      {
        headerName: "nb_showerheads",
        headerComponent: "headersRenderer",
        colId: "nb_showerheads",
        valueGetter: (params) => {
          if (params.data && params.data.nb_showerheads) {
            return {
              value: params.data.nb_showerheads,
              icon: "sh_icon",
              type: "nb_showerheads",
            };
          }
        },
        cellRenderer: "iconRenderer",
        maxWidth: 150,
      },
      {
        headerName: "nb_meters",
        headerComponent: "headersRenderer",
        colId: "nb_meters",
        valueGetter: (params) => {
          if (params.data && params.data.nb_meters) {
            return {
              value: params.data.nb_meters,
              icon: "meter_icon",
              type: "nb_meters",
            };
          }
        },
        cellRenderer: "iconRenderer",
        maxWidth: 150,
      },
      {
        headerName: "details",
        headerComponent: "headersRenderer",
        colId: "detailsButtons",
        valueGetter: (params) => {
          if (params.data && params.data.email) {
            return {
              value: params.data.email,
              show_email: false,
            };
          }
        },
        cellRenderer: "detailButtonRenderer",
        sortable: false,
        maxWidth: 60,
        minWidth: 60,
      },
    ];
    this.defaultColDef = {
      flex: 1,
      resizable: true,
      sortable: true,
      suppressMovable: true,
    };
    this.components = {};
    this.rowBuffer = 0;
    this.rowSelection = "multiple";
    this.rowModelType = "infinite";
    this.paginationPageSize = 25;
    this.cacheOverflowSize = 1;
    this.maxConcurrentDatasourceRequests = 2;
    this.infiniteInitialRowCount = 1;
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridOptions.rowModelType = this.rowModelType;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.handleQuery();
  },
  methods: {
   
    destroyGrid() {
      if (this.gridApi) {
        this.gridApi.destroy();
        this.isGridDestroyed = true;
      }
    },
    copyAndShowSnackbar(value) {
      navigator.clipboard.writeText(value).then(
        () => {
          this.copied = true;
        },
        (error) => {
          console.error("Could not copy text: ", error);
        }
      );
    },
    getQueryRow() {
      return this.query_row;
    },
    setQueryRow(value) {
      this.query_row = value;
    },
    handleQuery() {
      let query = this.$route.query;
      if ("nsh" in query && query.nsh === null) {
        this.no_showerheads = true;
      }
      if ("nd" in query && query.nd === null) {
        this.no_devices = true;
      }
      if (query.q) {
        try {
          let query_search = decodeURIComponent(escape(window.atob(query.q)));
          this.search = query_search;
          setTimeout(() => {
            clearTimeout(this.searchTimeout);
          }, 0);
        } catch (e) {
          this.$global_error.newError(
            "Invalid 'q' param, must be base 64 encoded"
          );
        }
      }
      if (query.g) {
        try {
          let query_device = decodeURIComponent(escape(window.atob(query.g)));
          this.device = query_device;
          setTimeout(() => {
            clearTimeout(this.searchTimeout);
          }, 0);
        } catch (e) {
          this.$global_error.newError(
            "Invalid 'g' param, must be base 64 encoded"
          );
        }
      }
      if (query.s && query.d) {
        this.gridApi.setSortModel([{ colId: query.s, sort: query.d }]);
      }
      if (query.r) {
        this.setQueryRow(query.r);
      }
    },
    updateQuery() {
      if (this.gridApi) {
        let query = {};
        if (this.gridApi.getFirstDisplayedRow()) {
          query.r = this.gridApi.getFirstDisplayedRow();
        }
        if (
          this.gridApi.sortModel &&
          this.gridApi.sortModel[0] &&
          this.gridApi.sortModel[0].colId &&
          this.gridApi.sortModel[0].sort
        ) {
          query.s = this.gridApi.sortModel[0].colId;
          query.d = this.gridApi.sortModel[0].sort;
        }
        if (this.getSearch()) {
          let trimed = this.getSearch().trim();
          query.q = window.btoa(unescape(encodeURIComponent(trimed)));
        }
        if (this.getDevice()) {
          query.g = window.btoa(unescape(encodeURIComponent(this.getDevice())));
        }
        if (this.getNoShowerheads()) {
          query.nsh = null;
        }
        if (this.getNoDevices()) {
          query.nd = null;
        }

        this.$router
          .replace({
            ...this.$router.currentRoute,
            query: query,
          })
          .catch(() => { });
      }
    },
    setLastRow(value) {
      this.lastRow = value;

      if (!this.isGridDestroyed && this.gridApi) {
        this.currentRow = Math.min(
          this.gridApi.getLastDisplayedRow() + 1,
          value
        );
      }
    },
    onGridReady(params) {

      const apiHydrao = this.$apiHydrao;
      var setLastRow = this.setLastRow;
      var getSearch = this.getSearch;
      var getDevice = this.getDevice;
      var getNoShowerheads = this.getNoShowerheads;
      var getNoDevices = this.getNoDevices;
      var gridApi = this.gridApi;
      var getQueryRow = this.getQueryRow;
      var setQueryRow = this.setQueryRow;
      var global_error = this.$global_error;

      var dataSource = {
        rowCount: null,
        getRows: async function (params) {
          let limit = params.endRow - params.startRow;
          let offset = params.startRow;
          let sortModel = {
            colId: null,
            sort: null,
          };
          if (params.sortModel[0]) {
            sortModel.colId = params.sortModel[0].colId;
            sortModel.sort = params.sortModel[0].sort;
          }
          var data_length;

          var rowsThisPage;
          let trimed = getSearch();
          trimed = trimed.trim();

          try {

            let result = await apiHydrao.getUsersData(
              limit,
              offset,
              sortModel,
              {
                search: trimed,
                device: getDevice(),
                no_showerheads: getNoShowerheads(),
                no_devices: getNoDevices(),
              },
              false
            );

            if (!result) {
              global_error.newError("Error : no results");
              params.failCallback();
              return;
            } else if (!result.data || !result.headers) {
              if (
                result.response &&
                result.response.data &&
                result.response.data.error
              ) {
                global_error.newError(
                  result.response.data.error +
                  ": " +
                  result.response.data.message
                );
              } else {
                global_error.newError(result);
              }
              params.failCallback();
              return;
            }
            console.log(result)

            rowsThisPage = result.data;
            // amount of matching row in the database
            data_length = result.headers["content-range"].split("/")[1];
            //tell the grid how many rows we'll be sending so i dont scroll past the last row
             if (!this.isGridDestroyed && !this.gridApi) {
              gridApi.setRowCount(data_length, true);
            }
          } catch (e) {
            global_error.newError(e);
            params.failCallback();
            return;
          }

          setLastRow(data_length);
          // if (gridApi.getInfiniteRowCount() < data_length) {
          //    gridApi.setInfiniteRowCount(data_length, false);
          //}
          if (getQueryRow()) {
            gridApi.ensureIndexVisible(parseInt(getQueryRow()), "top");
            setQueryRow(null);
          }

          var agLastRow = -1;
          if (data_length <= params.endRow) {
            agLastRow = data_length;
          }

          params.successCallback(rowsThisPage, agLastRow);
        },
      };
      this.isGridDestroyed = false;

      params.api.setDatasource(dataSource);

    },
    getSearch() {
      return this.search;
    },
    getDevice() {
      return this.device;
    },
    getNoShowerheads() {
      return this.no_showerheads;
    },
    getNoDevices() {
      return this.no_devices;
    },
    callSearch() {
      clearTimeout(this.searchTimeout);
      this.gridApi.onFilterChanged();
    },
  },
  watch: {
    search: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.gridApi.onFilterChanged();
      }, 1000);
    },
    device: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.gridApi.onFilterChanged();
      }, 0);
    },
    no_showerheads: function () {
      this.gridApi.onFilterChanged();
    },
    no_devices: function () {
      clearTimeout(this.searchTimeout);
      this.gridApi.onFilterChanged();
    },
  },
  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        results: "results",
        search: "Search",
        close: "Close",
        no_showerheads: "without shower heads",
        no_devices: "without devices",
        device: "Device",
        copied: "Email copied",
      },
      fr: {
        results: "résultats",
        search: "Rechercher",
        close: "Fermer",
        no_showerheads: "sans pommeaux",
        no_devices: "sans appareils",
        device: "Appareil",
        copied: "Adresse mail copiée",
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.deviceChip {
  border-radius: 30px;
}

.custom-filter-tag {
  margin-bottom: 13px;
  height: 26px;
  line-height: 26px;
}

.margin-right {
  margin-right: 20px;
}

.filters {
  margin-left: 50px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}

.row-count {
  float: right;

  margin-right: 20px;

  span {
    color: #c4c4c4;
  }
}

.top {
  margin-top: 5px;
  margin-bottom: 10px;
}

.ag-grid-div {
  height: calc(100vh - 260px);
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

div.ag-root {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

//search field

.search-input {
  float: right;
  margin-right: 20px;
}

.input-icons i {
  position: absolute;
  left: 10px;
  top: 5px;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

.icon {
  padding-top: 7px;
  min-width: 40px;
}

.icon-end {
  margin-left: min(72vw, 290px);
  padding-top: 7px;
  min-width: 40px;
}

.input-field {
  // width: min(85vw, 330px);
  padding-left: 34px;
  padding-right: 34px;
  border: 2px solid rgba(0, 0, 0, 0.54);
  height: 32px;
  border-radius: 3px;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.input-field:focus {
  border-color: #008dcc;
  outline: none;
}

.filter-checkbox .md-checkbox-container {
  background-color: white;
}

#closeCross {
  margin-left: 220px;
}

.checkboxes {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.checkbox {
  width: 140px;
  margin-left: 20px;
}

.nowrap {
  white-space: nowrap;
}
</style>
