<template>
  <span class="gray-text">{{ this.value ? formatDate(this.value) : '' }}</span>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "DateRenderer",
  data: () => ({
    value: null
  }),
  mounted() {
    this.value = this.params.value;
  }, 
  methods:{
    formatDate(d) {
      let date = new Date(d);

      let day = ("0" + date.getUTCDate()).slice(-2);
      let month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
      let year = date.getUTCFullYear();

      let formattedDate = day + "/" + month + "/" + year;
      return formattedDate;
    },
  }
});
</script>