<template>
  <div class="login" >
    <v-container class="title">
      <img src="../assets/logo-hydrao.svg" alt="HYDRAO" />
      <hr class="sub-line" />
      <span >{{ $t("title") }}</span>
    </v-container>
    <LoginHydrao />

    <LocaleSelect class="locale-select" />
    <span class="version">version <code>{{version}}</code></span>

    
  </div>
</template>

<script>
import LoginHydrao from "../components/LoginHydrao.vue";
import LocaleSelect from "../components/LocaleSelect.vue";
import data from '../config.json';
import { useVuelidate } from '@vuelidate/core'

export default {
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  name: "Login",
  data: () => ({
    version: data.version
  }),
  components: {
    LoginHydrao,
    LocaleSelect
  },
  i18n: {
    messages: {
      en: {
        title: "Dashboard"
      },
      fr: {
        title: "Tableau de bord"
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  background-color: #008dcc;
  height: 100%; 
}

.title {
  padding-top: 30px;
  text-align: center;
  width: 45%;
  span {
    color: white;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: 0.29em;
    
  }
}

.sub-line {
  color: white;
  padding-top: 10px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.locale-select {
  color:white;
  position: absolute;
  top: 0;
  right: 10px;
}

.version {
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: white;
}
</style>