<template>
  <div class="LocaleSelect">

    
      <v-select
      v-model="$i18n.locale" 
      @input="updateLocale"
      :items="langs"
      class="selecteur"
      >

      </v-select>
    
   
  </div>
</template>

<script>
export default {
  name: "LocaleSelect",
  data() {
    return { langs: ["fr", "en"] };
  },
  mounted: function mounted() {
    // sale, pas fier
   // var elements = document.getElementsByClassName("LocaleSelect");

   // elements[0].getElementsByTagName("svg")[0].style.fill = "white";
    /*elements[0].getElementsByClassName("md-select-value")[0].style[
      "-webkit-text-fill-color"
    ] = "white";*/
  },
  methods: {
    updateLocale() {
      this.$store.commit("setLocale", this.$i18n.locale);
    },
  },
};
</script>

<style lang="scss" scoped>
.selecteur{
  width:75px;margin-right:60px
}
.LocaleSelect {
  width: 50px;
}

.md-field.md-theme-default::after {
  background-color: white;
}
</style>