<template>
  <div v-if="sh && sh.device_uuid" class="shower-head-details">
    <div class="fixed-top">
      <span v-if="sh.label" class="title">{{ sh.label }}</span>
      <code v-else class="title">{{ sh.device_uuid }}</code>

      <span v-if="sh.email">
        <span v-for="(email, index) in emailToArray(sh.email)" v-bind:key="index" class="association-buttons">
          <disassociateShButtonNonReactive class="disassociate-button" :user_id="sh.user_id[index]"
            :device_uuid="sh.device_uuid" :email="email" :refresh_bus="refresh_bus" button="true" />
          <router-link class="router-link details" :to="'/users/' + getEncodedEmail(email)">
            <DetailButtonNonReactive :email="email" />
          </router-link>
        </span>
      </span>

      <span v-if="!sh.email && !from_user">
        <v-btn class="hydrao-blue-button small" @click="showAssociatePopup = true">
          <div style="display: flex; align-items: center">
            <svgIconNonReactive type="mdi" :path="pathPlus"></svgIconNonReactive>

            <span>{{ $t("associate") }}</span>
          </div>
        </v-btn>
      </span>
    </div>
    <!-- ------------------------------------------------->

    <v-list class="content">
      <v-list-subheader class="subheader dark-blue-color">
        {{ $t("ids") }}
      </v-list-subheader>

      <div class="custom-list-item">
        <v-row>
          <v-col>
            <div>
              <span class="row-title">{{ $t("uuid") }}</span>
            </div>
          </v-col>
          <v-col>
            <div>
              <code>{{ sh.device_uuid }}</code>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item">
        <v-row>
          <v-col>
            <div>
              <span class="row-title">{{ $t("mac_address") }}</span>
            </div>
          </v-col>
          <v-col>
            <div>
              <code>{{ sh.mac_address ? sh.mac_address : "-" }}</code>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item md-layout">
        <v-row>
          <v-col>
            <div>
              <span class="row-title">{{ $t("serial") }}</span>
            </div>
          </v-col>
          <v-col>
            <div>
              <code>{{ sh.serial ? sh.serial : "-" }}</code>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-list-subheader class="subheader dark-blue-color">{{
        $t("activity")
      }}</v-list-subheader>

      <div class="custom-list-item ">
        <v-row>
          <v-col>
            <div>
              <span class="row-title">{{ $t("first_seen") }}</span>
            </div>
          </v-col>
          <v-col>
            <div>
              <span>{{
                sh.first_seen != null
                  ? formatDate(sh.first_seen)
                  : "-"
              }}</span>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item">
        <v-row>
          <v-col>
            <div>
              <span class="row-title">{{ $t("last_seen") }}</span>
            </div>
          </v-col>
          <v-col>
            <div>
              <span>{{
                sh.last_seen != null ? formatDate(sh.last_seen) : "-"
              }}</span>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item ">
        <v-row>
          <v-col>
            <div>
              <span class="row-title">{{ $t("last_sync_date") }}</span>
            </div>
          </v-col>
          <v-col>
            <div>
              <span>{{
                sh.last_sync_date != null
                  ? formatDate(sh.last_sync_date)
                  : "-"
              }}</span>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item" v-if="sh.last_sync_date != null">
        <v-row>
          <v-col>
            <div>
              <span class="row-title">{{ $t("is_last_sync_complete") }}</span>
            </div>
          </v-col>
          <v-col>
            <div>
              <code :class="{
                'good-color': sh.is_last_sync_complete,
                'bad-color': !sh.is_last_sync_complete,
              }">{{
                sh.is_last_sync_complete != null
                  ? sh.is_last_sync_complete
                    ? $t("complete")
                    : $t("incomplete")
                  : "-"
              }}</code>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-list-subheader class="subheader dark-blue-color">
        {{ $t("usage") }}
      </v-list-subheader>

      <div class="custom-list-item md-layout">
        <v-row>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-45 md-xsmall-size-100">
              <span class="row-title">{{ $t("flow") }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-55 md-xsmall-size-100">
              <span v-if="sh.flow == null">-</span>
              <flowNonReactive v-else :value="sh.flow" />
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item md-layout">
        <v-row>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-45 md-xsmall-size-100">
              <span class="row-title">{{ $t("threshold") }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-55 md-xsmall-size-100">
              <span v-if="sh.threshold == null">-</span>
              <ThresholdNonReactive v-else :value="sh.threshold" />
            </div>
          </v-col>
        </v-row>
      </div>

      <div v-if="areDifferentThresholds(sh.threshold, sh.threshold_request)" class="custom-list-item md-layout">
        <v-row>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-45 md-xsmall-size-100">
              <span class="row-title">{{ $t("threshold_request") }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-55 md-xsmall-size-100">
              <span v-if="sh.threshold_request == null">-</span>
              <ThresholdNonReactive v-else :value="sh.threshold_request" />
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item md-layout">
        <v-row>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-45 md-xsmall-size-100">
              <span class="row-title">{{ $t("ref_shower_duration") }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-55 md-xsmall-size-100">
              <span>{{
                sh.ref_shower_duration != null
                  ? formatRefShower(sh.ref_shower_duration)
                  : "-"
              }}</span>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item md-layout">
        <v-row>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-45 md-xsmall-size-100">
              <span class="row-title">{{ $t("previous_flow") }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-55 md-xsmall-size-100">
              <span>
                {{
                  sh.previous_flow != null
                    ? sh.previous_flow + ""
                    : "-"
                }}
                <small v-if="sh.previous_flow != null">
                  <small>L/min</small>
                </small>
              </span>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-list-subheader class="subheader dark-blue-color">
        {{ $t("about") }}
      </v-list-subheader>

      <div class="custom-list-item md-layout">
        <v-row>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-45 md-xsmall-size-100">
              <span class="row-title">{{ $t("type") }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-55 md-xsmall-size-100">
              <span>
                <img class="custom-icon" :src="require('../../assets/showerheads/' +
                  getIconTypeName(sh.type) +
                  '.svg')
                  " :alt="sh.type" />
                <span class="type-span">{{
                  sh.type != null ? sh.type : "-"
                }}</span>
              </span>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item md-layout">
        <v-row>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-45 md-xsmall-size-100">
              <span class="row-title">{{ $t("connectivity") }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-55 md-xsmall-size-100">
              <span>
                <img class="custom-icon" :src="require('../../assets/showerheads/' +
                  getIconConnectivityName(sh.connectivity) +
                  '.svg')
                  " :alt="sh.connectivity" />
                <span class="type-span">{{
                  sh.connectivity != null ? sh.connectivity : "-"
                }}</span>
              </span>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item md-layout">
        <v-row>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-45 md-xsmall-size-100">
              <span class="row-title">{{ $t("calibration") }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-55 md-xsmall-size-100">
              <span>{{
                sh.calibration != null ? sh.calibration : "-"
              }}</span>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item md-layout">
        <v-row>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-45 md-xsmall-size-100">
              <span class="row-title">{{ $t("hw_version") }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-55 md-xsmall-size-100">
              <code>{{
                sh.hw_version != null ? sh.hw_version : "-"
              }}</code>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item md-layout">
        <v-row>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-45 md-xsmall-size-100">
              <span class="row-title">{{ $t("fw_version") }}</span>
            </div>
          </v-col>
          <v-col>
            <div class="md-layout-item md-size-50 md-small-size-55 md-xsmall-size-100">
              <code>{{
                sh.fw_version != null ? sh.fw_version : "-"
              }}</code>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-list>

    <v-dialog v-model="showAssociatePopup">
      <div style="display: flex; justify-content: center">
        <div>
          <v-card>
            <v-card-title>{{ $t("associate_to_user_title") }}</v-card-title>

            <v-card-text class="dialog-content">
              <span v-html="$t('associate_to_user_text')"></span>

              <v-autocomplete v-model="associate_autocomplete_value" :items="email_list"
                :item-title="(item) => item.email" :loading="loading" @input="getEmails" @change="getEmails"  @update:search="getEmails"
                @select="updateSelectedAccount" @focus="getEmails" return-object dense>
                <label> {{ $t("email") }}</label>
                <template slot-scope="{ item }">
                  <span class="fgray-text">
                    {{ item.email }}
                  </span>
                </template>
              </v-autocomplete>
            </v-card-text>

            <v-card-actions>
              <v-btn class="bad-color" @click="showAssociatePopup = false">{{
                $t("cancel")
              }}</v-btn>
              <v-btn style="color:rgb(28, 153, 209)" :disabled="!associate_autocomplete_value"
                @click="associateShToUser(associate_autocomplete_value)">
                {{ $t("associate_sh") }}</v-btn>
            </v-card-actions>

            <v-progress-linear v-if="addingShToUser" indeterminate></v-progress-linear>
          </v-card>
        </div>
      </div>
    </v-dialog>

    <v-snackbar :timeout="2000" v-model="errorTriggered" top left>
      <span style="color: white">{{ $t("errorAssociation") }} {{ errorVal }}</span>
    </v-snackbar>
  </div>
</template>
<script setup>
defineProps({
  sh: {
    type: Object,
    default: null,
    // required: true
  },
  from_user: {
    type: Boolean,

    default: false,
  },
  sh_grid_bus: {
    type: Object,
    default: mitt(),
  },
});
defineEmits(["update:sh"]);
</script>
<script>
//import * as Vue from "vue";
import Flow from "./Flow.vue";
import Threshold from "./Threshold.vue";
import DetailButton from "../user/DetailButton.vue";
import DisassociateShButton from "./DisassociateButton.vue";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlus } from "@mdi/js";

import { markRaw } from "vue";
const svgIconNonReactive = markRaw(SvgIcon);

import { defineComponent } from "vue";
import mitt from "mitt";

const flowNonReactive = markRaw(Flow);
const ThresholdNonReactive = markRaw(Threshold);
const DetailButtonNonReactive = markRaw(DetailButton);
const disassociateShButtonNonReactive = markRaw(DisassociateShButton);

export default defineComponent({
  name: "ShowerHeadDetails",
  inject: ["$apiHydrao", "$global_error"],
  components: {
    flowNonReactive,
    ThresholdNonReactive,
    DetailButtonNonReactive,
    disassociateShButtonNonReactive,
    svgIconNonReactive,
  },
  data: () => ({
    errorTriggered: false,
    errorVal: "",
    showAssociatePopup: false,
    associate_autocomplete_value: "",
    email_list: [],
    selectedAccount: null,
    addingShToUser: false,
    refresh_bus: mitt(),
    pathPlus: mdiPlus,
    loading: false,
    loaded: false,
    //sh_mutate: this.sh,
    sh_mutate: {
      user_id: null,
      email: null,
    },
  }),
  props: {
    sh: null,
    sh_grid_bus: mitt(),
    from_user: null,
  },
  mounted() {
    this.refresh_bus.on("refreshAfterDissociate", () => {
      this.sh_mutate = this.sh;
      this.sh_mutate.email = null;
      this.sh_mutate.user_id = null;
      this.$emit("update:sh", this.sh_mutate);
      this.sh_grid_bus.emit("shGridRefresh");
    });
  },
  methods: {
    // eslint-disable-next-line vue/no-dupe-keys
    errorDetected(msg) {
      this.errorVal = msg;
      this.errorTriggered = true;
    },
    formatDate(d) {
      let date = new Date(d);

      let day = ("0" + date.getUTCDate()).slice(-2);
      let month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
      let year = date.getUTCFullYear();

      let formattedDate = day + "/" + month + "/" + year;
      return formattedDate;
    },
    emailToArray(email) {
      if (Array.isArray(email)) {
        return email;
      }
      return [email];
    },
    areDifferentThresholds(t1, t2) {
      if (t1 == null) return true;
      if (t2 == null) return false;

      let s_t1 = JSON.parse(t1).sort((a, b) => {
        return a.liter > b.liter;
      });
      let s_t2 = JSON.parse(t2).sort((a, b) => {
        return a.liter > b.liter;
      });

      if (s_t1.length != s_t2.length) return true;

      for (var i = 0; i < s_t1.length; i++) {
        if (parseInt(s_t1[i].liter) != parseInt(s_t2[i].liter)) return true;
        if (s_t1[i].color.toLowerCase() != s_t2[i].color.toLowerCase())
          return true;
      }

      return false;
    },
    formatRefShower(nb_sec) {
      let res = Math.floor((nb_sec % 3600) / 60) + "m " + (nb_sec % 60) + "s";
      if (nb_sec >= 3600) res = Math.floor(nb_sec / 3600) + "h " + res;
      return res;
    },
    formatEmail(email) {
      if (Array.isArray(email)) {
        return email.join(" ");
      }
      return email;
    },
    getEncodedEmail(email) {
      return window.btoa(unescape(encodeURIComponent(email)));
    },
    getIconTypeName(icon) {
      let res = "unknown_type";
      switch (icon.toLowerCase()) {
        case "aloe":
          res = "aloe";
          break;
        case "cereus":
          res = "cereus";
          break;
        case "first":
          res = "first";
          break;
        case "origin":
          res = "first";
          break;
        case "yucca":
          res = "yucca";
          break;
      }
      return res;
    },
    getIconConnectivityName(icon) {
      let res = "unknown_connectivity";
      switch (icon.toLowerCase()) {
        case "ble":
          res = "bluetooth";
          break;
        case "lora":
          res = "lora";
          break;

        default:
          res = "unknown_connectivity";
          break;
      }
      return res;
    },
    async getEmails(event) {
      clearTimeout(this.getEmailTimeout);
      this.getEmailTimeout = setTimeout(async () => {
        this.loading = true;
        if (event && event.type == 'input') {
          let email = event.srcElement.value;
          if (email) email = email.toLowerCase();
          this.email_list = await this.$apiHydrao.getEmails(email);
        } else {
          this.email_list = await this.$apiHydrao.getEmails();
        }

        this.loaded = true;
        this.loading = false;



        // if (!this.add_child_account_autocomplete_value) {
        //   this.email_list = await this.$apiHydrao.getEmails();
        //   console.log("email list 1: ", this.email_list);
        // } else {
        //   const term =
        //     this.add_child_account_autocomplete_value.email.toLowerCase();
        //   console.log("term: ", term);
        //   this.email_list = await this.$apiHydrao.getEmails(term);
        //   console.log("email list 2: ", this.email_list);
        // }
      }, 800);
    },
    updateSelectedAccount(new_val) {
      window.setTimeout(() => {
        this.selectedAccount = new_val;
        this.associate_autocomplete_value = new_val.email;
      }, 50);
    },
    async associateShToUser(account) {
      console.log(account.id);

      this.addingShToUser = true;

      let res = await this.$apiHydrao.associateShowerHead(
        account.id,
        this.sh.device_uuid
      );
      if (!res.status || res.status != 200) {
        if (res.response && res.response.data && res.response.data.error) {
          switch (res.response.data.error) {
            case "AlreadyRegistered":
              this.errorDetected("AlreadyRegistered");
              this.$global_error.newError(
                this.$i18n.messages[this.$i18n.locale].already_registered
              );
              break;
            case "RegisterUnauthorized":
              this.errorDetected("RegisterUnauthorized");
              this.$global_error.newError(
                this.$i18n.messages[this.$i18n.locale].register_unauthorized
              );
              break;
            case "PlaceAccessDenied":
              this.errorDetected("PlaceAccessDenied");
              this.$global_error.newError(
                this.$i18n.messages[this.$i18n.locale].place_access_denied
              );
              break;

            default:
              this.errorDetected("default");
              this.$global_error.newError(res);
              break;
          }
        } else {
          this.$global_error.newError(res);
        }
      } else {
        this.sh_mutate = this.sh;
        this.sh_mutate.email = [account.email];
        this.sh_mutate.user_id = [account.id];
        this.$emit("update:sh", this.sh_mutate);

        this.sh_grid_bus.emit("shGridRefresh");

        // window.location.reload();
      }
      this.showAssociatePopup = false;
      this.selectedAccount = null;
      this.associate_autocomplete_value = "";
      this.addingShToUser = false;
    },
  },
  watch: {},
  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        ids: "IDs",
        uuid: "UUID",
        serial: "Serial",
        mac_address: "MAC address",

        activity: "Activity",
        last_seen: "Last seen",
        first_seen: "First seen",
        last_sync_date: "Last sync date",
        is_last_sync_complete: "Last sync result",
        complete: "COMPLETE",
        incomplete: "INCOMPLETE",

        usage: "Usage",
        flow: "Flow",
        threshold: "Thresholds (L)",
        threshold_request: "Thresholds request (L)",
        ref_shower_duration: "Reference shower",
        previous_flow: "Previous flow",

        about: "About",
        type: "Type",
        connectivity: "Connectivity",
        calibration: "Calibration",
        hw_version: "Hardware version",
        fw_version: "Firmware version",
        email: "Email",

        associate_to_user_title: "associate to user",
        associate_to_user_text:
          "the shower head will be added to selected user main group",

        associate_sh: "associate",
        no_email: "No emails",
        cancel: "Cancel",
        associate: "Associate",

        errorAssociation: "Error occured during association  : ",
      },
      fr: {
        ids: "Identifiants",
        uuid: "UUID",
        serial: "Numéro de série",
        mac_address: "Adresse MAC",

        activity: "Activité",
        last_seen: "Vu la dernière fois",
        first_seen: "Vu la première fois",
        last_sync_date: "Dernière synchro",
        is_last_sync_complete: "Résultat dernière synchro",
        complete: "COMPLÈTE",
        incomplete: "INCOMPLÈTE",

        usage: "Utilisation",
        flow: "Débit",
        threshold: "Seuils (L)",
        threshold_request: "Seuils demandés (L)",
        ref_shower_duration: "Douche de référence",
        previous_flow: "Débit précédant",

        about: "À propos",
        type: "Type",
        connectivity: "Connectivité",
        calibration: "Calibration",
        hw_version: "Version du hardware",
        fw_version: "Version du firmware",
        email: "Adresse mail",

        associate_to_user_title: "associer à un utilisateur",
        associate_to_user_text:
          "le pommeau sera ajouté au groupe principal de l'utilisateur choisi",

        associate_sh: "associer",

        no_email: "Aucune adresse mail",
        cancel: "Annuler",
        associate: "Associer",

        errorAssociation: "Une erreur est survenue lors de l'association : ",
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.associateUser {
  display: flex;
  align-items: center;
}

.disassociate-button {
  display: block;
  position: relative;
}

@media (max-width: 600px) {
  .row-title {
    font-weight: bold;
  }

  .custom-icon {
    height: 18px !important;
  }
}

.custom-list-item {
  min-height: 40px;
  padding: 4px 16px;
  align-items: center;
  transition: padding 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  will-change: padding;
  font-size: 13px;
}

.title {
  margin-left: 18px;
  margin-top: 2px;
  line-height: 34px;
  font-family: Roboto;
  font-weight: 300;
  font-size: large;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e2e2e2;
}

.content {
  overflow: auto;
  max-height: calc(100% - 37px);
}

.shower-head-details {
  background-color: white;
  height: calc(100% - 37px);
}

.subheader {
  margin-left: 0px;
  text-transform: uppercase;
  font-family: Inter;
  font-weight: 200;
  letter-spacing: 0.05em;
}

code {
  color: #545454;
}

.custom-icon {
  margin-right: 10px;
  height: 32px;
  width: 32px;
}

.type-span {
  font-family: Roboto;
  font-weight: 200;
}

.dialog-content {
  padding-left: 24px;
  padding-right: 24px;
}

.association-buttons {
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;

  &>* {
    margin: 0 5px;
  }

  .details {
    max-width: 150px;
    margin: 7px;
  }
}

.fixed-top .router-link {
  display: block;
  top: 2px;
  position: relative;

  &>* {
    display: block;
    position: relative;
  }
}
</style>
