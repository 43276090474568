<template>
<span class="selectable">
  <v-progress-circular
      v-if="!value"
      class="row-spinner"
      v-bind:class="{ hidden: value }"
      :size="20"
      :width="3"
      indeterminate
    ></v-progress-circular>
  <span class="gray-text" v-if="value && value.label">{{this.value.label}}</span>
  <code class="light-gray-text" v-else-if="value && value.uuid"><small>{{ this.value.uuid }}</small></code>
</span>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "LabelRenderer",
  data: () => ({
    value: null
  }),
  mounted() {
    this.value = this.params.value;
  }
});
</script>

<style scoped>
.selectable {
  user-select: text;
}
</style>