<template>
  <span>
    <ShowerHeadTypeShallow v-if="type || connectivity" :type="type" :connectivity="connectivity" />
    
  
  </span>
</template>

<script>
import ShowerHeadType from "../showerheads/ShowerHeadType.vue";

import { defineComponent, shallowRef } from 'vue';

const ShowerHeadTypeShallow = shallowRef(ShowerHeadType);

export default defineComponent({
  name: "ShowerHeadTypeRenderer",
  components: {
    ShowerHeadTypeShallow,
  },
  data: () => ({
    type: null,
    connectivity: null
  }),
  mounted() {
    if(this.params && this.params.value){
      if(this.params.value.type){
        this.type = this.params.value.type;
      }

      if(this.params.value.connectivity){
        this.connectivity = this.params.value.connectivity;
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

.row-spinner{
  margin-top: 12px;
  margin-left: 10px;
}

.type-icon {
  height: 35px;
  width: 35px;
}

.connectivity-icon {
  margin-left: 2px;
  height: 24px;
  width: 24px;
}
</style>