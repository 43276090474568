<template>
  <div>
    
    <div class="DevicesGrid" :style="'height: ' + height + ';'">
      <ag-grid-vue
        oncontextmenu="return false;"
        style="width: 100%; height: 100%"
        id="ag-grid-sh"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :components="components"
        :rowBuffer="rowBuffer"
        :rowSelection="rowSelection"
        :rowDeselection="true"
        :rowModelType="rowModelType"
        :paginationPageSize="paginationPageSize"
        :cacheOverflowSize="cacheOverflowSize"
        :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
        :infiniteInitialRowCount="infiniteInitialRowCount"
        :frameworkComponents="frameworkComponents"
        class="ag-theme-material"
      ></ag-grid-vue>
    </div>

    <div class="md-gutter md-layout md-alignment-top-left">
      <div class="md-layout-item">
        <span class="row-count light-gray-text">
          <pre><code><span>{{currentRow}}/</span>{{lastRow}}</code> {{$t('results')}}</pre>
        </span>
      </div>
    </div>

    <v-snackbar :timeout="2000" v-model="copied" top left>
      <span style="color: white">{{ $t("copied") }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import HeadersRenderer from "../cellRenderers/HeadersRenderer.vue";
import DeviceTypeRenderer from "../cellRenderers/DeviceTypeRenderer.vue";
import DeviceUsersButtonRenderer from "../cellRenderers/DeviceUsersButtonRenderer.vue";
import DeviceNameRenderer from "../cellRenderers/DeviceNameRenderer.vue";
import LastSeenRenderer from "../cellRenderers/LastSeenRenderer.vue";
import mitt from "mitt";

export default {
  name: "DevicesGrid",
  inject: ["$apiHydrao", "$global_error"],
  components: {
    "ag-grid-vue": AgGridVue,
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      components: null,
      rowBuffer: null,
      rowSelection: null,
      rowModelType: null,
      paginationPageSize: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      frameworkComponents: null,

      currentRow: "-",
      lastRow: "-",
      searchTimeout: null,
      copied: false,
      skip_watch: false,
      isGridDestroyed: false,

      only_lora: false,
    };
  },
  props: {
    search: null,
    bus: mitt(),
    height: {
      default: "100px",
    },
  },
  beforeMount() {
    this.gridOptions = {
      cacheBlockSize: 25,
      headerHeight: 25,
      suppressHorizontalScroll: true,
      suppressBrowserResizeObserver: true, // see https://github.com/ag-grid/ag-grid/issues/6562

      onBodyScroll: () => {
        if (!this.isGridDestroyed && this.gridApi) {
          let newCurrentRow = this.gridApi.getLastDisplayedRow() + 1;
          if (newCurrentRow != this.currentRow) {
            this.currentRow = newCurrentRow;
            this.updateQuery();
          }
        }
      },
      onSortChanged: () => {
        if (this.gridApi) {
          this.gridApi.ensureIndexVisible(0, "top");
          this.updateQuery();
        }
      },
      onFilterChanged: () => {
        if (this.gridApi) {
          this.gridApi.ensureIndexVisible(0, "top");
          this.updateQuery();
        }
      },
    };
    this.frameworkComponents = {
      headersRenderer: HeadersRenderer,
      deviceTypeRenderer: DeviceTypeRenderer,
      deviceUsersButtonRenderer: DeviceUsersButtonRenderer,
      deviceNameRenderer: DeviceNameRenderer,
      lastSeenRenderer: LastSeenRenderer,
    };
    this.columnDefs = [
      {
        headerName: "type",
        colId: "type",
        valueGetter: (params) => {
          if (params.data) {
            return {
              value: params.data.type ? params.data.type : null,
              loaded: params.data.nb_users != null,
            };
          } else {
            return {
              value: null,
              loaded: false,
            };
          }
        },
        headerComponent: "headersRenderer",
        cellRenderer: "deviceTypeRenderer",
        minWidth: 50,
        maxWidth: 50,
      },
      {
        headerName: "model",
        field: "device",
        cellRenderer: "deviceNameRenderer",
        headerComponent: "headersRenderer",
        onCellContextMenu: async (params) => {
          if (params.value) {
            this.copyAndShowSnackbar(params.value);
          }
        },
      },
      {
        headerName: "last_seen",
        field: "last_seen",
        headerComponent: "headersRenderer",
        cellRenderer: "lastSeenRenderer",
        sort: "desc",
        maxWidth: 110,
      },

      {
        headerName: "users",
        colId: "nb_users",
        valueGetter: (params) => {
          if (params.data && params.data.nb_users && params.data.device) {
            return {
              nb_users: params.data.nb_users,
              device: params.data.device,
            };
          }
        },
        headerComponent: "headersRenderer",
        cellRenderer: "deviceUsersButtonRenderer",
        sort: "desc",
        minWidth: 125,
        maxWidth: 125,
      },
    ];

    this.defaultColDef = {
      flex: 1,
      resizable: true,
      sortable: true,
      suppressMovable: true,
    };
    this.components = {};
    this.rowBuffer = 0;
    this.rowSelection = "single";
    this.rowModelType = "infinite";
    this.paginationPageSize = 25;
    this.cacheOverflowSize = 1;
    this.maxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1;
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    if (this.bus) {
      this.bus.on("searchCalled", () => {
        clearTimeout(this.searchTimeout);
        this.gridApi.onFilterChanged();
      });
      this.bus.on("searchInit", () => {
        clearTimeout(this.searchTimeout);
        this.gridApi.onFilterChanged();
        this.skip_watch = true;
      });
      this.bus.on("loraIsfalse", () => {
        console.log("loraIsfalse");
        clearTimeout(this.searchTimeout);
        this.gridApi.onFilterChanged();
        this.only_lora = false;

      });
      this.bus.on("loraIstrue", () => {
        console.log("loraIstrue");
        clearTimeout(this.searchTimeout);
        this.gridApi.onFilterChanged();
        this.only_lora = true;
        
      });
      
    }
    this.handleQuery();
  },
  watch: {
    search() {
      if (this.gridApi && !this.skip_watch) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.gridApi.onFilterChanged();
        }, 1000);
      }
      if (this.skip_watch) this.skip_watch = false;
    },
    only_lora: function () {
      this.gridApi.onFilterChanged();
    },
  },
  methods: {
    destroyGrid() {
      if (this.gridApi) {
        this.gridApi.destroy();
        this.isGridDestroyed = true;
      }
    },
    copyAndShowSnackbar(value) {
      navigator.clipboard.writeText(value).then(
        () => {
          this.copied = true;
        },
        (error) => {
          console.error("Could not copy text: ", error);
        }
      );
    },
    getQueryRow() {
      return this.query_row;
    },
    setQueryRow(value) {
      this.query_row = value;
    },
    handleQuery() {
      let query = this.$route.query;
      if ("onl" in query && query.onl === null) {
        this.only_lora = true;
      }
      if (query.s && query.d) {
        this.gridApi.setSortModel([{ colId: query.s, sort: query.d }]);
      }
      if (query.r) {
        this.setQueryRow(query.r);
      }
    },
    updateQuery() {
      if (this.gridApi) {
        let query = {};
        if (this.gridApi.getFirstDisplayedRow()) {
          query.r = this.gridApi.getFirstDisplayedRow();
        }
        /*if (
          this.gridApi.getSortModel() &&
          this.gridApi.getSortModel()[0] &&
          this.gridApi.getSortModel()[0].colId &&
          this.gridApi.getSortModel()[0].sort
        ) {
          query.s = this.gridApi.getSortModel()[0].colId;
          query.d = this.gridApi.getSortModel()[0].sort;
        }*/
        if (this.getSearch()) {
          let trimed = this.getSearch().trim();
          query.q = window.btoa(unescape(encodeURIComponent(trimed)));
        }
        if(this.getOnlyLora()){
          query.onl = null;
        }
        this.$router
          .replace({
            ...this.$router.currentRoute,
            query: query,
          })
          .catch(() => {});
      }
    },
    getDataSource() {
      var gridApi = this.gridApi;
      const apiHydrao = this.$apiHydrao;
      var refresh = false;

      var getOnlyLora = this.getOnlyLora;
      var setLastRow = this.setLastRow;
      var global_error = this.$global_error;
      var getSearch = this.getSearch;
      var getQueryRow = this.getQueryRow;
      var setQueryRow = this.setQueryRow;

      return {
        rowCount: null,
        getRows: async function (params) {
          let limit = params.endRow - params.startRow;
          let offset = params.startRow;
          let sortModel = {
            colId: null,
            sort: null,
          };
          if (params.sortModel[0]) {
            sortModel.colId = params.sortModel[0].colId;
            sortModel.sort = params.sortModel[0].sort;
          }
          var data_length;

          var rowsThisPage;
          try {
            let trimed = getSearch();
            trimed = trimed.trim();
            let result = await apiHydrao.getDevicesData(
              limit,
              offset,
              sortModel,
              {
                search: trimed, 
                only_lora: getOnlyLora(),
              },
              refresh
            );
            if (!result) {
              global_error.newError("Error : no results");
              params.failCallback();
              return;
            } else if (!result.data || !result.headers) {
              if (
                result.response &&
                result.response.data &&
                result.response.data.error
              ) {
                global_error.newError(
                  result.response.data.error +
                    ": " +
                    result.response.data.message
                );
              } else {
                global_error.newError(result);
              }
              params.failCallback();
              return;
            }
            rowsThisPage = result.data;
            // amount of matching row in the database
            data_length = result.headers["content-range"].split("/")[1];
            //tell the grid how many rows we'll be sending so i dont scroll past the last row
            if (!this.isGridDestroyed && !this.gridApi) {
              gridApi.setRowCount(data_length, true);
            }
          } catch (e) {
            global_error.newError(e);
            params.failCallback();
            return;
          }

          setLastRow(data_length);
          //if (gridApi.getInfiniteRowCount() < data_length) {
          // gridApi.setInfiniteRowCount(data_length, false);
          //}
          if (getQueryRow()) {
            gridApi.ensureIndexVisible(parseInt(getQueryRow()), "top");
            setQueryRow(null);
          }

          var agLastRow = -1;
          if (data_length <= params.endRow) {
            agLastRow = data_length;
          }

          params.successCallback(rowsThisPage, agLastRow);
        },
      };
    },
    onGridReady() {
      this.isGridDestroyed = false;

      this.gridReload();
    },
    gridReload() {
      this.gridApi.setDatasource(this.getDataSource());
    },
    setLastRow(value) {
      this.lastRow = value;
      if (!this.isGridDestroyed && this.gridApi) {
        this.currentRow = Math.min(
          this.gridApi.getLastDisplayedRow() + 1,
          value
        );
      }
    },
    getSearch() {
      return this.search;
    },

    getOnlyLora() {
      return this.only_lora;
    },
  },
  i18n: {
    messages: {
      en: { results: "results", copied: "Model copied" ,},
      fr: { results: "résultats", copied: "Modèle copié" },
    },
  },
};
</script>

<style lang="scss" scoped>


#ag-grid-devices {
  height: calc(100% - 25px);
  width: 100%;
}

.ag-header-row {
  height: 24px !important;
}

.ag-header {
  min-height: 30px !important;
  height: 30px !important;
}

span {
  color: #545454;
}

.row-count {
  float: right;

  pre {
    margin-top: 2px;
    margin-bottom: 0px;
  }

  span {
    color: #c4c4c4;
  }
}
</style>

<style>
div .ag-cell {
  color: #545454;
}
</style>
