import store from '../store'
import axios from "axios";
import router from '../router'
import data from '../config.json';
import customCache from '../store/custom_cache.js'

const api_url = data[data.env]["api-url"]
const api_key = data[data.env]["api-key"]

var config = {
    headers: {
        "x-api-key": api_key,
        "Content-Type": "application/json",
    }
};

const apiHydrao = {
    startSession: async function (username, password) {
        const data = {
            email: username,
            password: password
        };

        var result_sessions = await axios.post(api_url + "sessions/", data, config);

        //check if user is admin
        Object.assign(config.headers, { 'Authorization': "Bearer ".concat(result_sessions.data.access_token) })
        var result_me = await axios.get(api_url + "users/me", config);
        if (!result_me.data.features.includes('admin')) throw 'UserIsNotAdmin';

        store.commit("setAccessToken", result_sessions.data.access_token);
        store.commit("setRefreshToken", result_sessions.data.refresh_token);
    },

    refreshSession: async function () {
        const data = {
            refresh_token: store.getters.getRefreshToken
        };

        try {
            let res = await axios.post(api_url + "sessions/", data, config);
            console.log("commit refresh")
            store.commit("setAccessToken", res.data.access_token);
            return true
        } catch (e) {
            console.log("refresh failed")
            router.push({ name: 'Login' })
        }
    },

    loggedGet: async function (url, refresh = true, store_res = false) {
        Object.assign(config.headers, { 'Authorization': "Bearer ".concat(store.getters.getAccessToken) })

        if (!refresh) {
            let res = customCache.get(url)
            if (res) return res;
        }

        try {
            let res = await axios.get(url, config)
            if (store_res) customCache.set(url, res)
            return res
        } catch (e) {
            if (e && e.response && e.response.status && e.response.status == 401) {
                await this.refreshSession()
                console.log("recall")
                return await this.loggedGet(url)
            } else {
                console.log("fail: " + url)
                return e
            }
        }
    },

    loggedPut: async function (url, data, headers = null) {
        if (!headers) headers = config.headers;
        Object.assign(headers, { 'Authorization': "Bearer ".concat(store.getters.getAccessToken) });

        try {
            let res = await axios.put(url, data, { headers: headers });
            return res;
        } catch (e) {
            if (e && e.response && e.response.status && e.response.status == 401) {
                await this.refreshSession();
                console.log("recall");
                return await this.loggedPut(url, data, headers);
            } else {
                console.log("fail: " + url);
                return e;
            }
        }
    },

    loggedPost: async function (url, data, headers = null) {
        if (!headers) headers = config.headers;
        Object.assign(headers, { 'Authorization': "Bearer ".concat(store.getters.getAccessToken) });

        try {
            let res = await axios.post(url, data, { headers: headers });
            return res;
        } catch (e) {
            if (e && e.response && e.response.status && e.response.status == 401) {
                await this.refreshSession();
                console.log("recall");
                return await this.loggedPost(url, data, headers);
            } else {
                console.log("fail: " + url);
                return e;
            }
        }
    },

    loggedDelete: async function (url, headers = null) {
        if (!headers) headers = config.headers;
        Object.assign(headers, { 'Authorization': "Bearer ".concat(store.getters.getAccessToken) });

        try {
            let res = await axios.delete(url, { headers: headers });
            return res;
        } catch (e) {
            if (e && e.response && e.response.status && e.response.status == 401) {
                await this.refreshSession();
                console.log("recall");
                return await this.delete(url, headers);
            } else {
                console.log("fail: " + url);
                return e;
            }
        }
    },

    getUsersData: async function (limit = 25, offset = 0, sortModel, filterModel, refresh = false) {
        var url = api_url + "users?fields=id,email,registration_date,gateways,features,last_seen,nb_showerheads,nb_meters&limit=" + limit + "&offset=" + offset;
        if (sortModel.colId) url += "&order_by=" + sortModel.colId;
        if (sortModel.sort) url += "&direction=" + sortModel.sort;
        if (filterModel.search) url += "&search=" + window.btoa(unescape(encodeURIComponent(filterModel.search)));
        if (filterModel.device) url += "&device=" + window.btoa(unescape(encodeURIComponent(filterModel.device)));
        if (filterModel.no_showerheads) url += "&no_showerheads";
        if (filterModel.no_devices) url += "&no_devices";
        return await this.loggedGet(url, refresh, true);
    },

    getUserData: async function (email) {
        let encodedEmail = window.btoa(unescape(encodeURIComponent(email)))
        var url = api_url + "users/" + encodedEmail;
        return await this.loggedGet(url);
    },

    getUserStatus: async function (email) {
        let encodedEmail = window.btoa(unescape(encodeURIComponent(email)))
        var url = api_url + "users/" + encodedEmail + "/status";
        return await this.loggedGet(url);
    },

    activateUserStatus: async function (email) {
        let encodedEmail = window.btoa(unescape(encodeURIComponent(email)))
        var url = api_url + "users/" + encodedEmail + "/status";

        var data = {
            action: 'activate'
        }

        return await this.loggedPut(url, data);
    },

    addFeatures: async function (email, features) {
        let encodedEmail = window.btoa(unescape(encodeURIComponent(email)))
        var url = api_url + "users/" + encodedEmail + "/features";

        var data = {
            action: 'add',
            values: features
        }

        return await this.loggedPut(url, data);
    },

    removeFeatures: async function (email, features) {
        let encodedEmail = window.btoa(unescape(encodeURIComponent(email)))
        var url = api_url + "users/" + encodedEmail + "/features";

        var data = {
            action: 'remove',
            values: features
        }

        return await this.loggedPut(url, data);
    },

    getShowerHeadsData: async function (limit = 25, offset = 0, sortModel = null, user = null, search = null, fields = null, refresh = false) {
        var url = api_url + "admin-shower-heads?limit=" + limit + "&offset=" + offset;
        if (sortModel && sortModel.colId) url += "&order_by=" + sortModel.colId;
        if (sortModel && sortModel.sort) url += "&direction=" + sortModel.sort;
        if (user) url += "&user=" + window.btoa(unescape(encodeURIComponent(user)));
        if (search) url += "&search=" + window.btoa(unescape(encodeURIComponent(search)));
        if (fields && Array.isArray(fields) && fields.length > 0) {
            url += "&fields=" + fields.join(',');
        } else {
            url += "&fields=device_uuid,serial,last_seen,last_sync_date,threshold,label,type,is_last_sync_complete,connectivity,flow,mac_address,ref_shower_duration,previous_flow,calibration,hw_version,fw_version,threshold_request"
        }
        return await this.loggedGet(url, refresh, true);
    },

    getMetersData: async function (limit = 25, offset = 0, sortModel = null, user = null, search = null, fields = null, refresh = false) {
        var url = api_url + "admin-meters?limit=" + limit + "&offset=" + offset;
        if (sortModel && sortModel.colId) url += "&order_by=" + sortModel.colId;
        if (sortModel && sortModel.sort) url += "&direction=" + sortModel.sort;
        if (user) url += "&user=" + window.btoa(unescape(encodeURIComponent(user)));
        if (search) url += "&search=" + window.btoa(unescape(encodeURIComponent(search)));
        if (fields && Array.isArray(fields) && fields.length > 0) {
            url += "&fields=" + fields.join(',');
        } else {
            url += "&fields=device_uuid,serial,last_seen,last_update,last_sync_date,label"
        }
        return await this.loggedGet(url, refresh, true);
    },

    disassociateShowerHead: async function (user_id, device_uuid) {
        var url = api_url + "shower-heads/" + device_uuid + "/unregister";

        let headers = {
            "x-api-key": api_key,
            "Content-Type": "application/json",
            "x-on-behalf": user_id
        }

        return await this.loggedPost(url, null, headers);
    },

    associateShowerHead: async function (user_id, device_uuid) {
        var url = api_url + "shower-heads/" + device_uuid + "/register";

        let headers = {
            "x-api-key": api_key,
            "Content-Type": "application/json",
            "x-on-behalf": user_id
        }

        return await this.loggedPost(url, null, headers);
    },

    disassociateMeter: async function (user_id, device_uuid) {
        var url = api_url + "meters/" + device_uuid + "/unregister";

        let headers = {
            "x-api-key": api_key,
            "Content-Type": "application/json",
            "x-on-behalf": user_id
        }

        return await this.loggedPut(url, null, headers);
    },

    associateMeter: async function (user_id, device_uuid) {
        var url = api_url + "meters/" + device_uuid + "/register";

        let headers = {
            "x-api-key": api_key,
            "Content-Type": "application/json",
            "x-on-behalf": user_id
        }

        return await this.loggedPut(url, null, headers);
    },

    createDemo: async function (nb_shower_per_day, expiration_date) {
        var url = api_url + "demo-shower-heads/";
        return await this.loggedPost(url, { nb_shower_per_day: nb_shower_per_day, expiration_date: expiration_date });
    },

    createDemoMeter: async function (nb_users, nb_toilets, nb_showers, from_date, expiration_date) {
       var url = api_url + "demo-meters/";
        return await this.loggedPost(url, { 
            nb_users: nb_users, 
            nb_toilets: nb_toilets,
            nb_showers: nb_showers,
            from_date: from_date,
            expiration_date: expiration_date 
        });
    },

    getUUIDs: async function (term = "") {
        console.log(`search ${term}`);
        return (await this.getShowerHeadsData(50, 0, null, null, term, ['device_uuid', 'label', 'serial', 'type', 'connectivity'])).data
    },

    getMeterUUIDs: async function (term = "") {
        return (await this.getMetersData(50, 0, null, null, term, ['device_uuid', 'label', 'serial'])).data
    },

    getEmails: async function (term = "") {
        var url = api_url + "users?fields=id,email&search=" + window.btoa(unescape(encodeURIComponent(term)));
        let res = await this.loggedGet(url, null)
        return res.data;
    },

    addChildAccount: async function (parent_email, child_id) {
        var url = api_url + "users/" + window.btoa(unescape(encodeURIComponent(parent_email))) + "/child-accounts";

        let headers = {
            "x-api-key": api_key,
            "Content-Type": "application/json",
            "x-on-behalf": child_id
        }

        return await this.loggedPut(url, null, headers);
    },

    removeChildAccount: async function (parent_id, child_email) {
        var url = api_url + "child-accounts/" + window.btoa(unescape(encodeURIComponent(child_email)));

        let headers = {
            "x-api-key": api_key,
            "Content-Type": "application/json",
            "x-on-behalf": parent_id
        }

        return await this.loggedDelete(url, headers);
    },

    getDevicesData: async function (limit = 25, offset = 0, sortModel = null, filterModel, refresh = false) {
        var url = api_url + "gateways?limit=" + limit + "&offset=" + offset;
        //if (sortModel && sortModel.colId) url += "&order_by=" + sortModel.colId;
        if (sortModel && sortModel.sort) url += "&direction=" + sortModel.sort;
        if (filterModel.search) url += "&search=" + window.btoa(unescape(encodeURIComponent(filterModel.search)));
        if (filterModel.only_lora) url += "&only_lora=" + filterModel.only_lora

        return await this.loggedGet(url, refresh, true);
    },

    getMeterFeature : async function (device_uuid, user_id) {
        var url = api_url + "admin-meters/" + device_uuid ;

        let headers = {
            'x-on-behalf': user_id
        }
      
        console.log('user id : ' , user_id)
        let x = await this.loggedGet(url, headers);
        //console.log('api : ',  await this.loggedGet(url, headers));
        console.log('api x : ',  x);
        return x.data;
    }
}

export default apiHydrao;