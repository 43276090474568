<template>
  <Threshold :value="params.value"/>
</template>

<script>
import Threshold from "../showerheads/Threshold.vue"
import { defineComponent } from 'vue';

export default defineComponent({
  name: "ThresholdRenderer",
  components: {
    Threshold
  },
});
</script>