<template>
  <div>
    <v-btn
    variant="text"
      :disabled="loading"
      v-if="this.user_id && this.device_uuid "
      @click="dialog = true"
    >
      <v-progress-circular
        v-if="loading"
        class="dark-spinner"
        indeterminate
        size="13"
        width="2"
      ></v-progress-circular>

      <div style="display: flex; align-items: center">
        <div>
          <svg-icon class="bad-color" v-if="!loading" type="mdi" :path="pathClose" height="24" width="24"></svg-icon>
          <svg-icon v-else type="mdi" :path="pathHourglass"></svg-icon>
        </div>
        
      </div>
    </v-btn>

    <v-dialog v-model="dialog">
      <div style="display:flex; justify-content: center;">
        <div>
          <v-card style="">
            <v-card-title>{{ $t("disassociate") }}</v-card-title>
            <v-card-text>{{
              $t("disassociate_content", {
                uuid: this.device_uuid,
                email: this.email,
              })
            }}</v-card-text>

            <v-card-actions>
              <v-btn @click="dialog=false"> {{ $t("cancel") }}</v-btn>
              <v-btn @click="disassociate"> {{ $t("confirm") }}</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>

import mitt from "mitt";
import { defineComponent } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClose, mdiTimerSandEmpty } from "@mdi/js";

export default defineComponent({
  name: "DisassociateShButton",
  inject: ["$apiHydrao", "$global_error"],
  components: { SvgIcon },
  data: () => ({
    dialog: false,
    loading: false,
    pathClose: mdiClose,
    pathHourglass: mdiTimerSandEmpty,
  }),
  props: {
    user_id: null,
    device_uuid: null,
    email: null,
    refresh_bus: mitt(),
    button: null,
  },
  methods: {
    async disassociate() {
      this.loading = true;
      if (this.user_id && this.device_uuid) {
        let res = await this.$apiHydrao.disassociateShowerHead(
          this.user_id,
          this.device_uuid
        );
        if (!res.status || res.status != 200) {
          if (res.response && res.response.data && res.response.data.error) {
            this.$global_error.newError(
              res.response.data.error + ": " + res.response.data.message
            );
          } else {
            this.$global_error.newError(res);
          }
        } else {
          //refresh grid
          if (this.refresh_bus) {
            this.refresh_bus.emit("refreshAfterDissociate");
          }

          //this.params.value.gridReload();
        }
      }
      this.loading = false;
      this.dialog = false;
    },
  },
  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        disassociate: "Disassociate",
        disassociate_content:
          "Disassociate the shower head {uuid} from the user {email} ?",
        cancel: "Cancel",
        confirm: "Confirm",
      },
      fr: {
        disassociate: "Désassocier",
        disassociate_content:
          "Désassocier le pommeau {uuid} du compte {email} ?",
        cancel: "Annuler",
        confirm: "Confirmer",
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.disassociateU {
  display: flex;
  align-items: center;
}
.disassociate-button {
  margin-top: 3px;
}


</style>
