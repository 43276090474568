<template>
  <div class="UserFeatures">
    <v-progress-linear
      v-if="loading"
      color="hydrao-blue"
      indeterminate
    ></v-progress-linear>
    <v-progress-linear
      v-if="most_recent_features == null"
      indeterminate
      color="hydrao-blue"
    ></v-progress-linear>
    <v-list
      v-else-if="most_recent_features.length > 0"
      class="custom-responsive"
    >
      <div v-for="(feature, i) in most_recent_features" :key="`Feature${i}`">
        <v-divider v-if="i > 0"></v-divider>
        <v-list-item>
          <div style="display: flex; justify-content: space-between">
            <div
              class="feature-icon"
              style="display: flex; align-items: center"
            >
              <svgIconNonReactive
                type="mdi"
                :path="pathPuzzle"
                style="color: #8c8c8c"
              ></svgIconNonReactive>
              <span> {{ $t("feature." + feature) }}</span>
            </div>
            <div>
              <v-btn
                variant="text"
                @click="
                  delete_feature = true;
                  feature_to_remove = feature;
                "
              >
                <svgIconNonReactive
                  type="mdi"
                  :path="pathClose"
                  class="bad-color"
                ></svgIconNonReactive>
              </v-btn>
            </div>
          </div>
        </v-list-item>
      </div>
    </v-list>
    <div v-else>
      <span>{{ $t("no_feature") }}</span>
    </div>

    <v-dialog v-model="showAddFeatureDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ $t("add_features") }}</v-card-title>

        <v-list v-if="possible_features.length > 0" class="custom-responsive">
          <v-card-subtitle>{{
            $t("add_features_instructions")
          }}</v-card-subtitle>

          <div
            v-for="(feature, i) in possible_features"
            :key="`Feature${i}`"
            style="height: 50px"
          >
            <div class="checkbox-text-wrapper">
              <div class="contentFeature">
                <v-checkbox
                  v-model="new_features"
                  v-bind:label="$t('feature.' + feature)"
                  :value="feature"
                >
                </v-checkbox>
              </div>
            </div>
          </div>
        </v-list>
        <span v-else class="no-possible-features">
          {{ $t("no_possible_features") }}
        </span>

        <v-card-actions>
          <v-btn @click="showAddFeatureDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            v-if="possible_features.length > 0"
            @click="addFeatures"
            :disabled="new_features.length == 0"
          >
            {{ $t("add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--
    <v-dialog class="dialog-confirm">
      <v-card>
        <v-card-title>{{ $t("delete_feature") }}</v-card-title>
        <v-card-text></v-card-text>
      </v-card>
    </v-dialog>-->

    <v-dialog v-model="delete_feature" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("delete_feature") }}</span>
        </v-card-title>
        <v-card-text>
          <span>{{ $t("remove_warning") }} <br /></span>
          <span
            ><strong>{{ $t("feature." + feature_to_remove) }}</strong> ?</span
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="delete_feature = false">{{
            $t("cancel")
          }}</v-btn>
          <v-btn color="primary" @click="deleteFeature">{{
            $t("confirm")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mitt from "mitt";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClose, mdiPuzzle } from "@mdi/js";

import { markRaw } from "vue";
const svgIconNonReactive = markRaw(SvgIcon);

export default {
  name: "UserFeatures",
  inject: ["$apiHydrao", "$global_error"],
  components: {
    svgIconNonReactive,
  },
  data() {
    return {
      showAddFeatureDialog: false,
      new_features: [],
      updated_features: null,
      loading: false,
      delete_feature: false,
      feature_to_remove: null,
      dialogAdd: false,
      dialogDelete: false,
      pathClose: mdiClose,
      pathPuzzle: mdiPuzzle,
    };
  },
  props: {
    features: null,
    existing_features: null,
    email: { default: null, required: true },
    bus: mitt(),
  },
  computed: {
    possible_features: function () {
      return this.existing_features.filter(
        (f) => !this.most_recent_features.includes(f) && f != "admin"
      );
    },
    most_recent_features: function () {
      if (this.updated_features) return this.updated_features;
      return this.features;
    },
  },
  mounted() {
    if (this.bus) {
      this.bus.on("openFeaturesDialog", () => {
        this.showAddFeatureDialog = true;
        console.log("openFeaturesDialog triggered ", this.showAddFeatureDialog);
      });
    }
  },
  methods: {
    async deleteFeature() {
      this.delete_feature = false;
      this.loading = true;
      let res = await this.$apiHydrao.removeFeatures(this.email, [
        this.feature_to_remove,
      ]);
      if (res && res.status != 200) {
        if (res.response && res.response.data && res.response.data.code) {
          this.$global_error.newError(
            res.response.data.code + ": " + res.response.data.message
          );
        } else {
          this.$global_error.newError(res);
        }
      } else {
        if (
          res.data &&
          res.data.updated_features &&
          Array.isArray(res.data.updated_features)
        ) {
          this.updated_features = res.data.updated_features;
        } else {
          this.$global_error.newError(res);
        }
      }
      this.feature_to_remove = null;
      this.loading = false;
    },
    async addFeatures() {
      this.loading = true;
      this.bus.emit("addFeatureStart");
      this.showAddFeatureDialog = false;
      let res = await this.$apiHydrao.addFeatures(
        this.email,
        this.new_features
      );
      if (res && res.status != 200) {
        if (res.response && res.response.data && res.response.data.code) {
          this.$global_error.newError(
            res.response.data.code + ": " + res.response.data.message
          );
        } else {
          this.$global_error.newError(res);
        }
      } else {
        if (
          res.data &&
          res.data.updated_features &&
          Array.isArray(res.data.updated_features)
        ) {
          this.updated_features = res.data.updated_features;
        } else {
          this.$global_error.newError(res);
        }
      }
      this.new_features = [];
      this.loading = false;
      this.bus.emit("addFeatureEnd");
    },
  },
  i18n: {
    messages: {
      en: {
        no_feature: "No features",
        add_features: "Add features",
        no_possible_features: "No features to add.",
        add: "Add",
        add_features_instructions: "Select the features to add.",
        cancel: "Cancel",
        confirm: "Confirm",
        delete_feature: "Remove feature",
        remove_warning: "Do you want to remove the following feature:",
        feature: {
          null: "null",
          admin: "Hydrao Admin Access",

          //-------------------------
          app_upgrade_firmware: "[APP][Showerheads]Firmware update",
          meter_hourly_signal: "[My Hydrao][Meters] Hourly Signal",
          meter_labelling: "[My Hydrao][Meters] Usage Segmentation",
          meter_timeseries_db: "[My Hydrao][Meters] Archi V2 (timestream)",
          smart_alerts: "[My Hydrao][Meters] Smart alerts",
          sms_notifications: "[My Hydrao][Meters] SMS Notifications",
          web_add_sh_by_serial: "[My Hydrao][Showerheads] Add by serial number",
          web_change_threshold: "[My Hydrao][Showerheads] Thresholds update",
          web_request_child_account: "[My Hydrao] Parent/Child accounts",
          web_view_alerts: "[My Hydrao] Alerts",
          web_view_gateways: "[My Hydrao] Gateways",
          web_view_meters: "[My Hydrao] Meters",
          web_view_places: "[My Hydrao] Locations",
          web_view_sh: "[My Hydrao] Showerheads",
          app_ble_gateway: "[APPLI][Pommeaux] Bluetooth Gateway",
        },
      },
      fr: {
        no_feature: "Aucune fonctionnalité",
        add_features: "Ajouter des fonctionnalités",
        no_possible_features: "Aucune fonctionnalité à ajouter.",
        add: "Ajouter",
        add_features_instructions:
          "Sélectionnez les fonctionnalités à ajouter.",
        cancel: "Annuler",
        confirm: "Confirmer",
        delete_feature: "Retirer une fonctionnalité",
        remove_warning:
          "Êtes vous sûr de vouloir retirer la fonctionnalité suivante:",
        feature: {
          null: "null",
          admin: "Accès Admin Hydrao",

          //-------------------------
          app_upgrade_firmware: "[APPLI][Pommeaux]mise à jour du firmware",
          meter_hourly_signal: "[My Hydrao][Compteurs] Signal horaire",
          meter_labelling: "[My Hydrao][Compteurs] Segmentation d'usage",
          meter_timeseries_db: "[My Hydrao][Compteurs] Archi V2 (timestream)",
          smart_alerts: "[My Hydrao][Compteurs] Smart alerts",
          sms_notifications: "[My Hydrao][Compteurs] Notifications SMS",
          web_add_sh_by_serial:
            "[My Hydrao][Pommeaux] Ajout par numéro de série",
          web_change_threshold: "[My Hydrao][Pommeaux] Mise à jour des seuils",
          web_request_child_account: "[My Hydrao] Comptes parents/enfants",
          web_view_alerts: "[My Hydrao] Alertes",
          web_view_gateways: "[My Hydrao] Passerelles",
          web_view_meters: "[My Hydrao] Compteurs",
          web_view_places: "[My Hydrao] Emplacements",
          web_view_sh: "[My Hydrao] Pommeaux",
          app_ble_gateway: "[APPLI][Pommeaux] Passerelle bluetooth",
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-transform: uppercase;
  font-family: Inter;
}

span {
  color: #545454;
}

.no-possible-features {
  margin-left: 24px;
}

.checkbox-text-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contentFeature {
  display: flex;
  align-items: center;
}

.feature-icon svg {
  margin-right: 10px;
}
</style>

<style></style>
