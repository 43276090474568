<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <div style="margin-top: 20px; margin-left: 20px">
    <div id="top">
      <div id="btnBack">
        <v-btn @click="goBack" variant="text" class="hydrao-blue-text-button">
          <svg-icon type="mdi" :path="pathBack"></svg-icon>

          <span>{{ $t("go_back") }}</span>
        </v-btn>
      </div>

      <div id="btnGoTo">
        <div>
          <form
            ref="myHydraoForm"
            :action="my_hydrao_url"
            method="post"
            target="_blank"
            enctype="application/x-www-form-urlencoded"
          >
            <input
              type="hidden"
              id="token"
              name="token"
              :value="$store.getters.getAccessToken"
            />
            <input
              type="hidden"
              id="on_behalf"
              name="on_behalf"
              :value="parseInt(user_id)"
            />

            <v-btn
              @click="goMyHydrao"
              class="hydrao-blue-button"
              :disabled="!goMyHydraoReady"
            >
            <v-progress-circular
              indeterminate
              v-if="isMyHydraoLoading"
              height="13"
              size="20"
            ></v-progress-circular>
              <svg-icon type="mdi" :path="pathLink"></svg-icon>

              <span v-bind="{ 'loading-span': isMyHydraoLoading }">{{
                $t("go_my_hydrao")
              }}</span>
              
            </v-btn>

           
          </form>

          <v-btn v-show="false" class="head-button" disabled>
            <span> {{ $t("see_logs") }}</span>
          </v-btn>

          <v-btn v-show="false" class="head-button" disabled>
            <span>{{ $t("delete") }}</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div id="row1">
      <div id="info" class="userSection">
        <span class="title dark-blue-color"> {{ $t("infos") }}</span>

        <UserInfos
          :email="email"
          :country="country"
          :registration_date="registration_date"
          :id="user_id"
        />
      </div>
      <div id="gateways" class="userSection">
        <span class="userSection dark-blue-color title">
          {{ $t("devices") }}
        </span>
        <UserDevices :devices="devices" />
      </div>
    </div>

    <div id="row2">
      <div id="devices" class="userSection">
        <v-card>
          <div class="spaceBetween">
            <div>
              <v-tabs v-model="tab" slider-color="#008DCC">
                <v-tab value="one">
                  <span>{{ $t("showerheads") }}</span>
                </v-tab>
                <v-tab value="two">
                  <span>{{ $t("meters") }}</span>
                </v-tab>
              </v-tabs>
            </div>
            <div style="margin-right: 20px">
              <v-card-text>
                <v-window v-model="tab">
                  <v-window-item value="one">
                    <AssociateShowerHeadsButtons
                      :user_id="user_id"
                      :email="email"
                      :sh_grid_bus="sh_grid_bus"
                    >
                    </AssociateShowerHeadsButtons>
                  </v-window-item>
                </v-window>
                <v-window v-model="tab">
                  <v-window-item value="two">
                    <AssociateMetersButtons
                      :user_id="user_id"
                      :email="email"
                      :meter_grid_bus="meter_grid_bus"
                    >
                    </AssociateMetersButtons>
                  </v-window-item>
                </v-window>
              </v-card-text>
            </div>
          </div>

          <v-card-text>
            <v-window v-model="tab">
              <v-window-item value="one">
                <ShowerheadsGrid
                  height="267px"
                  mode="user"
                  :params="{ email: this.email, user_id: this.user_id }"
                  :bus="sh_grid_bus"
                  :from_user="true"

                />
              </v-window-item>
              <v-window-item value="two">
                <MetersGrid
                  height="267px"
                  mode="user"
                  :params="{ email: this.email, user_id: this.user_id }"
                  :bus="meter_grid_bus"
                  :from_user="true"
                />
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <div id="row3">
      <div id="features" class="userSection">
        <div>
          <div class="spaceBetween">
            <div>
              <span class="title dark-blue-color"> {{ $t("features") }}</span>
            </div>
            <div>
              <v-btn
                class="hydrao-blue-button"
                style="padding-right: 15px; padding-left: 15px"
                :disabled="!userActionReady || adding_feature"
                @click="features_bus.emit('openFeaturesDialog')"
              >
                <svg-icon type="mdi" :path="pathPlus"></svg-icon>

                <v-progress-circular
                  v-if="userActionLoading"
                  class="dark-spinner"
                  indeterminate
                  height="13"
                  size="2"
                ></v-progress-circular>
                {{ $t("add") }}
              </v-btn>
            </div>
          </div>
        </div>
        <UserFeatures
          :features="features"
          :existing_features="existing_features"
          :email="email"
          :bus="features_bus"
        />
      </div>

      <div id="child" class="userSection">
        <div>
          <div>
            <div class="spaceBetween">
              <div>
                <span class="title dark-blue-color">
                  {{ $t("child_account") }}
                </span>
              </div>
              <div>
                <v-btn
                  class="hydrao-blue-button"
                  :disabled="!userActionReady || addingChildAccount"
                  @click="showAddChildAccount = true"
                >
                  <v-progress-circular
                    v-if="userActionLoading"
                    class="dark-spinner"
                    indeterminate
                    height="13"
                    size="2"
                  ></v-progress-circular>
                  <svg-icon type="mdi" :path="pathPlus"></svg-icon>

                  {{ $t("add") }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <UserAccounts
          :accounts="child_accounts"
          mode="child"
          :user="{ id: user_id, email: email }"
          :bus="child_bus"
        />
      </div>
      <div id="parent" class="userSection">
        <span style="line-height: 42px" class="title dark-blue-color">{{
          $t("parent_account")
        }}</span>
        <UserAccounts :accounts="parent_accounts" />
      </div>
    </div>
    <v-dialog v-model="showAddChildAccount">
      <div class="dialog">
        <div>
          <v-card style="padding-left: 24px; padding-right: 24px">
            <v-card-title> {{ $t("add_child_account_title") }}</v-card-title>
            <v-card-text class="dialog-content">
              <span
                v-html="$t('add_child_account_text', { email: this.email })"
              ></span>
              <v-autocomplete
                v-model="add_child_account_autocomplete_value"
                :items="email_list"
                :item-title="(item) => item.email"
                :loading="loading"

                @input="getEmails"
                @change="getEmails"
                @select="updateSelectedAccount"
                @focus="getEmails"
                @update:search="getEmails"

                dense
                return-object
              >
              </v-autocomplete>
            </v-card-text>

            <v-card-actions>
              <v-btn @click="showAddChildAccount = false" class="bad-color">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
              style="color:#1c99d1" :disabled="!add_child_account_autocomplete_value"
              @click="addChildAccount(add_child_account_autocomplete_value)"
              >
                {{ $t("add_child_account") }}</v-btn
              >
            </v-card-actions>
            <v-progress-linear
              v-if="addingChildAccount"
              indeterminate
            ></v-progress-linear>
          </v-card>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
//import * as Vue from "vue";

import UserInfos from "../components/user/Infos.vue";
import UserDevices from "../components/user/Devices.vue";
import UserFeatures from "../components/user/Features.vue";
import UserAccounts from "../components/user/Accounts.vue";
import ShowerheadsGrid from "../components/showerheads/ShowerheadsGrid.vue";
import MetersGrid from "../components/meters/MetersGrid.vue";
import AssociateShowerHeadsButtons from "../components/showerheads/AssociateShowerHeadsButtons.vue";
import AssociateMetersButtons from "../components/meters/AssociateMetersButtons.vue";
import mitt from "mitt";
import data from "../config.json";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlus, mdiLink, mdiKeyboardBackspace } from "@mdi/js";

export default {
  name: "User",
  inject: ["$apiHydrao", "$global_error"],
  components: {
    UserInfos,
    UserDevices,
    AssociateMetersButtons,
    ShowerheadsGrid,
    AssociateShowerHeadsButtons,
    MetersGrid,
    UserFeatures,
    UserAccounts,
    SvgIcon,
  },
  data: () => ({
    email: null,
    user_id: null,
    country: null,
    registration_date: null,
    devices: null,
    features: null,
    child_accounts: null,

    parent_accounts: null,
    existing_features: [],
    go_my_hydrao_loading: false,
    my_hydrao_url: data[data.env]["my-hydrao-url"],

    associate_autocomplete_value: "",
    addingSh: false,
    sh_grid_bus: mitt(),
    meter_grid_bus: mitt(),
    getUUIDTimeout: null,

    features_bus: mitt(),
    adding_feature: false,

    showAddChildAccount: false,
    add_child_account_autocomplete_value: "",
    selectedAccount: null,
    email_list: [],
    addingChildAccount: false,
    child_bus: mitt(),
    getEmailTimeout: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10),
    pathPlus: mdiPlus,
    pathLink: mdiLink,
    pathBack: mdiKeyboardBackspace,
    tab: null,
  }),
  props: ["encodedEmail"],
  mounted() {
    this.init();
    if (this.features_bus) {
      this.features_bus.on("addFeatureStart", () => {
        this.adding_feature = true;
      });
    }
    if (this.features_bus) {
      this.features_bus.on("addFeatureEnd", () => {
        this.adding_feature = false;
      });
    }
    if (this.child_bus) {
      this.child_bus.on("childRemoved", (email) => {
        this.child_accounts = this.child_accounts.filter(
          (account) => account.email != email
        );
      });
    }
  },
  computed: {
    userActionLoading: function () {
      return this.user_id == null;
    },
    userActionReady: function () {
      return !this.userActionLoading && !isNaN(parseInt(this.user_id));
    },
    goMyHydraoReady: function () {
      return this.userActionReady && !this.go_my_hydrao_loading;
    },
    isMyHydraoLoading: function () {
      return this.userActionLoading || this.go_my_hydrao_loading;
    },
  },
  methods: {
    init() {
      if (this.encodedEmail) {
        try {
          this.email = decodeURIComponent(window.atob(this.encodedEmail));
          console.log(this.email);
          this.$apiHydrao.getUserData(this.email).then(
            (success) => {
              console.log('success : ' , success);
              if (success && success.status != 200) {
                if (
                  success.response &&
                  success.response.data &&
                  success.response.data.error
                ) {
                  this.$global_error.newError(
                    success.response.data.error +
                      ": " +
                      success.response.data.message
                  );
                } else {
                  this.$global_error.newError(success);
                }
              } else if (!success) {
                this.$global_error.newError(success);
              } else {
                if (success.data) {
                  if (success.data.user) {
                    //user
                    this.user_id = success.data.user.id
                      ? success.data.user.id
                      : "-";
                    this.registration_date = success.data.user.registration_date
                      ? success.data.user.registration_date
                      : "-";
                    this.country = success.data.user.country_code
                      ? success.data.user.country_code
                      : "-";
                    this.devices = success.data.gateway
                      ? success.data.gateway
                      : [];
                    this.features = success.data.user.features
                      ? success.data.user.features
                      : [];
                  }
                  if (success.data.existing_features) {
                    //features
                    this.existing_features = success.data.existing_features;  
                  }
                  if (success.data.accounts) {
                    //accounts
                    this.child_accounts = success.data.accounts.child
                      ? success.data.accounts.child
                      : [];
                    this.parent_accounts = success.data.accounts.parent
                      ? success.data.accounts.parent
                      : [];
                  }
                } else {
                  this.failDefaultvalues();
                }
              }
            },
            (fail) => {
              console.log(fail);
              this.failDefaultvalues();
            }
          );
        } catch (e) {
          this.email = this.encodedEmail;
          this.failDefaultvalues();
          console.log(e);
        }
      }
      switch (this.$i18n.locale) {
        case "en":
          //  this.$material.locale.dateFormat = "MM/dd/yyyy";
          break;
        case "fr":
          //this.$material.locale.dateFormat = "dd/MM/yyyy";
          break;
        default:
          //this.$material.locale.dateFormat = "yyyy-MM-dd";
          break;
      }
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    async goMyHydrao() {
      this.go_my_hydrao_loading = true;
      await this.$apiHydrao.refreshSession();

      document.cookie = "admin_on_behalf=" + parseInt(this.user_id) + ";domain=hydrao.com;path=/"
      document.cookie = "admin_token=" + this.$store.getters.getAccessToken + ";domain=hydrao.com;path=/"

      //this.$refs.myHydraoForm.submit();
      this.go_my_hydrao_loading = false;
      window.open(this.my_hydrao_url, "_blank");
    },
    resetData() {
      this.email = null;
      this.user_id = null;
      this.country = null;
      this.registration_date = null;
      this.devices = null;
      this.features = null;
      this.child_accounts = null;
      this.parent_accounts = null;
      this.existing_features = [];
    },
    failDefaultvalues() {
      this.country = "-";
      this.user_id = "-";
      this.registration_date = "-";
      this.devices = [];
      this.features = [];
      this.child_accounts = [];
      this.parent_accounts = [];
    },

    updateSelectedAccount(new_val) {
      console.log(
        " new val selected : ",
        this.add_child_account_autocomplete_value
      );
      window.setTimeout(() => {
        this.selectedAccount = new_val;
        this.add_child_account_autocomplete_value = new_val.value;
      }, 50);
    },
    async addChildAccount(child) {
      console.log(child);
      this.addingChildAccount = true;

      if (this.user_id && this.user_id == child.id) {
        this.$global_error.newError(
          this.$i18n.messages[this.$i18n.locale].own_child_error
        );
      } else if (
        this.child_accounts.filter((account) => account.email == child.email)
          .length > 0
      ) {
        this.$global_error.newError(
          this.$i18n.messages[this.$i18n.locale].already_child_error
        );
      } else {
        let res = await this.$apiHydrao.addChildAccount(this.email, child.id);
        if (!res.status || res.status != 200) {
          if (res.response && res.response.data && res.response.data.code) {
            switch (res.response.data.code) {
              case "UnknownUser":
                this.$global_error.newError(
                  this.$i18n.messages[this.$i18n.locale].unknown_user
                );
                break;

              default:
                this.$global_error.newError(res);
                break;
            }
          } else {
            this.$global_error.newError(res);
          }
        } else {
          if (
            this.child_accounts.filter(
              (account) => account.email == child.email
            ).length == 0 &&
            child.email != this.email
          ) {
            let result = await this.$apiHydrao.getShowerHeadsData(
              1,
              0,
              null,
              child.email,
              null,
              ["device_uuid"],
              true
            );
            let nb_showerheads_main_group = "?";
            if (result && result.headers && result.headers["content-range"]) {
              nb_showerheads_main_group =
                result.headers["content-range"].split("/")[1];
            }
            let resultMeter = await this.$apiHydrao.getMetersData(
              1,
              0,
              null,
              child.email,
              null,
              ["device_uuid"],
              true
            );
            let nb_meters_main_group = "?";
            if (
              resultMeter &&
              resultMeter.headers &&
              resultMeter.headers["content-range"]
            ) {
              nb_meters_main_group =
                resultMeter.headers["content-range"].split("/")[1];
            }
            this.child_accounts.push({
              email: child.email,
              nb_showerheads_main_group: nb_showerheads_main_group,
              nb_meters_main_group: nb_meters_main_group,
            });
          }
        }
      }

      this.showAddChildAccount = false;
      this.selectedAccount = null;
      this.add_child_account_autocomplete_value = "";
      this.addingChildAccount = false;
    },
    async getEmails(event) {
      clearTimeout(this.getEmailTimeout);
      this.getEmailTimeout = setTimeout(async () => {
        this.loading = true;
        if(event && event.type =='input') {
          let email = event.srcElement.value;
          if(email) email = email.toLowerCase();
          this.email_list = await this.$apiHydrao.getEmails(email);
        } else {
          this.email_list = await this.$apiHydrao.getEmails();
        }
        
        this.loaded = true;
        this.loading = false;



        // if (!this.add_child_account_autocomplete_value) {
        //   this.email_list = await this.$apiHydrao.getEmails();
        // } else {
        //   const term =
        //     this.add_child_account_autocomplete_value.email.toLowerCase();

        //   this.email_list = await this.$apiHydrao.getEmails(term);
        // }
      }, 800);
    },
  },
  watch: {
    $route() {
      this.resetData();
      this.init();
    },
  },
  i18n: {
    messages: {
      en: {
        go_back: "Go back",
        go_my_hydrao: "See on My Hydrao",
        see_logs: "See logs",
        delete: "Delete",
        infos: "Infos",
        devices: "Devices",
        features: "Features",
        child_account: "Child accounts",
        parent_account: "Parent accounts",
        showerheads: "Shower heads",
        meters: "Meters",
        add: "Add",
        cancel: "Cancel",
        add_child_account_title: "Add a child account",
        add_child_account_text:
          "<strong>{email}</strong> will have access to the child account.",
        email: "Email",
        no_email: "No emails",
        add_child_account: "Add the child",
        unknown_user: "User unknown.",
        own_child_error: "User can not be his own child.",
        already_child_error:
          "The selected account is already a child of the user.",
      },
      fr: {
        go_back: "Retour",
        go_my_hydrao: "Voir sur My Hydrao",
        see_logs: "Voir les logs",
        delete: "Supprimer",
        infos: "Infos",
        meters: "Compteurs",
        devices: "Appareils",
        features: "Fonctionnalités",
        child_account: "Comptes enfants",
        parent_account: "Comptes parents",
        showerheads: "Pommeaux",
        cancel: "Annuler",

        add: "Ajouter",
        add_child_account_title: "Ajouter un compte enfant",
        add_child_account_text:
          "<strong>{email}</strong> aura accès au compte enfant.",
        email: "Adresse mail",
        no_email: "Aucune adresse mail",
        add_child_account: "Ajouter l'enfant",
        unknown_user: "Utilisateur inconnu.",
        own_child_error: "L'utilisateur ne peut pas être son propre enfant.",
        already_child_error:
          "Le compte choisi est déjà un enfant de l'utilisateur.",
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  display: flex;
  justify-content: center;
  padding: 20px;
}
// .btnStyle {
//   background-color: #008dcc !important;

//   color: white;
//   border-radius: 30px;
//   margin-right: 20px;
//   padding-right: 15px;
//   padding-left: 15px;
// }
.loading-span {
  margin-left: 8px !important;
}

.dialog-content {
  padding-left: 24px;
  padding-right: 24px;
}

.title {
  margin-left: 3px;
  text-transform: uppercase;
  font-family: Inter;
  font-weight: 200;
  letter-spacing: 0.05em;
}

.userSection {
  padding: 5px;
}

#gateways {
  flex: 1;
}

#devices {
  padding-bottom: 0px;
  margin: 20px 0;
}

.back-button {
  padding-top: 3px;
}

.back-icon {
  padding-bottom: 4px;
  margin-right: 2px;
}

#row1 {
  display: flex;
}

#info {
  flex: 1;
  margin-bottom: 0;
}

// #devices {
//   flex: 1;
// }

#row3 {
  display: flex;
}

#features {
  flex: 1;
}

#child {
  flex: 1;
}

#parent {
  flex: 1;
}

#top {
  display: flex;
}

#btnBack {
  flex: 1;
}

#btnGoTo {
  flex: 0;
}
</style>

<style scoped>
.back-button div.md-ripple {
  padding-left: 4px;
}

.dark-spinner .md-progress-spinner-circle {
  stroke: #8c8c8c !important;
}

.md-menu-content {
  z-index: 11 !important;
}

@media (max-width: 600px) {
  .custom-responsive .md-list-item-content {
    padding: 4px 4px !important;
  }

  .custom-responsive .md-list-item-content > .md-icon:first-child {
    margin-right: 5px !important;
  }
}

@media (max-width: 500px) {
  .custom-responsive .md-list-item-text {
    font-size: 10px !important;
  }
}

@media (max-width: 400px) {
  .custom-responsive .account-nb-sh {
    display: none !important;
  }
}

/* reduction du nb d'inline css*/

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.UserFeatures,
.UserAccounts {
  margin-top: 15px;
}

.v-card {
  border-radius: 0 !important;
  padding-top: 15px;
}
.v-card-text {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
