<template>
  <span>
    <span v-if="value" class="gray-text left-cell">{{ this.value }}</span>
  
    <v-progress-circular
      v-if="!value"
      class="row-spinner"
      v-bind:class="{ hidden: value }"
      :size="20"
      :width="3"
      indeterminate=""
    ></v-progress-circular>
  </span>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "EmailRenderer",
  data: () => ({
    value: null,
  }),
  mounted() {
    this.value = this.params.value;
  },
});
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

.row-spinner {
  margin-top: 12px;
  margin-left: 10px;
}

.left-cell {
  margin-left: 10px;
}

span {
  user-select: text;
}
</style>