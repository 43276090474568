// import Vue from 'vue'
//import createPersistedState from "vuex-persistedstate";
 //import VuexPersister from 'vuex-persister';
 import VuexPersistence from 'vuex-persist'
 import { createStore } from 'vuex'


import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

const vuexLocal = new VuexPersistence({
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
})


const store = createStore({
  state: {
    access_token: null,
    refresh_token: null,
    locale: 'fr',
    username: null,
    error: {
      active: false,
      message: null
    }
  },
  getters: {
    isAuthenticated: state => {
      return state.access_token !== null && state.access_token !== '' && state.refresh_token !== null && state.refresh_token !== ''
    },
    getAccessToken: state => {
      return state.access_token
    },
    getRefreshToken: state => {
      return state.refresh_token
    },
    getLocale: state => {
      return state.locale
    },
    getUsername: state => {
      return state.username
    },
    getError: state => {
      return state.error.message
    },
    hasError: state => {
      return state.error.active
    }
  },
  mutations: {
    setUsername(state, new_username) {
      state.username = new_username
    },
    setLocale(state, new_locale) {
      state.locale = new_locale
    },
    setAccessToken(state, new_access_token) {
      state.access_token = new_access_token
    },
    setRefreshToken(state, new_refresh_token) {
      state.refresh_token = new_refresh_token
    },
    clearTokens(state) {
      state.access_token = null
      state.refresh_token = null
      delete state.access_token;
      delete state.refresh_token;
    },
    setError(state, message) {
      state.error.active = true
      state.error.message = message
    },
    closeError(state) {
      state.error.active = false
      state.error.message = null
    }
  },
   plugins: [vuexLocal.plugin ]
})


export default store