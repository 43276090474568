var cache = {};

const customCache = {
    get: (key) => {
        return cache[key];
    },
    set: (key, value) => {
        cache[key] = value;
    },

    clear: () => {
        cache = {};
    },
}

export default customCache