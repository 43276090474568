<template>
  <span :class="{ 'gray-text': !color, 'good-color': color=='good', 'bad-color': color=='bad', }">
    {{ this.value ? $tc(this.unit, this.amount) : ""}}
    
    <v-tooltip
      v-if="this.value"
      text="{{ $d(new Date(this.value), 'short') }}"
      open-delay="300"
     ></v-tooltip>
  </span>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "LastSeenRenderer",
  data: () => ({
    value: null,
    unit: null,
    color: null,
    amount: null
  }),
  mounted() {
    if (this.params.value && typeof this.params.value == "object") {
      this.value = this.params.value.value;
      this.color = this.params.value.color;
    } else {
      this.value = this.params.value;
    }
    let res = this.formatLastSeen(this.value);
    if (res) {
      this.unit = res.unit;
      this.amount = res.amount;
    }
  },
  methods: {
    formatLastSeen: function(value, date = Date.now()) {
      if (value) {
        return this.formatDateDiff(new Date(date) - new Date(value));
      }
      return null;
    },

    formatDateDiff: function(difference) {
      let minute = 1000 * 60; //milliseconds * seconds
      let hour = minute * 60;
      let day = hour * 24;
      let month = day * 30.4375;
      let year = month * 12;
      let result = {
        unit: null,
        amount: null
      };

      if (difference < hour) {
        //if smaller than an hour -> result in minutes
        result.unit = "minute";
        result.amount = Math.round(difference / minute);
      } else if (difference < day) {
        //if smaller than a day -> result in hours
        result.unit = "hour";
        result.amount = Math.round(difference / hour);
      } else if (difference < month) {
        //if smaller than a month -> result in days
        result.unit = "day";
        result.amount = Math.round(difference / day);
      } else if (difference < year) {
        //if smaller than a year -> result in months
        result.unit = "month";
        result.amount = Math.round(difference / month);
      } else {
        result.unit = "year";
        result.amount = Math.round(difference / year);
      }

      return result;
    }
  },
  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        minute: "{count} minute | {count} minutes",
        hour: "{count} hour | {count} hours",
        day: "{count} day | {count} days",
        month: "{count} month | {count} months",
        year: "{count} year | {count} years"
      },
      fr: {
        minute: "{count} minute | {count} minutes",
        hour: "{count} heure | {count} heures",
        day: "{count} jour | {count} jours",
        month: "{count} mois | {count} mois",
        year: "{count} an | {count} ans"
      }
    }
  }
});
</script>