<template>
  <span>
    <span
      class="threshold"
      :class="{'threshold-first': index==0, 'threshold-last': index==sorted_value.length-1}"
      :style="'background-color: ' + thresholdDisplayColor(threshold.color)"
      v-for="(threshold, index) in this.sorted_value"
      v-bind:key="index"
    >{{ threshold.liter }}</span>
  </span>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Threshold",
  data: () => ({
    thresholdDisplayColors: {
      FFDE1A: "e3a003", // yellow
      FF7100: "f36f16", // orange
      FF0000: "dc2926", // red
      FF00FF: "bc59af", //purple
      "0000FF": "008dcc", //blue
      "4CDDF1": "49abbf", //light blue
      "00FF00": "52a684", //green
      "67FF11": "83b663", //light green
      "000000": "000000" //black
    }
  }),
  props: {
    value: null
  },
  computed: {
  sorted_value() {
    if (this.value) {
      return JSON.parse(this.value).sort((a, b) => {
        return a.liter > b.liter;
      });
    }
    return null
  },
  },
  methods: {
    thresholdDisplayColor(color) {
      let rgb = this.thresholdDisplayColors[color.substr(2).toUpperCase()];
      return "#" + (rgb ? rgb : "d6d6d6");
    }
  }
});
</script>

<style lang="scss" scoped>
.threshold {
  color: white;
  margin-left: 1px;
  display: inline-block;
  width: 24px;
  line-height: 15px;
  text-align: center;
}

.threshold-first {
  border-radius: 5px 0px 0px 5px;
}

.threshold-last {
  border-radius: 0px 5px 5px 0px;
}
</style>