<template>
  <div class="UserDevices">
    <v-progress-linear v-if="devices == null" md-mode="indeterminate"></v-progress-linear>
    <ag-grid-vue
      v-else-if="devices.length > 0"
      oncontextmenu="return false;"
      id="ag-grid-devices"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :rowData="devices"
      :frameworkComponents="frameworkComponents"
      :defaultColDef="defaultColDef"
      class="ag-theme-material"
    ></ag-grid-vue>
    <div v-else>
      <span>{{$t("no_devices")}}</span>
    </div>
    <v-snackbar :timeout="2000" v-model="copied" persistent>
      <span style="color: white">{{ $t("copied") }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import LastSeenRenderer from "../cellRenderers/LastSeenRenderer.vue";
import HeadersRenderer from "../cellRenderers/HeadersRenderer.vue";
import DeviceTypeRenderer from "../cellRenderers/DeviceTypeRenderer.vue";
import CodeRenderer from "../cellRenderers/CodeRenderer.vue";
import DeviceNameRenderer from "../cellRenderers/DeviceNameRenderer.vue";

export default {
  name: "UserDevices",
  components: {
    "ag-grid-vue": AgGridVue,
  },
  data() {
    return {
      gridApi: null,
      gridOptions: null,
      columnDefs: null,
      frameworkComponents: null,
      defaultColDef: null,

      copied: false,
    };
  },
  props: {
    devices: null,
  },
  beforeMount() {
    this.gridOptions = {
      headerHeight: 25,
      suppressHorizontalScroll: true,
    };
    this.frameworkComponents = {
      lastSeenRenderer: LastSeenRenderer,
      headersRenderer: HeadersRenderer,
      deviceTypeRenderer: DeviceTypeRenderer,
      codeRenderer: CodeRenderer,
      deviceNameRenderer: DeviceNameRenderer,
    };
    this.columnDefs = [
      {
        headerName: "type",
        field: "type",
        headerComponent: "headersRenderer",
        cellRenderer: "deviceTypeRenderer",
        minWidth: 50,
        maxWidth: 50,
      },
      {
        headerName: "model",
        field: "device",
        cellRenderer: "deviceNameRenderer",
        headerComponent: "headersRenderer",
        onCellContextMenu: async (params) => {
          if (params.value) {
            this.copyAndShowSnackbar(params.value);
          }
        },
      },
      {
        headerName: "app_version",
        valueGetter: (params) => {
          if (params.data && params.data.app_version) {
            return JSON.parse(params.data.app_version).version;
          }
        },
        cellRenderer: "codeRenderer",
        headerComponent: "headersRenderer",
        maxWidth: 150,
      },
      {
        headerName: "os_version",
        field: "os_version",
        cellRenderer: "codeRenderer",
        headerComponent: "headersRenderer",
        maxWidth: 150,
      },
      {
        headerName: "last_seen",
        field: "last_seen",
        headerComponent: "headersRenderer",
        cellRenderer: "lastSeenRenderer",
        maxWidth: 120,
        sort: "desc",
      },
    ];
    this.defaultColDef = {
      flex: 1,
      resizable: true,
      sortable: true,
      suppressMovable: true,
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
  methods: {
    copyAndShowSnackbar(value) {
      navigator.clipboard.writeText(value).then(
        () => {
          this.copied = true;
        },
        (error) => {
          console.error("Could not copy text: ", error);
        }
      );
    },
  },
  i18n: {
    messages: {
      en: { no_devices: "No devices", copied: "Model copied" },
      fr: { no_devices: "Aucun appareil", copied: "Modèle copié" },
    },
  },
};
</script>

<style lang="scss" scoped>
.UserDevices {
  height: 185px;
}

#ag-grid-devices {
  height: 100%;
  width: 100%;
}

span {
  color: #545454;
}
</style>

<style>
</style>