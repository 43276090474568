<template>
   
  <div>
    <div class=" top">
      <div class="filters">
        <div class="checkboxes">
        <div style="display:flex;">
          <v-checkbox v-model="only_lora" class="checkbox gray-text" id="onl">
            <label for="onl" class="nowrap"> {{ $t("only_lora") }}</label>
          </v-checkbox> 
        </div>
 
      </div>
        <div class="search-input">
          <div class="input-icons">
            <i><svgIconNonReactive type="mdi" :path="pathMagnify"></svgIconNonReactive></i>
            <input
              v-on:keyup.enter="callSearch"
              v-model="search"
              class="input-field"
              type="text"
              :placeholder="$t('search')"
            />
            <i id="closeCross" v-if="search" v-on:click="search=''">
              <svgIconNonReactive type="mdi" :path="pathClose"></svgIconNonReactive>
            </i>
          </div>

        </div>


      </div>
    
    <div class="ag-grid-div">
      <DevicesGrid height="calc(100vh - 220px)" :search="search" :bus="searchBus"/>
    </div>
  </div></div>
</template>

<script>
//import * as Vue from "vue";
import DevicesGrid from "../components/devices/DevicesGrid.vue";
import mitt from "mitt";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClose, mdiMagnify } from "@mdi/js";

import { markRaw } from "vue";
const svgIconNonReactive = markRaw(SvgIcon);

export default {
  name: "Devices",
  components: { DevicesGrid, svgIconNonReactive },
  data: function () {
    return {
      search: "",
      searchBus: mitt(),
      pathClose: mdiClose,
      pathMagnify: mdiMagnify,

      only_lora:false
    };
  },
  mounted() {
    this.handleQuery();
  },
  methods: {
    handleQuery() {
      let query = this.$route.query;
      if (query.q) {
        try {
          let query_search = decodeURIComponent(escape(window.atob(query.q)));
          this.search = query_search;
          this.initSearch();
        } catch (e) {
          this.$global_error.newError(
            "Invalid 'q' param, must be base 64 encoded"
          );
        }
      }
    },
    initSearch() {
      this.searchBus.emit("searchInit");
    },
    callSearch() {
      this.searchBus.emit("searchCalled");
    },
  },

  watch:{
    only_lora: function(){
      this.searchBus.emit('loraIs' + this.only_lora);
    }
  },
  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        results: "results",
        search: "Search",
        close: "Close",
        only_lora: "Only LoRa",
      },
      fr: {
        results: "résultats",
        search: "Rechercher",
        close: "Fermer",
        only_lora: "LoRa uniquement",
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.filters {
  margin-left: 50px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}

.checkboxes {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.checkbox {
  width: 140px;
  margin-left: 20px;
}

.nowrap {
  white-space: nowrap;
}

//search field

.search-input {
  float: right;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}
.input-icons i {
  position: absolute;
  left: 10px;
  top: 5px;
}
.icon {
  padding-top: 7px;
  min-width: 40px;
}

.icon-end {
  margin-left: min(72vw, 290px);
  padding-top: 7px;
  min-width: 40px;
}

.input-field {
  width: min(85vw, 330px);
  padding-left: 34px;
  padding-right: 34px;
  border: 2px solid rgba(0, 0, 0, 0.54);
  height: 32px;
  border-radius: 3px;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.input-field:focus {
  border-color: #008dcc;
  outline: none;
}

.top {
  display: flex;
  flex-direction: column;
}

#closeCross {
  margin-left: 275px;
}

.filters {
  margin-left: 50px;
  margin-top: 15px;
  justify-content: space-between;
  padding-left: 10px;
}

.search-input {
  float: right;
  margin-right: 20px;
}

.ag-grid-div {
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>
