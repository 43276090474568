<template>
  <div>
    <div v-if="value">
      <span class="gray-text">
        <div class="alignCenter">
          <svg-icon class="gray-icon" v-if="this.icon == 'mdiPuzzle'" type="mdi" :path="pathPuzzle"></svg-icon>
          <svg-icon class="gray-icon" v-if="this.icon == 'mdiCellphone'" type="mdi" :path="pathCellphone"></svg-icon>
          <img class="gray-icon" v-if="this.icon == 'sh_icon'" src="../../assets/sh_icon.svg" alt="showerhead_icon" />

          <img class="gray-icon" v-if="this.icon == 'meter_icon'" src="../../assets/meter_icon.svg" alt="meter_icon" />
          <span style="margin-left: 10px">{{ this.value }}</span>

          <v-tooltip v-if="this.list" open-delay="100" class="auto-height">
            <ul>
              <li v-for="(elem, i) in list" :key="`Elem${i}`">
                {{ type ? $t("type." + type + "." + elem) : elem }}
              </li>
            </ul>
          </v-tooltip>
        </div>
      </span>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPuzzle, mdiCellphone } from "@mdi/js";

export default defineComponent({
  name: "IconRenderer",
  components: {
    SvgIcon,
  },
  data: () => ({
    value: null,
    icon: null,
    list: null,
    type: null,
    pathPuzzle: mdiPuzzle,
    pathCellphone: mdiCellphone,
  }),
  mounted() {
    if (
      this.params.value &&
      this.params.value.value &&
      this.params.value.icon
    ) {
      this.value = this.params.value.value;
      this.icon = this.params.value.icon;
      if (this.params.value.list) {
        this.list = this.params.value.list;
        this.type = this.params.value.type ? this.params.value.type : null;
      }
    }
  },
  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        type: {
          feature: {
            null: "null",
            admin: "Admin Hydrao access",
            web_add_sh_by_serial: "[My Hydrao] Add shower heads by serial",
            web_change_threshold: "[My Hydrao] Tuning thresholds",
            web_request_child_account: "[My Hydrao] Manage child accounts",
            web_view_places: "[My Hydrao] Places",
            web_view_meters: "[My Hydrao] Meters",
            web_view_sh: "[My Hydrao] Showerheads",
            app_upgrade_firmware: "[App] Upgrade firmware",
          },
        },
      },
      fr: {
        type: {
          feature: {
            null: "null",
            admin: "Accès Admin Hydrao",
            web_view_sh: "[My Hydrao] Pommeaux",
            web_add_sh_by_serial:
              "[My Hydrao] Ajout de pommeaux par numéro de série",
            web_change_threshold: "[My Hydrao] Réglage des seuils",
            web_request_child_account: "[My Hydrao] Gestion de comptes enfants",
            web_view_places: "[My Hydrao] Emplacements",
            web_view_meters: "[My Hydrao] Compteurs",
            app_upgrade_firmware: "[Appli] Mise à jour firmware",
          },
        },
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.alignCenter {
  display: flex;
  align-items: center
}

.auto-height {
  height: auto;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
</style>
