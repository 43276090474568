<template>
  <div>
    <div id="containerForm">
      <div class="w25">
        <v-form ref="form" v-model="valid" @submit.prevent="validateLoginAdmin">
          <v-card>
            <v-card-text>
              <v-text-field variant="underlined" v-model.trim="email" :rules="emailRules" label="E-mail" :disabled="sending"
                required></v-text-field>
            </v-card-text>
            <v-card-text>
              <v-text-field variant="underlined" v-model.trim="password" :rules="passwordRules" 
                :type="showPassword ? 'text' : 'password'" label="Password" :disabled="sending" append-inner-icon="mdi-magnify"
                required>
                <template v-slot:append-inner>
                  <v-btn variant="text" class="gray-icon" @click="showPassword = !showPassword">
                    <i v-if="showPassword == true">
                      <svgIconNonReactive type="mdi" :path="pathEye"></svgIconNonReactive>
                    </i>
                    <i v-else>
                      <svgIconNonReactive type="mdi" :path="pathEyeOffOutline"></svgIconNonReactive>
                    </i>

                  </v-btn>


                </template>
              </v-text-field>
            </v-card-text>
            <v-card-text>
              <div id="flexCenter">
                <div>
                  <v-btn style="color:white;" class="hydrao-blue-button" rounded="xl" type="submit" :disabled="sending">
                    {{ $t("form.login_button") }}
                  </v-btn>
                </div>
              </div>
            </v-card-text>
            <v-snackbar :timeout="isInfinity ? Infinity : duration" persistent active.sync="error">
              <span v-if="common_error">{{
                $t("error" + common_error_number)
              }}</span>
              <span v-if="!common_error">s</span>
              <v-btn class="md-primary" @click="error = false">{{
                $t("close")
              }}</v-btn>
            </v-snackbar>

            <v-progress-linear indeterminate height="5" v-if="sending" color="hydrao-orange"></v-progress-linear>
          </v-card>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
//import { inject } from 'vue'
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";

import { markRaw } from "vue";
const svgIconNonReactive = markRaw(SvgIcon);

export default {
  inject: ["$apiHydrao"],
  components: {
    svgIconNonReactive,
  },
  data: () => ({
    sending: false,
    error: false,
    valid: false,
    password: null,
    email: null,
    duration: 10000,
    isInfinity: false,
    common_error: true,
    common_error_number: 0,
    uncommon_error_message: null,
    position: "center",
    showPassword: false,
    pathEye: mdiEyeOutline,
    pathEyeOffOutline: mdiEyeOffOutline,
  }),
  computed: {
    cssVars() {
      return {
        "--margin-top": this.marginTop + "px",
      };
    },
    emailRules() {
      return [
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;

          return this.$t("form.required_email");
        },
      ];
    },
    passwordRules() {
      return [
        (value) => {
          if (value?.length >= 3) return true;

          return this.$t("form.required_password");
        },
      ];
    },
  },
  methods: {
    async validateLoginAdmin() {
      const { valid } = await this.$refs.form.validate();

      //this.v$.$touch();

      if (valid) {
        this.sending = true;
       
        try {
          await this.$apiHydrao.startSession(this.email, this.password);

          this.$store.commit("setUsername", this.email);
          this.$router.push({ path: "/" });
        } catch (e) {
          console.log("error");
          this.error = true;
          this.isInfinity = false;
          this.common_error = true;
          if (e === "UserIsNotAdmin") {
            this.common_error_number = 2;
          } else if (e.response.data.error == "AuthFailed") {
            this.common_error_number = 0;
          } else if (e.response.data.error == "AccountDisabled") {
            this.common_error_number = 1;
          } else {
            this.isInfinity = true;
            this.common_error = false;
            this.uncommon_error_message =
              "[" +
              e.response.status +
              ":" +
              e.response.data.error +
              "] " +
              e.response.data.message;
          }
          this.sending = false;

          //this.form.username = null;
          this.$refs.form.password = null;

          this.$refs.form.reset();
        }
      }
    },
  },

  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        form: {
          login: "Email",
          password: "Password",
          login_button: "Log in",
          required_email: "The email is required",
          required_password: "The password is required",
          invalid_email: "The email must be valid",
        },
        error0: "Email or password invalid",
        error1: "User account not confirmed or disabled",
        error2: "The user account is not admin",
        close: "Close",
      },
      fr: {
        form: {
          login: "Adresse mail",
          password: "Mot de passe",
          login_button: "Se connecter",
          required_email: "L'adresse mail doit être remplie",
          required_password: "Le mot de passe doit être rempli",
          invalid_email: "L'adresse mail doit être valide",
        },
        error0: "Adresse mail ou mot de passe incorrect",
        error1: "Compte utilisateur non confirmé ou désactivé",
        error2: "Le compte utilisateur n'est pas administrateur",
        close: "Fermer",
      },
    },
  },
};
</script>

<style scoped>
#containerForm {
  display: flex;
  justify-content: center;
  margin-top: 10%;
}

.w25 {
  width: 25%
}

#flexCenter {
  display: flex;
  justify-content: center
}

.v-card {
  border-radius: 15px !important;
}

button.gray-icon {
  right: -15px;
  position: absolute;
}
</style>