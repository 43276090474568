<template>
  <div v-if="meter && meter.device_uuid" class="shower-head-details">
    <div class="fixed-top">
      <span v-if="meter.label" class="title">{{ meter.label }}</span>
      <code v-else class="title">{{ meter.device_uuid }}</code>

      <span v-if="meter.email">
        <span class="association-buttons">
          <DisassociateMeterButtonNonReactive class="disassociate-button" :user_id="meter.user_id"
            :device_uuid="meter.device_uuid" :email="meter.email" :refresh_bus="refresh_bus" button="true" />
          <router-link class="router-link user-btn" :to="'/users/' + getEncodedEmail(meter.email)">
            <DetailButtonNonReactive class="details" :email="meter.email" />
          </router-link>
        </span>
      </span>

      <span v-if="!meter.email && !from_user">
        <AssociateMeterToUserNonReactive class="associate-user-button" :refresh_bus="refresh_bus"
        :meter_uuid="meter.device_uuid" />
      </span>
    </div>

    <v-list-subheader class="subheader dark-blue-color">
      {{ $t("ids") }}
    </v-list-subheader>

    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title">{{ $t("uuid") }}</span>
          </div>
        </v-col>
        <v-col>
          <code>{{ meter.device_uuid }}</code>
        </v-col>
      </v-row>
    </div>

    <div class="custom-list-item">
      <v-row>
        <v-col>
          <span class="row-title"> {{ $t("type") }} </span>
        </v-col>
        <v-col>
          <div>

            <code
              v-if="(meter.type && !meter.type_request) || (meter.type_request == meter.type && meter.type_request != null && meter.type != null)">
              {{ meter.type }}
            </code>

            <code v-else-if="meter.type != meter.type_request">
              <code class="barre" v-if="meter.type_request"
                >{{ meter.type_request }}
              </code>
            <svgIconNonReactive class="orange icon" type="mdi" :path="pathTimeSand"></svgIconNonReactive>
            </code>
            <code v-else-if="meter.type_request == null && meter.type == null">
              {{ $t('unknown') }}
            </code>
          </div>
        </v-col>
      </v-row>
    </div>

    <div v-if="meter.label != null" class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span>
              <span class="row-title"> {{ $t("label") }} : </span>
            </span>
          </div>
        </v-col>
        <v-col>
          <div>
            <code> {{ meter.label }}</code>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title"> {{ $t("serial") }}</span>
          </div>
        </v-col>
        <v-col>
          <div>
            <code>{{ meter.serial ? meter.serial : "-" }}</code>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title">{{ $t("gateway") }}</span>
          </div>
        </v-col>
        <v-col>
          <div class="align">
            <code>{{ meter.gateway_uuid ? meter.gateway_uuid : "-" }}</code>
            <span v-if="lastSeenInH(meter.last_seen) < 1 && meter.gateway_uuid">
              <svgIconNonReactive class="green icon" type="mdi" :path="pathCircle" width="20" height="20">
              </svgIconNonReactive>
            </span>
            <span v-else-if="
              meter.gateway_uuid && lastSeenInH(meter.last_seen) >= 1
            ">
              <svgIconNonReactive class="icon" style="color: red" type="mdi" :path="pathCircle" width="20" height="20">
              </svgIconNonReactive>
            </span>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-list-subheader class="subheader dark-blue-color">{{ $t("activity") }}
    </v-list-subheader>

    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title">{{ $t("first_seen") }}</span>
          </div>
        </v-col>
        <v-col>
          <div>
            <span>{{
              meter.first_seen != null ? formatDate(meter.first_seen) : "-"
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title">{{ $t("last_seen") }}</span>
          </div>
        </v-col>
        <v-col>
          <div class="align">
            <span>{{
              meter.last_seen != null ? formatDate(meter.last_seen) : "-"
            }}</span>

            <span v-if="lastSeenInH(meter.last_seen) < 24">
              <svgIconNonReactive class="green icon" type="mdi" :path="pathCircle" width="20" height="20">
              </svgIconNonReactive>
            </span>
            <span v-else>
              <svgIconNonReactive class="orange icon" type="mdi" :path="pathCircle" width="20" height="20">
              </svgIconNonReactive>
            </span>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title">{{ $t("last_update") }}</span>
          </div>
        </v-col>
        <v-col>
          <div>
            <span>{{
              meter.last_update != null
                ? formatDate(meter.last_update)
                : "-"
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title">{{ $t("charge_level") }}</span>
          </div>
        </v-col>
        <v-col>
          <div class="align">
            <span>{{
              meter.charge_level != null ? meter.charge_level + "%" : "0%"
              }}
              <!-- <svgIconNonReactive
                v-if="meter.charge_level > 30"
                class="green"
                type="mdi"
                :path="pathFullBattery"
              ></svgIconNonReactive>

              <svgIconNonReactive
                v-else-if="meter.charge_level && meter.charge_level <= 30"
                class="orange"
                type="mdi"
                :path="pathFullBattery"
              ></svgIconNonReactive> -->
            </span>
            <div class="battery">
              <div v-if="meter.charge_level > 30" class="charge" :style="{ width: `${meter.charge_level}%` }"></div>
              <div v-else-if="meter.charge_level && meter.charge_level <= 30" class="charge"
                style="background-color: #ff8c00" :style="{ width: `${meter.charge_level}%` }"></div>
              <div v-else-if="!meter.charge_level" class="charge" style="background-color: #ff8c00; width: 0%"></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-list-subheader v-if="features_list" class="subheader dark-blue-color">
      {{ $t("features") }}
    </v-list-subheader>

    <div v-if="features_list" class="custom-list-item" style="margin-left:20px">
      <v-row>
        <v-col>
          <div v-if="features_list">
            <ul>
              <li v-for="(feature, index) in features_list" v-bind:key="index">
                <span v-if="feature.exclude" style="text-decoration: line-through" class="bad-color">{{
                  $t(feature.feature) }}
                </span>
                <span v-else> {{ $t(feature.feature) }}</span>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-list-subheader class="subheader dark-blue-color">
      {{ $t("tech_info") }}
    </v-list-subheader>

    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title">{{ $t("batch_number") }}</span>
          </div>
        </v-col>
        <v-col>
          <div>
            <span>{{
              meter.batch_number != null ? meter.batch_number : "-"
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title">{{ $t("fw_version") }}</span>
          </div>
        </v-col>
        <v-col>
          <div>
            <span>{{
              meter.fw_version != null ? meter.fw_version : "-"
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title">{{ $t("frequency") }}</span>
          </div>
        </v-col>
        <v-col>
          <div>
            <span>{{
              meter.frequency_plan != null ? meter.frequency_plan : "-"
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title">{{ $t("pcb_version") }}</span>
          </div>
        </v-col>
        <v-col>
          <div>
            <span>{{
              meter.pcb_version != null ? meter.pcb_version : "-"
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="custom-list-item">
      <v-row>
        <v-col>
          <div>
            <span class="row-title">{{ $t("bom_version") }}</span>
          </div>
        </v-col>
        <v-col>
          <div>
            <span>{{
              meter.bom_version != null ? meter.bom_version : "-"
            }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script setup>
defineProps({
  meter: {
    type: Object,
    default: null,
    // required: true
  },
  from_user: {
    type: Boolean,

    default: false,
  },
  sh_grid_bus: {
    type: Object,
    default: mitt(),
  },
});
defineEmits(["update:sh"]);
</script>
<script>
//import * as Vue from "vue";
import DetailButton from "../user/DetailButton.vue";
import DisassociateMeterButton from "./DisassociateButton.vue";
import AssociateMeterToUser from "./AssociateToUserButton.vue";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlus } from "@mdi/js";
import { mdiTimerSand, mdiCircle, mdiBattery } from "@mdi/js";
import { markRaw } from "vue";
const svgIconNonReactive = markRaw(SvgIcon);

import { defineComponent } from "vue";
import mitt from "mitt";

const DetailButtonNonReactive = markRaw(DetailButton);
const DisassociateMeterButtonNonReactive = markRaw(DisassociateMeterButton);
const AssociateMeterToUserNonReactive = markRaw(AssociateMeterToUser);

export default defineComponent({
  name: "MeterDetails",
  inject: ["$apiHydrao", "$global_error"],
  components: {
    DetailButtonNonReactive,
    DisassociateMeterButtonNonReactive,
    AssociateMeterToUserNonReactive,
    svgIconNonReactive,
  },
  data: () => ({
    errorTriggered: false,
    errorVal: "",
    showRegisterDevice: false,
    associate_autocomplete_value: "",
    email_list: [],
    selectedAccount: null,
    addingShToUser: false,
    refresh_bus: mitt(),
    pathPlus: mdiPlus,
    //sh_mutate: this.sh,
    meter_mutate: {
      user_id: null,
      email: null,
    },
    pathTimeSand: mdiTimerSand,
    pathCircle: mdiCircle,
    difLastSeen: null,
    pathFullBattery: mdiBattery,

    features_list: null,
  }),
  props: {
    meter: null,
    sh_grid_bus: mitt(),
    from_user: null,
  },
  mounted() {
    this.refresh_bus.on("refreshAfterDissociate", () => {
      this.meter_mutate = this.meter;
      this.meter_mutate.email = null;
      this.meter_mutate.user_id = null;
      this.$emit("update:sh", this.meter_mutate);
      this.sh_grid_bus.emit("shGridRefresh");
    });
    // this.difLastSeen= this.lastSeenOneH(this.meter.last_seen);
    // this.features_list = this.loadFeature();
  },
  methods: {
    // eslint-disable-next-line vue/no-dupe-keys
    lastSeenInH(ls) {
      const dateString = ls;
      const date = new Date(dateString);

      const currentDate = new Date();

      const difference = currentDate.getTime() - date.getTime();
      const differenceInHours = difference / (1000 * 60 * 60);

      return differenceInHours;
      // return diffInHours;
    },

    formatDate(d) {
      let date = new Date(d);

      let day = ("0" + date.getUTCDate()).slice(-2);
      let month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
      let year = date.getUTCFullYear();

      let formattedDate = day + "/" + month + "/" + year;
      return formattedDate;
    },
    emailToArray(email) {
      if (Array.isArray(email)) {
        return email;
      }
      return [email];
    },

    getEncodedEmail(email) {
      return window.btoa(unescape(encodeURIComponent(email)));
    },

    async loadFeature() {
      let res = await this.$apiHydrao.getMeterFeature(
        this.meter.device_uuid,
        this.meter.user_id
      );

      return res;
    },
    featureMap(featurelist) {
      if (this.features_list && this.features_list.meter_type) {
        let excludeval = featurelist.meter_meter_features;
        console.log("excludeval", excludeval);

        let featureNames = featurelist.meter_type.meter_type_meter_features.map(
          (item) => item.feature
        );
        console.log("features ", featureNames);

        let res = featureNames.map((featureName) => {
          let excludeRule = excludeval.find(
            (excludeItem) => excludeItem.feature === featureName
          );
          let exclude = false;
          if (excludeRule) {
            exclude = excludeRule.exclude;
            console.log("excludeval[i].feature : ", excludeRule.feature);
            if (exclude) {
              console.log("true");
            } else {
              console.log("false");
            }
          }

          // return an object with feature and exclude properties
          return { feature: featureName, exclude: exclude };
        });

        excludeval.forEach((excludeItem) => {
          if (
            excludeItem.exclude === false &&
            !featureNames.includes(excludeItem.feature)
          ) {
            res.push({ feature: excludeItem.feature, exclude: false });
          }
        });

        return res;
      }
    },
  },
  watch: {
    meter: {
      async handler(meter) {
        if (meter) {
          console.log("sh", meter);

          this.features_list = await this.$apiHydrao.getMeterFeature(
            this.meter.device_uuid,
            this.meter.user_id
          );
          this.features_list = this.featureMap(this.features_list);
          console.log("features_list", this.features_list);
        }
      },
    },
  },
  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        ids: "IDs",
        uuid: "UUID",
        serial: "Serial",
        mac_address: "MAC address",
        gateway: "Gateway",

        activity: "Activity",
        last_seen: "Last seen",
        first_seen: "First seen",
        last_sync_date: "Last sync date",
        is_last_sync_complete: "Last sync result",
        complete: "COMPLETE",
        incomplete: "INCOMPLETE",
        last_update: "Last update",

        usage: "Usage",
        flow: "Flow",
        threshold: "Thresholds (L)",
        threshold_request: "Thresholds request (L)",
        ref_shower_duration: "Reference shower",
        previous_flow: "Previous flow",

        about: "About",
        type: "Type",
        connectivity: "Connectivity",
        calibration: "Calibration",
        hw_version: "Hardware version",
        fw_version: "Firmware version",
        email: "Email",

        associate_to_user_title: "associate to user",
        associate_to_user_text:
          "the shower head will be added to selected user main group",

        associate_sh: "associate",
        no_email: "No emails",
        cancel: "Cancel",
        associate: "Associate",

        errorAssociation: "Error occured during association  : ",
        unknown: "Unknown",
        label: "Label",
        charge_level: "Charge level",
        tech_info: "Technical information",
        batch_number: "Batch number",
        frequency: "Frequency",
        pcb_version: "PCB version",
        bom_version: "BOM version",
        features: "Features",
        hourly_signal: "Hourly signal",
        period_alerts: "Period alerts",
        smart_alerts: "Smart alerts",
        threshold_alerts: "Threshold alerts",
        timeseries_db: "Timeseries DB",
      },
      fr: {
        ids: "Identifiants",
        uuid: "UUID",
        serial: "Numéro de série",
        mac_address: "Adresse MAC",
        gateway: "Passerelle",

        activity: "Activité",
        last_seen: "Vu la dernière fois",
        first_seen: "Vu la première fois",
        last_sync_date: "Dernière synchro",
        is_last_sync_complete: "Résultat dernière synchro",
        complete: "COMPLÈTE",
        incomplete: "INCOMPLÈTE",
        last_update: "Dernière mise à jour",

        usage: "Utilisation",
        flow: "Débit",
        threshold: "Seuils (L)",
        threshold_request: "Seuils demandés (L)",
        ref_shower_duration: "Douche de référence",
        previous_flow: "Débit précédant",

        about: "À propos",
        type: "Type",
        connectivity: "Connectivité",
        calibration: "Calibration",
        hw_version: "Version du hardware",
        fw_version: "Version du firmware",
        email: "Adresse mail",

        associate_to_user_title: "associer à un utilisateur",
        associate_to_user_text:
          "le pommeau sera ajouté au groupe principal de l'utilisateur choisi",

        associate_sh: "associer",

        no_email: "Aucune adresse mail",
        cancel: "Annuler",
        associate: "Associer",

        errorAssociation: "Une erreur est survenue lors de l'association : ",
        unknown: "Inconnu",
        label: "Nom",
        charge_level: "Niveau de charge",
        tech_info: "Informations techniques",
        batch_number: "Numéro de lot",
        frequency: "Fréquence",
        pcb_version: "Version du PCB",
        bom_version: "Version du BOM",

        features: "Fonctionnalités",
        hourly_signal: "Signal horaire",
        period_alerts: "Alertes périodiques",
        smart_alerts: "Alertes intelligentes",
        threshold_alerts: "Alertes seuils",
        timeseries_db: "Base de données timeseries",
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.battery {
  width: 35px;
  height: 15px;
  position: relative;
  border: 1px solid #444444;
  border-radius: 4px;
  margin-left: 10px;
}

.battery:before {
  content: "";
  position: absolute;
  top: 3px;
  right: -4px;
  width: 3px;
  height: 6px;
  background: #444444;
}

.charge {
  height: 100%;
  background: #9acd32;
}

.align {
  display: flex;
  align-items: center;
}

.orange {
  color: #ff8c00;
}

.green {
  color: #00ca36;
}

.barre {
  text-decoration: line-through;
}

.associateUser {
  display: flex;
  align-items: center;
}

.disassociate-button {
  display: block;
  position: relative;
}

@media (max-width: 600px) {
  .row-title {
    font-weight: bold;
  }

  .custom-icon {
    height: 18px !important;
  }
}

.custom-list-item {
  min-height: 40px;
  padding: 4px 16px;
  align-items: center;
  transition: padding 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  will-change: padding;
  font-size: 13px;
}

.title {
  margin-left: 18px;
  margin-top: 2px;
  line-height: 34px;
  font-family: Roboto;
  font-weight: 300;
  font-size: large;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e2e2e2;
}

.content {
  overflow: auto;
  max-height: calc(100% - 37px);
}

.shower-head-details {
  background-color: white;
  height: calc(100% - 37px);
}

.subheader {
  margin-left: 20px;
  text-transform: uppercase;
  font-family: Inter;
  font-weight: 200;
  letter-spacing: 0.05em;
}

code {
  color: #545454;
}

.custom-icon {
  margin-right: 10px;
  height: 32px;
  width: 32px;
}

.type-span {
  font-family: Roboto;
  font-weight: 200;
}

.dialog-content {
  padding-left: 24px;
  padding-right: 24px;
}

.association-buttons {
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;

  &>* {
    margin: 0 5px;
  }

  .details {
    max-width: 150px;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.fixed-top .router-link {
  display: block;
  top: 2px;
  position: relative;

  &>* {
    display: block;
    position: relative;
  }
}

.user-btn {
  margin: 3px;
  display: inline-block;
}
</style>
