<template>
  <code class="gray-text"> {{ this.value ? this.value : '-' }}</code>
  </template>
  
  <script>
  
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: "BatchRenderer",
    data: () => ({
      value: null
    }),
    mounted() {
      this.value = this.params.value;
    }
  });
  </script>
  
  <style scoped>
  code {
    user-select: text;
  }
  </style>