<template>
  <div>
    <disassociateShButtonNonReactive
      :user_id="user_id"
      :email="email"
      :device_uuid="device_uuid"
      :refresh_bus="refresh_bus"
    />
  </div>
</template>

<script>
//import * as Vue from 'vue';
import DisassociateShButton from "../showerheads/DisassociateButton.vue";
import { defineComponent } from 'vue';
import mitt from 'mitt';

import { markRaw } from "vue";
const disassociateShButtonNonReactive = markRaw(DisassociateShButton);

export default defineComponent({
  name: "DisassociateButtonRenderer",
  components: { disassociateShButtonNonReactive },
  data: () => ({
    user_id: null,
    email: null,
    device_uuid: null,
    refresh_bus: mitt(),
  }),
  mounted() {
    if (
      this.params.value &&
      this.params.value.user_id &&
      this.params.value.device_uuid
    ) {
      this.user_id = this.params.value.user_id;
      this.device_uuid = this.params.value.device_uuid;
      this.email = this.params.value.email;
    }

    this.refresh_bus.on("refreshAfterDissociate", () => {
      this.params.value.gridReload();
    });
  },
  methods: {},
});
</script>

<style scoped>
</style>