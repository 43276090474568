import App from './App.vue'
import { createApp } from 'vue'
import apiHydrao from './api_hydrao'
//import * as VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import customCache from './store/custom_cache.js'
import mitt from 'mitt';

//import vuetify 
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

//-----


// router
import router from './router'
// ---

// cookie storage
import store from './store'
// ---
import "./styles/_style.scss";

import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

// Use plugin with optional defaults



// Locale

const vuetify = createVuetify({
  themes:{
    themes: {
      light: {
        bhydrao:'#008dcc',
      }
    }
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },

  
  components,
  directives,
})

const dateTimeFormats = {
  'fr-FR': {
    short: {
      year: '2-digit', month: 'numeric', day: 'numeric'
    },
    long: {
      year: 'numeric', month: '2-digit', day: '2-digit'
    }
  },
  'en-US': {
    short: {
      year: '2-digit', month: 'numeric', day: 'numeric'
    },
    long: {
      year: 'numeric', month: '2-digit', day: '2-digit'
    }
  },
}

const i18n = createI18n({
  locale: store.getters.getLocale, // set locale
  fallbackLocale: { 'en': ['en-US'], 'fr': ['fr-FR'] },
  dateTimeFormats,
  silentFallbackWarn: true
})



// custom cache
/*
prototype.$customCache = customCache*/
// ---

// error snackbar global var

/*
prototype.$global_error = 
*/
// ---

// Vue.config.productionTip = false


let globalError = {
  hasError() {
    return store.getters.hasError
  },
  getError() {
    return store.getters.getError
  },
  newError(message) {
    store.commit("setError", message);
  },
  close() {
    store.commit("closeError");
  },
};

const emitter = mitt();

const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.provide('$apiHydrao', apiHydrao);
app.provide('$customCache',customCache);
app.provide('$global_error',globalError);
app.use(vuetify)
app.use(router);
app.use(i18n);
app.use(store);
app.use(VueClipboard);
app.use(VCalendar, {});
app.mount('#app');


