<template>
  <div>
    <div class="pageContainer">
      <div class="md-layout-item bloc" :class="{
        'md-size-60 md-large-size-50 md-medium-size-40 md-small-size-25 md-xsmall-hide':
          details,
        'md-size-100': !details,
      }">
        <div class="ag-grid-div" :style="'height: ' + height + ';'">
          <ag-grid-vue oncontextmenu="return false;" style="width: 100%; height: 100%" id="ag-grid-meter"
            :gridOptions="gridOptions" @grid-ready="onGridReady" :columnDefs="columnDefs" :defaultColDef="defaultColDef"
            :components="components" :rowBuffer="rowBuffer" :rowSelection="rowSelection" :rowDeselection="true"
            :rowModelType="rowModelType" :paginationPageSize="paginationPageSize" :cacheOverflowSize="cacheOverflowSize"
            :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
            :infiniteInitialRowCount="infiniteInitialRowCount" :frameworkComponents="frameworkComponents"
            class="ag-theme-material"></ag-grid-vue>
        </div>

        <div class="md-gutter md-layout md-alignment-top-left">
          <div class="md-layout-item">
            <span class="row-count light-gray-text">
              <pre><code><span>{{ currentRow }}/</span>{{ lastRow }}</code> {{ $t('results') }}</pre>
            </span>
          </div>
        </div>
      </div>
      <div class="md-layout-item bloc" :class="{
        'fake-hide': !details,
        'md-size-40 md-large-size-50 md-medium-size-60 md-small-size-75 md-xsmall-size-100':
          details,
      }">
        <div :style="'height: ' + height + ';'">
          <div class="close-header">
            <div @click="
              details = false;
            updateQuery();
            ">
              <svgIconNonReactive type="mdi" :path="pathClose"></svgIconNonReactive>
            </div>
          </div>
          <div class="meter-details-box" :style="'height: calc(' + height + ' - 26px);'">
            <MeterDetails v-model:meter="detailed_meter" :sh_grid_bus="bus" :from_user="from_user" />
          </div>
        </div>
      </div>
    </div>

    <v-snackbar :timeout="2000" v-model="copied">
      <span style="color: white">{{ $t("copied") }}</span></v-snackbar>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import LastSeenRenderer from "../cellRenderers/LastSeenRenderer.vue";
import HeadersRenderer from "../cellRenderers/HeadersRenderer.vue";
import IconRenderer from "../cellRenderers/IconRenderer.vue";
import CodeRenderer from "../cellRenderers/CodeRenderer.vue";
import DisassociateMeterButtonRenderer from "../cellRenderers/DisassociateMeterButtonRenderer.vue";
import FlowRenderer from "../cellRenderers/FlowRenderer.vue";
import LabelRenderer from "../cellRenderers/LabelRenderer.vue";
import DetailButtonRenderer from "../cellRenderers/DetailButtonRenderer.vue";
import BatchRenderer from "../cellRenderers/BatchRenderer.vue";
import ShowerheadExpandRenderer from "../cellRenderers/ShowerheadExpandRenderer.vue";
import mitt from "mitt";
import MeterDetails from "./MeterDetails.vue";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClose } from "@mdi/js";

import { markRaw } from "vue";
import apiHydrao from "../../api_hydrao";
const svgIconNonReactive = markRaw(SvgIcon);

export default {
  name: "MetersGrid",
  inject: ["$apiHydrao", "$global_error"],
  components: {
    "ag-grid-vue": AgGridVue,
    MeterDetails,
    svgIconNonReactive,
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      components: null,
      rowBuffer: null,
      rowSelection: null,
      rowModelType: null,
      paginationPageSize: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      frameworkComponents: null,

      lastEmail: null,
      currentRow: "-",
      lastRow: "-",
      lastSearch: null,
      searchTimeout: null,

      details: false,
      detailed_meter: null,

      first_fit: true,
      copied: false,
      skip_watch: false,
      selected_row: null,
      loaded: false,
      pathClose: mdiClose,

      isGridDestroyed: false,
    };
  },
  props: {
    mode: null,
    params: null,
    bus: mitt(),
    from_user: null,
    height: {
      default: "100px",
    },
  },
  beforeUnmount() {
    this.destroyGrid();
  },
  beforeMount() {
    this.gridOptions = {
      cacheBlockSize: 25,
      headerHeight: 25,
      suppressBrowserResizeObserver: true, //see https://github.com/ag-grid/ag-grid/issues/6562

      onBodyScroll: () => {
        if (!this.isGridDestroyed && this.gridApi) {
          let newCurrentRow = this.gridApi.getLastDisplayedRow() + 1;
          if (newCurrentRow != this.currentRow) {
            this.currentRow = newCurrentRow;
            if (this.mode == "all") this.updateQuery();
          }
        }
      },
      onSortChanged: () => {
        if (this.gridApi) {
          this.gridApi.ensureIndexVisible(0, "top");
          if (this.mode == "all") this.updateQuery();
        }
      },
      onFilterChanged: () => {
        if (this.gridApi) {
          this.gridApi.ensureIndexVisible(0, "top");
          if (this.mode == "all") this.updateQuery();
        }
      },
      onCellClicked: (params) => {
        let feature = apiHydrao.getMeterFeature(params.data.device_uuid);
        console.log("feature ", feature)
        this.detailed_meter = params.data;
        console.log("params : ", params)
        if (this.detailed_meter) this.detailed_meter.rowIndex = params.rowIndex;
        if (params.column.colId == "more") {
          this.details = true;
        }
        if (this.mode == "all" && this.details) this.updateQuery();
      },
      onPaginationChanged: () => { },
      onGridSizeChanged: (params) => {
        var grid_width = document.getElementById("ag-grid-meter").offsetWidth;
        if (grid_width) {
          if (this.first_fit) {
            this.first_fit = !this.first_fit;
          } else {
            params.api.sizeColumnsToFit();
          }
        }
      },
    };
    this.frameworkComponents = {
      lastSeenRenderer: LastSeenRenderer,
      headersRenderer: HeadersRenderer,
      iconRenderer: IconRenderer,
      codeRenderer: CodeRenderer,
      disassociateButtonRenderer: DisassociateMeterButtonRenderer,
      flowRenderer: FlowRenderer,
      labelRenderer: LabelRenderer,
      batchRenderer: BatchRenderer,
      detailButtonRenderer: DetailButtonRenderer,
      showerheadExpandRenderer: ShowerheadExpandRenderer,
    };
    this.columnDefs = [
      {
        headerName: "label",
        headerComponent: "headersRenderer",
        colId: "label",
        valueGetter: (params) => {
          if (params.data) {
            return {
              label: params.data.label,
              uuid: params.data.device_uuid,
            };
          }
        },
        cellRenderer: "labelRenderer",
        onCellContextMenu: async (params) => {
          if (params.value) {
            console.log(params.value);
            params.value.label
              ? this.copyAndShowSnackbar(params.value.label)
              : this.copyAndShowSnackbar(params.value.uuid);
          }
        },
      },
      {
        headerName: "serial",
        field: "serial",
        headerComponent: "headersRenderer",
        cellRenderer: "codeRenderer",
        onCellContextMenu: async (params) => {
          if (params.value) {
            this.copyAndShowSnackbar(params.value);
          }
        },
      },
      {
        headerName: "batch_number",
        field: "batch_number",
        headerComponent: "headersRenderer",
        cellRenderer: "batchRenderer",
        onCellContextMenu: async (params) => {
          if (params.value) {
            this.copyAndShowSnackbar(params.value);
          }
        },
      },
      {
        headerName: "last_seen",
        field: "last_seen",
        headerComponent: "headersRenderer",
        cellRenderer: "lastSeenRenderer",
        sort: "desc",
        maxWidth: 110,
      },
    ];

    if (this.mode == "user" && !isNaN(this.params.user_id)) {

      this.columnDefs.push({
        colId: "disassociate",
        headerName: "disassociate",
        valueGetter: (params) => {
          if (
            this.params.user_id &&
            params.data &&
            params.data.device_uuid &&
            this.params.email
          ) {
            return {
              user_id: this.params.user_id,
              email: this.params.email,
              device_uuid: params.data.device_uuid,
              gridReload: this.gridReload,
            };
          } else if (
            params.data &&
            params.data.device_uuid &&
            params.data.email &&
            params.data.user_id
          ) {
            return {
              user_id: params.data.user_id,
              email: params.data.email,
              device_uuid: params.data.device_uuid,
              gridReload: this.gridReload,
            };
          }
        },
        headerComponent: "headersRenderer",
        cellRenderer: "disassociateButtonRenderer",
        sortable: false,
        minWidth: 100,
        maxWidth: 300,
      });
    }

    if (this.mode == "all") {
      this.columnDefs.push({
        colId: "email",
        headerName: "email",
        headerComponent: "headersRenderer",
        cellRenderer: "detailButtonRenderer",
        valueGetter: (params) => {
          if (params.data && params.data.email) {
            return {
              value: params.data.email,
              show_email: true,
            };
          } else if (params.data && params.data.device_uuid) {
            return {
              show_register: true,
              meter_uuid: params.data.device_uuid,
              gridReload: this.gridReload,
            };
          }
        },
        sortable: false,
        minWidth: 140,
      });
    }
    this.columnDefs.push({
      headerName: "",
      colId: "more",
      field: "device_uuid",
      cellRenderer: "showerheadExpandRenderer",
      sortable: false,
      minWidth: 55,
      maxWidth: 55,
    });
    this.defaultColDef = {
      flex: 1,
      resizable: true,
      sortable: true,
      suppressMovable: true,
    };
    this.components = {};
    this.rowBuffer = 0;
    this.rowSelection = "single";
    this.rowModelType = "infinite";
    this.paginationPageSize = 25;
    this.cacheOverflowSize = 1;
    this.maxConcurrentDatasourceRequests = 1;
    this.infiniteInitialRowCount = 1;
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    if (this.bus) {
      this.bus.on("meterGridRefresh", () => {
        this.gridReload();
      });
      this.bus.on("meterSearchCalled", () => {
        clearTimeout(this.searchTimeout);
        this.gridApi.onFilterChanged();
      });
      this.bus.on("meterSearchInit", () => {
        clearTimeout(this.searchTimeout);
        this.gridApi.onFilterChanged();
        this.skip_watch = true;
      });
    }
    if (this.mode == "all") this.handleQuery();
  },
  watch: {
    loaded() {
      if (
        this.gridApi &&
        this.selected_row != null &&
        this.mode == "all" &&
        this.details
      ) {
        let node = this.gridApi.getRowNode(this.selected_row + "");
        if (node) {
          node.setSelected(true);
          this.detailed_meter = node.data;
          this.detailed_meter.rowIndex = this.selected_row;
          this.selected_row = null;
          this.updateQuery();
        } else {
          this.loaded = false;
        }
      }
    },
    params() {
      if (this.mode == "user" && this.lastEmail != this.params.email) {
        this.lastEmail = this.params.email;
        if (this.gridApi) {
          this.gridApi.onFilterChanged();
        }
      }
      /* if (this.mode == "user" && !isNaN(this.params.user_id)) {
        this.gridApi.refreshCells({ columns: ["disassociate"], force: false });
      }*/
      if (this.mode == "all" && this.lastSearch != this.params.search) {
        this.lastSearch = this.params.search;
        if (this.gridApi && !this.skip_watch) {
          clearTimeout(this.searchTimeout);
          this.searchTimeout = setTimeout(() => {
            this.gridApi.onFilterChanged();
          }, 1000);
        }
        if (this.skip_watch) this.skip_watch = false;
      }
    },
  },
  methods: {
    destroyGrid() {
      if (this.gridApi) {
        this.gridApi.destroy();
        this.isGridDestroyed = true;
      }
    },
    copyAndShowSnackbar(value) {
      navigator.clipboard.writeText(value).then(
        () => {
          this.copied = true;
        },
        (error) => {
          console.error("Could not copy text: ", error);
        }
      );
    },
    getQueryRow() {
      return this.query_row;
    },
    setQueryRow(value) {
      this.query_row = value;
    },
    getSelectedRow() {
      return this.loaded;
    },
    setLoaded(value) {
      this.loaded = value;
    },
    handleQuery() {
      let query = this.$route.query;
      if (query.s && query.d) {
        this.gridApi.setSortModel([{ colId: query.s, sort: query.d }]);
      }
      if (query.r && query.m == null) {
        this.setQueryRow(query.r);
      }
      if (query.m != null) {
        this.setQueryRow(query.m);
        this.details = true;
        this.selected_row = query.m;
      }
    },
    updateQuery() {
      if (this.gridApi) {
        let query = {};
        if (this.gridApi.getFirstDisplayedRow()) {
          query.r = this.gridApi.getFirstDisplayedRow();
        }
        /* if (
          this.gridApi.getSortModel() &&
          this.gridApi.getSortModel()[0] &&
          this.gridApi.getSortModel()[0].colId &&
          this.gridApi.getSortModel()[0].sort
        ) {
          query.s = this.gridApi.getSortModel()[0].colId;
          query.d = this.gridApi.getSortModel()[0].sort;
        }*/
        if (this.getSearch()) {
          let trimed = this.getSearch().trim();
          query.q = window.btoa(unescape(encodeURIComponent(trimed)));
        }
        if (
          this.details &&
          this.detailed_meter &&
          this.detailed_meter.rowIndex != null
        ) {
          query.m = this.detailed_meter.rowIndex;
        }

        this.$router
          .replace({
            ...this.$router.currentRoute,
            query: query,
          })
          .catch(() => { });
      }
    },
    getDataSource() {
      var gridApi = this.gridApi;
      const apiHydrao = this.$apiHydrao;
      var getUserEmail = this.getUserEmail;
      var refresh = true;
      var fields = null;
      if (this.mode == "user") {
        fields = [
          "device_uuid",
          "serial",
          "first_seen",
          "last_seen",
          "last_update",
          "label",
          "batch_number",
          "charge_level",

        ];
      } else if (this.mode == "all") {
        fields = [
          "device_uuid",
          "serial",
          "first_seen",
          "last_seen",
          "last_update",
          "label",
          "email",
          "user_id",
          "batch_number",
          "charge_level",
          "fw_version",
          "frequency_plan",
          "pcb_version",
          "bom_version",
          'type',
          'type_request'
        ];
      }
      var setLastRow = this.setLastRow;
      var global_error = this.$global_error;
      var getSearch = this.getSearch;
      var getQueryRow = this.getQueryRow;
      var setQueryRow = this.setQueryRow;
      var setLoaded = this.setLoaded;
      var getSelectedRow = this.getSelectedRow;

      return {
        rowCount: null,
        getRows: async function (params) {
          let limit = params.endRow - params.startRow;
          let offset = params.startRow;
          let sortModel = {
            colId: null,
            sort: null,
          };
          if (params.sortModel[0]) {
            sortModel.colId = params.sortModel[0].colId;
            sortModel.sort = params.sortModel[0].sort;
          }
          var data_length;

          var rowsThisPage;
          let trimed = getSearch();
          if (trimed) trimed = trimed.trim();
          try {
            let result = await apiHydrao.getMetersData(
              limit,
              offset,
              sortModel,
              getUserEmail(),
              trimed,
              fields,
              refresh
            );
            if (!result) {
              global_error.newError("Error : no results");
              params.failCallback();
              return;
            } else if (!result.data || !result.headers) {
              if (
                result.response &&
                result.response.data &&
                result.response.data.error
              ) {
                global_error.newError(
                  result.response.data.error +
                  ": " +
                  result.response.data.message
                );
              } else {
                global_error.newError(result);
              }
              params.failCallback();
              return;
            }
            rowsThisPage = result.data;
            // amount of matching row in the database
            data_length = result.headers["content-range"].split("/")[1];
            //tell the grid how many rows we'll be sending so i dont scroll past the last row

            if (!this.isGridDestroyed && !this.gridApi) {
              gridApi.setRowCount(data_length, true);
            }
          } catch (e) {
            global_error.newError(e);
            params.failCallback();
            return;
          }

          setLastRow(data_length);
          /*if (gridApi.getInfiniteRowCount() < data_length) {
            gridApi.setInfiniteRowCount(data_length, false);
          }*/
          if (getQueryRow()) {
            gridApi.ensureIndexVisible(parseInt(getQueryRow()), "top");
            setQueryRow(null);
          }
          if (getSelectedRow() != null) {
            setLoaded(true);
          }

          var agLastRow = -1;
          if (data_length <= params.endRow) {
            agLastRow = data_length;
          }

          params.successCallback(rowsThisPage, agLastRow);
        },
      };
    },
    onGridReady() {
      this.isGridDestroyed = false;
      this.gridReload();
    },
    gridReload() {
      this.gridApi.setDatasource(this.getDataSource());
    },
    setLastRow(value) {
      this.lastRow = value;
      if (!this.isGridDestroyed && this.gridApi) {
        this.currentRow = Math.min(
          this.gridApi.getLastDisplayedRow() + 1,
          value
        );
      }
    },
    getUserEmail: function () {
      return this.lastEmail;
    },
    getSearch() {
      return this.lastSearch;
    },
  },
  i18n: {
    messages: {
      en: {
        no_sh: "No shower heads",
        results: "results",
        copied: "Cell copied",
      },
      fr: {
        no_sh: "Aucun pommeau",
        results: "résultats",
        copied: "Case copiée",
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pageContainer {
  display: flex;
  flex-direction: row;
}

#ag-grid-devices {
  height: calc(100% - 25px);
  width: 100%;
}

.ag-header-row {
  height: 24px !important;
}

.ag-header {
  min-height: 30px !important;
  height: 30px !important;
}

span {
  color: #545454;
}

.row-count {
  float: right;
  margin-right: 20px;

  pre {
    margin-top: 2px;
    margin-bottom: 0px;
  }

  span {
    color: #c4c4c4;
  }
}

.close-header {
  width: 100%;
  height: 26px;
  text-align: right;

  i {
    float: right;
  }
}

.meter-details-box {
  background-color: white;
  overflow: auto;
}

.fake-hide {
  display: none;
}

.bloc {
  width: 100%;
  transition: 0.1s;
}

.ag-grid-div {
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>

<style>
div .ag-cell {
  color: #545454;
}
</style>
