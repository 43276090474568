<template>
  <div class="UserInfos">
    <v-list>
      <v-list-item>
        <span class="listItem">
          <div>
            <svg-icon type="mdi" :path="path"></svg-icon>

            <span>{{ email }}</span>
          </div>
          <div>
            <span v-if="id && id != '-'">#{{ id }}</span>
          </div>
        </span>
      </v-list-item>
      <v-divider></v-divider>
      <div class="custom-list-item">
        <div>
          <v-row>
            <v-col>
              <span class="row-title">{{ $t("registration_date") }}</span>
            </v-col>
            <v-col>
              <span v-if="registration_date != null">{{
                formatDate(this.registration_date)
              }}</span>
              <v-progress-circular
                v-else
                :size="20"
                :width="3"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="custom-list-item">
        <v-row>
          <v-col>
            <span class="row-title"> {{ $t("country") }}</span>
          </v-col>
          <v-col>
            <span v-if="country != null && hasFlag()" class="flag">
              <img :class="'flag:' + country" />{{ this.country }}
            </span>
            <span v-else-if="country != null" class="flag status">
              <span>
                {{ country }}
              </span>
            </span>
            <v-progress-circular
              v-else
              indeterminate
              :size="20"
              :width="3"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </div>

      <div class="custom-list-item">
        <v-row>
          <v-col>
            <span class="row-title">{{ $t("status") }}</span>
          </v-col>
          <v-col>
            <span v-if="status != null">
              <span v-if="status == 'CONFIRMED'" class="status good-color">
                <div class="alignCenter">
                  <svg-icon type="mdi" :path="pathChecked"></svg-icon>
                  
                  <code> {{ $t("confirmed") }}</code>
                </div>
              </span>
              <span v-else class="status bad-color" style="display:flex; align-items: center;">
                <svg-icon class="bad-color" type="mdi" :path="pathCancel"></svg-icon>
                <code>
                  {{
                    status == "UNCONFIRMED" || status == "UNKNOWN"
                      ? $t(status)
                      : status
                  }}
                </code>

                <v-btn
                variant="tonal"
                  v-if="status == 'UNCONFIRMED'"
                  class="activate-button"
                  @click="dialog = true"
                >
                  {{ $t("activate") }}</v-btn
                >
                <v-dialog v-model="dialog" >
                  <div class="justified" >

                  
                  <v-card max-width="500px" >

                    <v-card-text>
                      {{ $t("activate_warning") }}
                    </v-card-text>

                    <v-card-actions>
                      <v-btn class="bad-color" @click="dialog = false">{{
                        $t("cancel")
                      }}</v-btn>
                      <v-btn  @click="onConfirm" style="color: #34a3d5">{{
                        $t("confirm")
                      }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
                </v-dialog>
              </span>
            </span>

            <v-progress-circular
              v-else
              :size="20"
              :width="3"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </div>
    </v-list>
  </div>
</template>

<script>
import { hasFlag } from "country-flag-icons";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiAccount, mdiCheckCircle, mdiCancel } from "@mdi/js";

export default {
  name: "UserInfos",
  inject: ["$apiHydrao", "$global_error"],
  components: {
    SvgIcon,
  },
  data() {
    return {
      active: false,
      status: null,
      path: mdiAccount,
      pathCancel : mdiCancel,
      dialog: false,
      pathChecked: mdiCheckCircle,
    };
  },
  props: {
    id: null,
    email: null,
    registration_date: null,
    country: null,
  },
  mounted() {
    this.updateStatus();
  },
  methods: {
    formatDate(d) {
      let date = new Date(d);

      let day = ("0" + date.getUTCDate()).slice(-2);
      let month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
      let year = date.getUTCFullYear();

      let formattedDate = day + "/" + month + "/" + year;
      return formattedDate;
    },
    hasFlag() {
      return hasFlag(this.country);
    },
    updateStatus() {
      if (this.email) {
        try {
          this.$apiHydrao.getUserStatus(this.email).then(
            (success) => {
              if (success.data && success.data.status) {
                this.status = success.data.status;
              } else {
                this.status = success;
              }
            },
            (fail) => {
              this.status = fail;
            }
          );
        } catch (e) {
          this.status = "UNKNOWN";
          console.log(e);
        }
      }
    },
    async onConfirm() {
      this.status = null;
      let res = await this.$apiHydrao.activateUserStatus(this.email);
      if (res && res.status != 200) {
        if (res.response && res.response.data && res.response.data.code) {
          this.$global_error.newError(
            res.response.data.code + ": " + res.response.data.message
          );
        } else {
          this.$global_error.newError(res);
        }
      } else if (!res) {
        this.$global_error.newError(res);
      }
      this.updateStatus();
      this.dialog = false;
    },
  },
  watch: {
    email() {
      this.updateStatus();
    },
  },
  i18n: {
    messages: {
      en: {
        activate: "Activate",
        activate_warning:
          "Activate this account manually without email validation",
        confirm: "Confirm",
        cancel: "Cancel",
        email: "Email",
        status: "Status",
        registration_date: "Registration date",
        country: "Country",
        confirmed: "confirmed",
        UNCONFIRMED: "unconfirmed",
        UNKNOWN: "unknown",
      },
      fr: {
        activate: "Activer",
        activate_warning:
          "Activer ce compte manuellement sans validation par mail ?",
        confirm: "Confirmer",
        cancel: "Annuler",
        email: "Adresse mail",
        status: "Status",
        registration_date: "Date de création",
        country: "Pays",
        confirmed: "activé",
        UNCONFIRMED: "non confirmé",
        UNKNOWN: "inconnu",
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.justified {
  display: flex;
  justify-content: center;
}
@media (max-width: 600px) {
  .row-title {
    font-weight: bold;
  }
}
.custom-list-item {
  min-height: 40px;
  padding: 4px 16px;
  align-items: center;
  transition: padding 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  will-change: padding;
  font-size: 13px;
}

.status i {
  width: 24px;
  margin-right: 5px;
}

.flag {
  display: flex;
  align-items: center;
  font-size: 16px !important;
}

span {
  color: #545454;
}

.activate-button {
  margin-left: 10px;
  margin-top: 2px;
  width: auto;
  height: 18px;
  min-width: 0px;
  font-size: 12px;
}

.lamba-flag-icon {
  margin-right: 8px;
  margin-bottom: 3px;
}

[class*=" flag:"],
[class^="flag:"] {
  height: 16px !important;
  width: 24px !important;
  margin-right: 8px;
  margin-bottom: 3px;
}
</style>

<style>
.activate-button div.md-ripple {
  padding-left: 6px;
  padding-right: 6px;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
