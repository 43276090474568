<template>
  <Flow :value="params.value" />
</template>

<script>
import Flow from "../showerheads/Flow.vue";

import { defineComponent } from 'vue';

export default defineComponent({
  name: "FlowRenderer",
  components: {
    Flow,
  },
});
</script>

<style scoped>
</style>