<template>
  <v-btn class="hydrao-blue-button small detail-email-button-side">
    <span class="email-span">{{ this.email }}</span>
    <svg-icon type="mdi" :path="pathArrowRight"></svg-icon>

  </v-btn>
</template>

<script>
import { defineComponent } from 'vue';

import SvgIcon from '@jamescoyle/vue-icon'
import { mdiArrowRight } from '@mdi/js'

export default defineComponent({
  name: "DetailButton",
  components: { SvgIcon },
  data: () => ({
    pathArrowRight: mdiArrowRight,
  }),
  props: {
    email: null,
  },
});
</script>

<style lang="scss">
.detail-email-button-side .md-ripple .md-button-content {
  width: 100%;
}
</style>

<style lang="scss" >
.detail-email-button-side {
  text-transform: none !important;
  white-space: nowrap;
  width: 100%;
  display: flex;
  flex-direction: row;

  .v-btn__content { 
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 100%;

    .email-span {
      text-overflow: ellipsis;
      overflow: hidden;
      flex: 1;
      text-decoration: none;

    }

    .detail-icon {
      font-size: 18px !important;
      padding-bottom: 4px;
    }
  }

}
</style>

<style scoped></style>

<style>
.detail-email-button-side .md-ripple {
  padding: 0 !important;
}
</style>