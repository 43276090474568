<template>
  <div>
    <div class="details-button-renderer-wrapper">
      <router-link :class="{ 'grid-link': this.encodedEmail && show_email }" :to="'/users/' + encodedEmail">
        <v-btn variant="text" v-if="this.encodedEmail && !show_email" class="details-button hydrao-blue-text-button">
          <svgIconNonReactive type="mdi" :path="pathArrowRight"></svgIconNonReactive>

        </v-btn>

        <DetailButton v-else-if="this.encodedEmail" class="detail-email-button" :email="email" />
      </router-link>
      <associateMeterToUserNonReactive class="associate-user-button" v-if="unregister_meter_uuid" :refresh_bus="refresh_bus"
        :meter_uuid="unregister_meter_uuid" />
    </div>
  </div>
</template>

<script>
import mitt from "mitt";
//import * as Vue from 'vue';
import DetailButton from "../user/DetailButton.vue";
import AssociateMeterToUser from "../meters/AssociateToUserButton.vue";
import { defineComponent } from "vue";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiArrowRight } from "@mdi/js";

import { markRaw } from "vue";
const svgIconNonReactive = markRaw(SvgIcon);
const associateMeterToUserNonReactive = markRaw(AssociateMeterToUser);


const comp = defineComponent({
  name: "DetailButtonRenderer",
  components: {
    DetailButton,
    associateMeterToUserNonReactive,
    svgIconNonReactive,
  },
  data: () => ({
    email: null,
    encodedEmail: null,
    show_email: false,
    unregister_meter_uuid: null,
    refresh_bus: mitt(),
    pathArrowRight: mdiArrowRight,
  }),
  mounted() {
    if (this.params.value && this.params.value.value) {
      this.show_email = this.params.value.show_email;
      this.email = this.params.value.value;

      if (Array.isArray(this.email)) {
        this.email = this.email[0];
      }

      this.encodedEmail = window.btoa(unescape(encodeURIComponent(this.email)));
    } else if (this.params.value && this.params.value.show_register) {
      //register button
      if (this.params.value.meter_uuid) {
        this.unregister_meter_uuid = this.params.value.meter_uuid;
      }
    }
    this.refresh_bus.on("refresh", () => {
      this.params.value.gridReload();
    });
  },
  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        details: "Details",
      },
      fr: {
        details: "Détails",
      },
    },
  },
});

export default comp;
</script>

<style lang="scss" >
div.details-button-renderer-wrapper {
  margin-right: 15px!important;
}

</style>
