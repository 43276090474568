<template>
  <div>
    <v-btn
      variant="outlined"
      class="associate-button hydrao-outline-button small"
      @click="showRegisterDevice = true"
    >
      <svg-icon type="mdi" :path="pathPlus"></svg-icon>
      <span style="font-size: 10px">{{ $t("associate") }}</span>
    </v-btn>

    <v-dialog v-model="showRegisterDevice">
      <div class="containCard">
        <div>
          <v-card>
            <v-card-title> {{ $t("associate_to_user_title") }}</v-card-title>
            <v-card-text class="dialog-content">
              <span v-html="$t('associate_to_user_text')"></span>

              <v-autocomplete
                v-model="associate_autocomplete_value"
                :items="email_list"
                :item-title="(item) => item.email"
                :loading="loading"
                @input="getEmails"
                @change="getEmails"
                @select="updateSelectedAccount"
                @focus="getEmails"
                @update:search="getEmails"
                dense
                return-object
              >
              </v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-btn class="bad-color" @click="showRegisterDevice = false">
                {{ $t("cancel") }}</v-btn
              >
              <v-btn :disabled="!associate_autocomplete_value" style="color: #1c99d1"
                @click="associateMeterToUser(associate_autocomplete_value)"
                >{{ $t("associate_sh") }}</v-btn
              >
            </v-card-actions>
            <v-progress-linear
              v-if="addingMeterToUser"
              indeterminate
            ></v-progress-linear>
          </v-card>
        </div>
      </div>
    </v-dialog>
    <v-snackbar :timeout="2000" v-model="errorTriggered" top left>
      <span style="color: white"
        >{{ $t("errorAssociation") }} {{ errorVal }}</span
      >
    </v-snackbar>
  </div>
</template>

<script>
import mitt from "mitt";
import { defineComponent } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlus } from "@mdi/js";

export default defineComponent({
  name: "AssociateMeterToUser",
  inject: ["$apiHydrao", "$global_error"],
  props: {
    meter_uuid: null,
    refresh_bus: mitt(),
  },
  components: { SvgIcon },
  data: () => ({
    addingMeterToUser: false,
    showRegisterDevice: false,
    selectedAccount: null,
    associate_autocomplete_value: null,
    email_list: [],
    pathPlus: mdiPlus,
    loading:false,
    loaded:false,
    errorVal: "",
    errorTriggered: false,

  }),
  methods: {
    errorDetected(msg) {
      this.errorVal = msg;
      this.errorTriggered = true;
    },
    async getEmails(event) {
      clearTimeout(this.getEmailTimeout);
      this.getEmailTimeout = setTimeout(async () => {
        this.loading = true;
        if(event && event.type =='input') {
          let email = event.srcElement.value;
          if(email) email = email.toLowerCase();
          this.email_list = await this.$apiHydrao.getEmails(email);
        } else {
          this.email_list = await this.$apiHydrao.getEmails();
        }
        
        this.loaded = true;
        this.loading = false;


        // if (!this.add_child_account_autocomplete_value) {
        //   this.email_list = await this.$apiHydrao.getEmails();
        // } else {
        //   const term =
        //     this.add_child_account_autocomplete_value.email.toLowerCase();

        //   this.email_list = await this.$apiHydrao.getEmails(term);
        // }
      }, 800);
    },
    updateSelectedAccount(new_val) {
      window.setTimeout(() => {
        this.selectedAccount = new_val;
        this.associate_autocomplete_value = new_val.email;
      }, 50);
    },
    async associateMeterToUser(account) {
      console.log(account.id);
      if (this.meter_uuid) {
        this.addingMeterToUser = true;

        let res = await this.$apiHydrao.associateMeter(
          account.id,
          this.meter_uuid
        );
        if (!res.status || res.status != 200) {
          if (res.response && res.response.data && res.response.data.error) {
            switch (res.response.data.error) {
              case "AlreadyRegistered":
                this.errorDetected("AlreadyRegistered");

                this.$global_error.newError(
                  this.$i18n.messages[this.$i18n.locale].already_registered
                );
                break;
              case "RegisterUnauthorized":
              this.errorDetected("RegisterUnauthorized");
                this.$global_error.newError(
                  this.$i18n.messages[this.$i18n.locale].register_unauthorized
                );
                break;
              case "PlaceAccessDenied":
              this.errorDetected("PlaceAccessDenied");
                this.$global_error.newError(
                  this.$i18n.messages[this.$i18n.locale].place_access_denied
                );
                break;

              default:
              this.errorDetected("default");
                this.$global_error.newError(res);
                break;
            }
          } else {
            this.$global_error.newError(res);
          }
        } else {
          if (this.refresh_bus) {
            this.refresh_bus.emit("refresh");
          }
        }
        this.showRegisterDevice = false;
        this.selectedAccount = null;
        this.associate_autocomplete_value = "";
        this.addingMeterToUser = false;
      }
    },
  },

  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        email: "Email",

        associate_to_user_title: "associate to user",
        associate_to_user_text:
          "the shower head will be added to selected user main group",

        associate_sh: "associate",
        no_email: "No emails",
        cancel: "Cancel",
        associate: "Associate",

        errorAssociation: "Error occured during association  : ",

      },
      fr: {
        email: "Adresse mail",

        associate_to_user_title: "associer à un utilisateur",
        associate_to_user_text:
          "le pommeau sera ajouté au groupe principal de l'utilisateur choisi",

        associate_sh: "associer",

        no_email: "Aucune adresse mail",
        cancel: "Annuler",
        associate: "Associer",

        errorAssociation: "Une erreur est survenue lors de l'association : ",

      },
    },
  },
});
</script>

<style lang="scss" scoped>
.containCard{
  display: flex; justify-content: center;
}
.associate-button {
  width: 100%;
}
.dialog-content {
  padding-left: 24px;
  padding-right: 24px;
}
</style>
