<template>
  <span>{{params.value}}</span>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "DeviceNameRenderer",
});
</script>

<style scoped>
span {
  user-select: text;
}
</style>