<template>
  <code class="gray-text">{{ this.value }}</code>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
  name: "CodeRenderer",
  data: () => ({
    value: null
  }),
  mounted() {
    this.value = this.params.value;
  }
});
</script>

<style scoped>
code {
  user-select: text;
}
</style>