<template>
  <div >
    
    <img src="../../assets/sh_icon.svg" alt="HYDRAO" />
    <span class="ml gray-text">{{ this.value }}</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "NbShowerheadsRenderer",
  data: () => ({
    value: null
  }),
  mounted() {
    this.value = this.params.value;
  }
});
</script>

<style lang="scss" scoped>
.ml{
  margin-left: 0.5em;
}
</style>