<template>
  <div>
    <v-btn
      variant="text"
      @click="dialog = true"
      v-if="this.user_id && this.device_uuid"
      class="disassociate-button bad-color"
      :disabled="loading"
    >
      <svg-icon v-if="!loading" type="mdi" :path="pathClose"></svg-icon>
      <svg-icon v-else type="mdi" :path="pathTimerSand"></svg-icon>
    </v-btn>

    <v-dialog v-model="dialog" class="dialog-confirm"  width="unset">
      <v-card>
        <v-card-title>{{ $t("disassociate") }}</v-card-title>
        <v-card-text>
          <div
            v-html="
              $t('disassociate_content', {
                uuid: this.device_uuid,
                email: this.email,
              })
            "
          ></div>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="dialog = false">{{ $t("cancel") }}</v-btn>
          <v-btn @click="disassociateMeter">{{ $t("confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClose, mdiTimerSand } from "@mdi/js";

export default defineComponent({
  name: "DisassociateMeterButtonRenderer",
  inject: ["$apiHydrao", "$global_error"],
  components: { SvgIcon },
  data: () => ({
    user_id: null,
    email: null,
    device_uuid: null,
    dialog: false,
    loading: false,
    pathClose: mdiClose,
    pathTimerSand: mdiTimerSand,
  }),
  mounted() {
    if (
      this.params.value &&
      this.params.value.user_id &&
      this.params.value.device_uuid
    ) {
      this.user_id = this.params.value.user_id;
      this.device_uuid = this.params.value.device_uuid;
      this.email = this.params.value.email;
    }
  },
  methods: {
    async disassociateMeter() {
      this.loading = true;
      if (this.user_id && this.device_uuid) {
        let res = await this.$apiHydrao.disassociateMeter(
          this.user_id,
          this.device_uuid
        );
        if (!res.status || res.status != 200) {
          if (res.response && res.response.data && res.response.data.error) {
            this.$global_error.newError(
              res.response.data.error + ": " + res.response.data.message
            );
          } else {
            this.$global_error.newError(res);
          }
        } else {
          //refresh grid
          this.params.value.email = null;
          this.params.value.user_id = null;
          this.params.value.gridReload();
        }
      }
      this.loading = false;
    },
  },
  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        disassociate: "Disassociate",
        disassociate_content:
          "Disassociate the meter <strong><code>{uuid}</code></strong> from the user <strong>{email}</strong> ?",
        cancel: "Cancel",
        confirm: "Confirm",
      },
      fr: {
        disassociate: "Désassocier",
        disassociate_content:
          "Désassocier le compteur <strong><code>{uuid}</code></strong> du compte <strong>{email}</strong> ?",
        cancel: "Annuler",
        confirm: "Confirmer",
      },
    },
  },
});
</script>

<style scoped>
.disassociate-button {
  margin-top: 3px;
}
</style>
