<template>
  <div class="home">
    <v-app id="md-app" md-waterfall md-mode="fixed">
      <div class="topStyle">
        <div class="f1" style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            ">
          <div style="margin-left:20px">
            <img src="../assets/logo-hydrao.svg" alt="HYDRAO" class="logo" />
          </div>

          <div style="margin-right:125px">
            <span class="title">{{ $t("title") }}</span>
          </div>
          <div>
            <span>{{ $store.getters.getUsername }}</span>
            <v-btn variant="text" v-on:click="logout" class="buttonStyle">
              <svgIconNonReactive type="mdi" :path="pathLogout"></svgIconNonReactive>
            </v-btn>
          </div>
        </div>
        <div class="f2">
          <div class="container">

            <v-tabs class="tabs" @click="tabChanged">
              <v-tab to="/users" id="tab-users" :custom="false" width="150px">
                <svgIconNonReactive type="mdi" :path="pathUtilisateurs"></svgIconNonReactive>

                <span class="margeIcon"> {{ $t("tabs.users") }}</span>
              </v-tab>

              <v-tab to="/shower-heads" id="tab-sh" :custom="true" width="150px">
                <img src="../assets/white_sh_icon.svg" alt="Icon" />
                <span class="margeIcon"> {{ $t("tabs.showerheads") }} </span></v-tab>
              <v-tab to="/meters" id="tab-meters" :custom="custom_sh_icon" width="150px">
                <img src="../assets/white_meter_icon.svg" alt="Icon" />

                <span class="margeIcon"> {{ $t("tabs.meters") }} </span></v-tab>
              <v-tab to="/devices" id="tab-devices" :custom="false" width="150px">
                <svgIconNonReactive type="mdi" :path="pathAppareils"></svgIconNonReactive>

                <span class="margeIcon"> {{ $t("tabs.gateways") }}</span>
              </v-tab>
            </v-tabs>

            <div class="localeSelect">
              <v-col>
                <LocaleSelect />
              </v-col>
            </div>
            <!-- <div @click="tabChanged" class="tabs">
              <v-row>
                <v-col>
                 
                </v-col>
               
              </v-row>
            </div> -->
          </div>
        </div>
      </div>
      <div class="bg-hydrao app-content">
        <router-view />
      </div>
    </v-app>

    <v-snackbar :timeout="1000000" :md-active="has_error" persistent>
      <span>{{ error_message }}</span>
      <v-btn class="md-primary" @click="$global_error.close()">{{
        $t("close")
      }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import LocaleSelect from "../components/LocaleSelect.vue";
//import Icon from "../components/Icon.vue";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiAccountGroup, mdiCellphone, mdiLogout } from "@mdi/js";

import { markRaw } from "vue";
const svgIconNonReactive = markRaw(SvgIcon);

export default {
  name: "Home",
  inject: ["$customCache", "$global_error"],
  components: {
    LocaleSelect,
    svgIconNonReactive,
    //Icon,
  },
  props: {
    custom: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    custom_sh_icon: "white_sh_icon",
    custom_meter_icon: "white_meter_icon",
    pathUtilisateurs: mdiAccountGroup,
    pathAppareils: mdiCellphone,
    pathLogout: mdiLogout,
  }),
  mounted() {
    this.tabChanged();
  },
  computed: {
    has_error: function () {
      return this.$global_error.hasError();
    },
    error_message: function () {
      let error = this.$global_error.getError();
      let formated_error = "";

      if (error && error.message) {
        if (error.name) formated_error += error.name + ": ";
        if (error.message) formated_error += error.message;
        return formated_error;
      }

      return error;
    },
  },
  methods: {
    tabChanged() {
      if (this.$route.path == "/shower-heads") {
        this.custom_sh_icon = "white_sh_icon_active";
      } else {
        this.custom_sh_icon = "white_sh_icon";
      }

      if (this.$route.path == "/meters") {
        this.custom_meter_icon = "white_meter_icon_active";
      } else {
        this.custom_meter_icon = "white_meter_icon";
      }
    },
    logout: function () {
      this.$store.commit("clearTokens");
      this.$store.commit("setUsername", null);
      this.$customCache.clear();
      this.$router.push({ name: "Login" });
    },
  },
  i18n: {
    messages: {
      en: {
        title: "Dashboard",
        logout: "Log out",
        tabs: {
          users: "Users",
          showerheads: "Shower heads",
          meters: "Meters",
          devices: "Devices",
          gateways: "Gateways",
        },
        close: "Close",
      },
      fr: {
        title: "Tableau de bord",
        logout: "Déconnexion",
        tabs: {
          users: "Utilisateurs",
          showerheads: "Pommeaux",
          meters: "Compteurs",
          devices: "Appareils",
          gateways: "Passerelles",
        },
        close: "Fermer",
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 50px;
  width: min(200px, 80%);
}

.home {
  height: 100%;
}

.home-toolbar {
  z-index: 10;
}

#md-app {
  height: 200px;
  //max-height: 100vh;
}

.title {
  text-transform: uppercase;
  font-weight: 100 !important;
  font-size: min(max(2vw, 20px), 60px);
  line-height: min(max(2vw, 20px), 60px);
  letter-spacing: 0.29em;
}

.tab-label {
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.7);
}

a.router-link-active .tab-label {
  color: #ffffff;
}

.marge {
  margin-top: 20px;
}

.localeSelect {
  position: absolute;
  right: -20px;
  top:50px;
}
</style>

<style>
div.LocaleSelect .md-field {
  margin-top: 0px;
  margin-bottom: 0px;
}

@media (min-width: 1281px) {
  .app-content {
    width: calc(100% - 3px);
  }
}

.md-app-scroller {
  background-color: #f5f8fa;
}

.dialog-confirm .md-dialog-container .md-dialog-actions .md-button .md-ripple {
  position: unset;
}

.ag-header-viewport {
  background-color: #f5f8fa;
}

.ag-header {
  border-bottom: none !important;
}

.ag-cell {
  border-color: rgba(0, 0, 0, 0) !important;
}

.ag-center-cols-viewport {
  overflow-x: hidden !important;
}

.f1 {
  width: 100%;
}

.f2 {

  width: 100%;
  margin-top: 7px
}

.container {
  display: flex;
}

.tabs {
  flex: 1;
  margin-left: 20px;
}

.localeSelect {
  flex: 0;
  margin-right: 40px;
}

/* reduction du nb d'inline css*/
.topStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: white;
  background-color: #008dcc;
  height: 112px;
}

.buttonStyle {
  background-color: #008dcc !important;
  color: white;
  border-radius: 30px;
  margin-left: 5px;
}

.margeIcon {
  margin-left: 8px;
}
</style>
