<template>
  <div
    class="alignCenter col-head"
    @click="onSortRequested"
    
  >
    <span class="header-text gray-text">{{ $t(params.displayName) }}</span>
    <svg-icon
      class="line-height"
      v-if="orderIndex == 1"
      type="mdi"
      :path="pathChevronUp"
    ></svg-icon>

    <svg-icon
      class="line-height"
      v-if="orderIndex == 2"
      type="mdi"
      :path="pathChevronDown"
    ></svg-icon>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

export default defineComponent({
  name: "HeadersRenderer",
  data: function () {
    return {
      orderIndex: 0,
      orders: [null, "asc", "desc"],
      sortable: false,
      pathChevronDown: mdiChevronDown,
      pathChevronUp: mdiChevronUp,
    };
  },
  components: {
    SvgIcon,
    //Icon,
  },
  mounted() {
    this.sortable = this.params.enableSorting;
    if (this.sortable) {
      this.onSortChanged();
      this.params.column.addEventListener("sortChanged", this.onSortChanged);
    }
  },
  methods: {
    onSortChanged: function () {
      this.orderIndex = 0;
      if (this.params.column.isSortAscending()) {
        this.orderIndex = 1;
      } else if (this.params.column.isSortDescending()) {
        this.orderIndex = 2;
      }
    },
    onSortRequested: function () {
      if (this.sortable) {
        this.orderIndex++;
        this.orderIndex %= this.orders.length;
        this.params.setSort(this.orders[this.orderIndex], false);
      }
    },
  },
  i18n: {
    // `i18n` option, setup locale info for component
    messages: {
      en: {
        email: "USER",
        status: "STATUS",
        registration_date: "REGISTRATION DATE",
        last_seen: "LAST SEEN",
        last_update: "LAST UPDATE",
        nb_showerheads: "SHOWER HEADS",
        nb_meters: "METERS",
        nb_gateways: "DEVICES",
        nb_features: "FEATURES",
        details: "DETAILS",
        type: "TYPE",
        model: "MODEL",
        app_version: "APP VERSION",
        os_version: "OS VERSION",
        users: "USERS",
        last_sync_date: "SYNCHRONIZATION",
        uuid: "UUID",
        label: "LABEL",
        threshold: "THRESHOLDS (L)",
        disassociate: "DISASSOCIATE",
        flow: "FLOW",
        serial: "SERIAL",
        batch_number: "BATCH NUMBER",
      },
      fr: {
        email: "UTILISATEUR",
        status: "STATUS",
        registration_date: "DATE DE CRÉATION",
        last_seen: "VU IL Y A",
        last_update: "DERNIÈRE MISE À JOUR",
        nb_showerheads: "POMMEAUX",
        nb_meters: "COMPTEURS",
        nb_gateways: "APPAREILS",
        nb_features: "FONCTIONNALITÉS",
        details: "DÉTAILS",
        type: "TYPE",
        model: "MODÈLE",
        app_version: "VERSION APP",
        os_version: "VERSION OS",
        users: "UTILISATEURS",
        last_sync_date: "SYNCHRONISATION",
        uuid: "UUID",
        label: "LABEL",
        threshold: "SEUILS (L)",
        disassociate: "DÉSASSOCIER",
        flow: "DÉBIT",
        serial: "NUMÉRO DE SÉRIE",
        batch_number: "NUMÉRO DE LOT",
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.alignCenter{
  display: flex; align-items: center
}
.header-text {
  font-family: Inter, Roboto;
  font-style: normal;
  font-weight: 600;

  /* identical to box height */
  letter-spacing: 0.05em;
  text-transform: uppercase;

  width: calc(100%);
  text-overflow: ellipsis;
  overflow: hidden;
}

.line-height {
  margin-left: 0;
  height: 15px;
}

.col-head {
  width: inherit;
  display: contents;
}
</style>
