<template>
  <div class="UserAccounts">
    <v-progress-linear v-if="removing_child"  color="hydrao-blue" indeterminate></v-progress-linear>
    <v-progress-linear
      v-if="accounts == null"
      indeterminate
      color="hydrao-blue"
    ></v-progress-linear>

    <v-list v-else-if="accounts.length > 0" class="custom-responsive">
      <div v-for="(account, i) in accounts" :key="`Account${i}`">
        <v-divider v-if="i > 0"></v-divider>

        <v-list-item>
          <div style="display: flex; justify-content: space-between">
            <div class="align-center">
              <div>
                <svgIconNonReactive class="light-gray-icon"
                  type="mdi"
                  :path="pathAccountCircle"
                ></svgIconNonReactive>
              </div>
              <div>
                <span style="margin-left: 10px" classs="gray-text">{{ account.email }}</span>
              </div>
            </div>
            <div class="align-center">
              <v-badge
                v-if="account.nb_showerheads_main_group != null"
                color="#1eae58"
                :content="account.nb_showerheads_main_group"
              ></v-badge>
              <v-badge
                v-if="account.nb_meters_main_group != null"
                color="#ebb42b"
                style="margin-left: 25px"
                :content="account.nb_meters_main_group"
              ></v-badge>
              
              <v-btn
                variant="text"
                v-if="mode == 'child'"
                @click="
                  remove_child = true;
                  email_to_remove = account.email;
                "
              >
                <svgIconNonReactive
                  type="mdi"
                  :path="pathClose"
                  class="bad-color"
                ></svgIconNonReactive>
              </v-btn>
              <v-btn variant="text" @click="forcePageReload(account)">
                <svgIconNonReactive
                  type="mdi"
                  :path="pathArrowRight"
                  color="#008dcc"
                ></svgIconNonReactive>
              </v-btn>
            </div>
          </div>
        </v-list-item>
      </div>
    </v-list>
    <div v-else>
      <span>{{ $t("no_account") }}</span>
    </div>

    <v-dialog v-model="remove_child">
      <v-card>
        <v-card-title>{{ $t("remove_child") }}</v-card-title>
        <v-card-text>
          <span
            v-html="
              $t('remove_child_warning', {
                email: email_to_remove,
              })
            "
          ></span>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="remove_child = false">{{ $t("cancel") }}</v-btn>
          <v-btn @click="removeChild">{{ $t("confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mitt from "mitt";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClose, mdiArrowRight, mdiAccountCircle } from "@mdi/js";

import { markRaw } from "vue";
const svgIconNonReactive = markRaw(SvgIcon);

export default {
  name: "UserAccounts",
  inject: ["$apiHydrao", "$global_error"],
  components: {
    svgIconNonReactive,
  },
  data() {
    return {
      remove_child: false,
      removing_child: false,
      email_to_remove: null,
      pathClose: mdiClose,
      pathArrowRight: mdiArrowRight,
      pathAccountCircle: mdiAccountCircle,
    };
  },
  props: {
    accounts: null,
    mode: null,
    user: null,
    bus: mitt(),
  },
  methods: {
    forcePageReload(account) {
      let page = "/users/" + this.encode(account.email);

      window.location.href = page;
    },
    encode(string) {
      return window.btoa(unescape(encodeURIComponent(string)));
    },
    async removeChild() {
      this.remove_child = false
      this.removing_child = true;

      if (
        this.mode != "child" ||
        !this.user ||
        !this.user.id ||
        !this.user.email
      ) {
        this.$global_error.newError(
          this.$i18n.messages[this.$i18n.locale].wrong_mode
        );
      } else if (
        this.accounts.filter((account) => account.email == this.email_to_remove)
          .length == 0
      ) {
        this.$global_error.newError(
          this.$i18n.messages[this.$i18n.locale].not_child_error
        );
      } else {
        let res = await this.$apiHydrao.removeChildAccount(
          this.user.id,
          this.email_to_remove
        );
        if (!res.status || res.status != 200) {
          this.$global_error.newError(res);
        } else {
          if (this.bus) {
            this.bus.emit("childRemoved", this.email_to_remove);
          }
        }
      }

      this.removing_child = false;
    },
  },
  i18n: {
    messages: {
      en: {
        no_account: "No accounts",
        see: "See",
        cancel: "Cancel",
        confirm: "Confirm",
        remove_child: "Remove child account",
        remove_child_warning:
          "Do you want to remove the following child account:<br><strong>{email}</strong> ?",
        wrong_mode: "You can not remove child accounts.",
        not_child_error: "The user does not have this account as a child.",
      },
      fr: {
        no_account: "Aucun compte",
        see: "Voir",
        cancel: "Annuler",
        confirm: "Confirmer",
        remove_child: "Retirer un compte enfant",
        remove_child_warning:
          "Êtes vous sûr de vouloir retirer le compte enfant suivant:<br><strong>{email}</strong> ?",
        wrong_mode: "Vous ne pouvez pas retirer de compte enfant.",
        not_child_error: "Cet utilisateur n'a pas ce compte enfant.",
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.align-center {
  display: flex;
  align-items: center;
}
.UserAccounts {
  height: 100%;
}

.title {
  text-transform: uppercase;
  font-family: Inter;
}

span {
  color: #545454;
}

.bg-meter {
  background: #ebb42b !important;
}

.bg-sh {
  background: #1eae58 !important;
}

.md-badge {
  margin: 0 3px !important;
}
</style>
