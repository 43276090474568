//import * as VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Users from '../views/Users.vue'
import User from '../views/User.vue'
import Showerheads from '../views/Showerheads.vue'
import Meters from '../views/Meters.vue'
import Devices from '../views/Devices.vue'
import Login from '../views/Login.vue'
import store from '../store'
import { createWebHistory, createRouter } from "vue-router";
//Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    redirect: { name: "Users" },
    component: Home,
    children: [{
      path: "users",
      name: "Users",
      component: Users
    }, {
      path: "shower-heads",
      name: "Showerheads",
      component: Showerheads
    },{
      path: "meters",
      name: "Meters",
      component: Meters
    }, {
      path: "devices",
      name: "Devices",
      component: Devices
    }, {
      path: "users/:encodedEmail",
      name: "User",
      component: User,
      props: true
    }]
  },
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.getters.isAuthenticated) { next({ name: 'Login' }) }
  else { next() }
})

export default router
