<template>
  <div>
    <div v-if="value">
      <img v-if="value == 'bluetooth'" src="../../assets/devices/bluetooth.svg" :alt="value" />
      <img v-if="value == 'apple'" src="../../assets/devices/apple.svg" :alt="value"  />
      <img v-if="value == 'lora'" src="../../assets/showerheads/lora.svg" :alt="value" style="height: 24px;"/>
      <img v-if="value == 'android'" src="../../assets/devices/android.svg" :alt="value" />
      <img v-if="value=='device_unknown'" src="../../assets/devices/device_unknown.svg" :alt="value" />


    </div>
    <v-progress-circular
    class="row-spinner"
    v-bind:class="{ hidden: value }"
    indeterminate
    size="20"
    width="3"
    ></v-progress-circular>
   
    
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "DeviceTypeRenderer",
  data: () => ({
    value: null,
  }),
  mounted() {
    let val = this.params.value;
    if(val!= null){

    
      if (typeof val == "object" && val.loaded) {
        val = val.value;
      }
      if (typeof val != "object" || val.loaded) {
        switch (val) {
          case "BLEGateway":
            this.value = "bluetooth";
            break;
          case "AppleDevice":
            this.value = "apple";
            break;
          case "AndroidDevice":
            this.value = "android";
            break;
          case "LoRaGateway":
            this.value = "lora";
            break;
          default:
            this.value = "device_unknown";
            break;
        }
      }
    }
  },
});
</script>

<style lang="scss" scoped>
.type-icon {
  height: 24px;
  margin-left: 5px;
  max-width: inherit;
}

.hidden {
  display: none;
}

.row-spinner {
  margin-top: 12px;
  margin-left: 10px;
}
</style>