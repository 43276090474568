<template>
  <span>
    <!-- Boutto pour ajouter demo-->
    <v-btn
      class="btnstyle hydrao-blue-button"
      :disabled="!userActionReady"
      @click="dialog = true"
    >
      <v-progress-circular
        v-if="userActionLoading"
        class="dark-spinner"
        indeterminate
        height="13"
        size="2"
      ></v-progress-circular>
      <svg-icon type="mdi" :path="pathPlus"></svg-icon>

      <span v-bind:class="{ 'loading-span': userActionLoading }">{{
        $t("add_demo")
      }}</span>
    </v-btn>
    <!--===================================-->
    <!-- boite de dialogue liée au button "ajouter demo"-->

    <!-- DIALOGUE POUR DEMO-->
    <v-dialog v-model="dialog" class="dialogDemo">
      <v-card>
        <v-card-title>{{ $t("associate_demo_title") }}</v-card-title>
        <v-card-text class="dialog-content">
          <span v-html="$t('associate_demo_text')"></span>

          <v-text-field  v-model="demo_nb_users" >
            <label>{{ $t("demo_nb_users") }}</label>
          </v-text-field>

          <v-text-field v-model="demo_nb_toilets"  >
            <label>{{ $t("demo_nb_toilets") }}</label>
           </v-text-field>

          <v-text-field v-model="demo_nb_showers">
            <label> {{ $t("demo_nb_showers") }}</label>
           </v-text-field>

          <div class="containerDate">
            <div>
              <div>
                <label> {{ $t("demo_starting_date") }}</label>
              </div>
              <div>
                <VDatePicker v-model="demo_from_date" mode="date" :attributes="attrs" />
              </div>
            </div>
            <div>
              <div>
                <div>
                  <label>{{ $t("demo_expiration_date") }}</label>
                </div>
                <div>
                  <VDatePicker v-model="demo_expiration_date" mode="date" />
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn class="bad-color" @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            style="color: #34a3d5"
            :disabled="demo_nb_users <= 1 || demo_nb_users > 60 
            || demo_nb_toilets <= 1 || demo_nb_toilets > 60 ||
            demo_nb_showers <= 1 || demo_nb_showers > 60 
            || demo_expiration_date.getTime() <= demo_from_date.getTime()"
            @click="addDemo()"
          >
            {{ $t("associate_demo") }}
          </v-btn>
        </v-card-actions>

        <v-progress-linear v-if="creatingDemo" indeterminate>
        </v-progress-linear>
      </v-card>
    </v-dialog>

    <!--===================================================-->
    <!-- bouton associer-->

    <v-btn
      class="btnstyle hydrao-blue-button"
      :disabled="!userActionReady"
      @click="dialogDemo = true"
    >
      <v-progress-circular
        v-if="userActionLoading"
        class="dark-spinner"
        indeterminate
        height="13"
        size="2"
      ></v-progress-circular>
      <svg-icon type="mdi" :path="pathPlus"></svg-icon>

      <span v-bind:class="{ 'loading-span': userActionLoading }"
        >{{ $t("associate") }}
      </span>
    </v-btn>
    <!--======================-->

    <!-- DIALOGUE POUR ASSOCIER-->

    <v-dialog v-model="dialogDemo">
      <div class="dialog">
        <div>
          <v-card>
            <v-card-title>
              {{ $t("associate_title") }}
            </v-card-title>
            <v-card-text class="dialog-content">
              <span v-html="$t('associate_text', { email: this.email })"></span>

              <v-autocomplete
                v-model="associate_autocomplete_value"
                :items="uuid_list"
                :item-title="(item) => item.device_uuid"
                :loading="loading"
                @focus="getUUIDs"
                @input="getUUIDs"
                @change="getUUIDs"
                @update:search="getUUIDs"
                @select="updateSelectedUUID"
                dense
              >
              </v-autocomplete>
            </v-card-text>

            <v-card-actions>
              <v-btn class="bad-color" @click="dialogDemo = false"> {{ $t("cancel") }}</v-btn>

              <v-btn style="color:#1c99d1" @click="addMeter(associate_autocomplete_value)">
                {{ $t("associate") }}
              </v-btn>
            </v-card-actions>
            <v-progress-linear v-if="addingMeter" indeterminate>
            </v-progress-linear>
          </v-card>
        </div>
      </div>
    </v-dialog>
    <v-snackbar :timeout="2000" v-model="errorTriggered" top left>
      <span style="color: white"
        >{{ $t("errorAssociation") }} {{ errorVal }}</span
      >
    </v-snackbar>
  </span>
</template>
<script>
import mitt from "mitt";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlus } from "@mdi/js";

export default {
  name: "AssociateMetersButtons",
  inject: ["$apiHydrao", "$global_error"],
  components: { SvgIcon },
  data: () => ({
    associate_autocomplete_value: "",
    showAssociate: false,
    addingMeter: false,
    uuid_list: [],
    selectedUUID: null,
    showAssociateDemo: false,
    loaded: false,
    loading:false,
    demo_nb_users: null,
    demo_nb_toilets: null,
    demo_nb_showers: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10),
    demo_from_date: new Date(new Date().setMonth(new Date().getMonth() )),
    demo_expiration_date: new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ),
    creatingDemo: false,
    dialog: false,
    dialogDemo: false,
    menu: false,
    pathPlus: mdiPlus,
    picker: new Date().toISOString().substring(0, 10),

    errorVal: "",
    errorTriggered: false,
  }),
  props: {
    user_id: null,
    email: null,
    meter_grid_bus: mitt(),
  },
  computed: {
    userActionLoading: function () {
      return this.user_id == null;
    },
    userActionReady: function () {
      return !this.userActionLoading && !isNaN(parseInt(this.user_id));
    },
  },
  methods: { 
    errorDetected(msg) {
      this.errorVal = msg;
      this.errorTriggered = true;
    }, 

     

    async addMeter(uuid) {
      console.log("uuuuid passé : ", uuid);
      console.log("user_id passé : ", this.user_id);
      this.addingMeter = true;
      let res = await this.$apiHydrao.associateMeter(this.user_id, uuid);
      if (!res.status || res.status != 200) {
        console.log(res);
        if (res.response && res.response.data && res.response.data.error) {
          switch (res.response.data.error) {
            case "AlreadyRegistered":
            this.errorDetected("AlreadyRegistered");
              this.$global_error.newError(
                this.$i18n.messages[this.$i18n.locale].already_registered
              );
              break;
            case "RegisterUnauthorized":
            this.errorDetected("RegisterUnauthorized");
              this.$global_error.newError(
                this.$i18n.messages[this.$i18n.locale].register_unauthorized
              );
              break;
            case "PlaceAccessDenied":
            this.errorDetected("PlaceAccessDenied");
              this.$global_error.newError(
                this.$i18n.messages[this.$i18n.locale].place_access_denied
              );
              break;

            default:
            this.errorDetected("default");
              this.$global_error.newError(res);
              break;
          }
        } else {
          this.$global_error.newError(res);
        }
      } else {
        this.meter_grid_bus.emit("meterGridRefresh");
        //window.location.reload();
      }
      this.dialogDemo = false;
      this.showAssociate = false;
      this.selectedUUID = "";
      this.associate_autocomplete_value = "";
      this.addingMeter = false;
    },
    async addDemo() {
      this.creatingDemo = true;

      let res = await this.$apiHydrao.createDemoMeter(
        this.demo_nb_users,
        this.demo_nb_toilets,
        this.demo_nb_showers,
        this.demo_from_date,
        this.demo_expiration_date
      );
      if (!res) {
        this.$global_error.newError("empty response");
      } else if (!res.status || res.status != 200) {
        this.$global_error.newError(res);
      } else {
        await this.addMeter(res.data.device_uuid);
      }
      this.showAssociateDemo = false;
      this.creatingDemo = false;
      this.dialog = false;
    },

    updateSelectedAccount(new_val) {
      window.setTimeout(() => {
        this.selectedAccount = new_val;
        this.add_child_account_autocomplete_value = new_val.email;
      }, 50);
    },

    async getUUIDs(event) {
      clearTimeout(this.getUUIDTimeout);
      this.getUUIDTimeout = setTimeout(async () => {


        this.loading = true;
        if(event && event.type =='input') {
          this.uuid_list = await this.$apiHydrao.getMeterUUIDs(event.srcElement.value);
        } else {
          this.uuid_list = await this.$apiHydrao.getMeterUUIDs();
        }
        
        this.loaded = true;
        this.loading = false;


        // if (!this.associate_autocomplete_value) {
        //   this.uuid_list = await this.$apiHydrao.getMeterUUIDs();
        // } else {
        //   const term = this.associate_autocomplete_value.toLowerCase();
        //   this.uuid_list = await this.$apiHydrao.getMeterUUIDs(term);
        // }
        // this.loaded = true;
      }, 800);
      console.log("getUUIDs", this.uuid_list);
    },
    updateSelectedUUID(new_val) {
      window.setTimeout(() => {
        this.selectedUUID = new_val.device_uuid;
        this.associate_autocomplete_value = new_val.device_uuid;
      }, 50);
    },
  },
  i18n: {
    messages: {
      en: {
        associate: "Associate",
        associate_title: "Associate a new meter",
        associate_text:
          "The meter head will be added in the main group of <strong>{email}</strong>",
        no_sh: "No results.",
        uuid_label: "UUID",
        cancel: "Cancel",

        already_registered: "Meter already associated to this account",
        register_unauthorized: "Meter already associated to another account",
        place_access_denied:
          "User cannot access place: it belongs to another user, or place does not exists.",

        associate_demo_title: "Create a new demo meter",
        associate_demo_text: "This meter will generate new data every day.",
        demo_nb_users: "nb of users",
        demo_nb_users_error: "wrong nb of users",
        demo_nb_toilets: "nb of toilets",
        demo_nb_toilets_error: "wrong nb of toilets",
        demo_nb_showers: "nb of shower heads",
        demo_nb_showers_error: "wrong number of shower heads",
        demo_from_date_error: "invalid date : should be before expiration date",
        demo_expiration_date_error:
          "invalid date: should be in the future and after from date",

        demo_expiration_date: "Date on which the data stop",
        demo_starting_date: "Date on which the data start",
        associate_demo: "Add the demo",

        add_demo: "Add demo",
        errorAssociation: "Error occured during association  : ",

      },
      fr: {
        associate: "Associer",

        associate_title: "Associer un nouveau compteur",
        associate_text:
          "Le nouveau compteur sera ajouté dans le groupe principal de <strong>{email}</strong>",

        no_sh: "Aucun résultat.",
        uuid_label: "UUID",
        cancel: "Annuler",

        already_registered: "Compteur déjà associé à ce compte",
        register_unauthorized: "Compteur déjà associé à un autre compte",
        place_access_denied:
          "L'utilisateur n'a pas accès à l'emplacement: il appartient à un autre compte, ou n'existe pas.",

        associate_demo_title: "Créer un compteur de démo",
        associate_demo_text:
          "Ce compteur génèrera de nouvelles données tous les jours.",

        demo_nb_users: "Nombre d'habitants",
        demo_nb_users_error: "Nombre invalide",
        demo_nb_toilets: "Nombre de toilettes",
        demo_nb_toilets_error: "Nombre invalide",
        demo_nb_showers: "Nombre de pommeaux",
        demo_nb_showers_error: "Nombre invalide",
        demo_from_date_error:
          "date invalide : doit etre avant la date d'expiration",
        demo_expiration_date_error:
          "date invalide : doit etre dans le futur et après la date de début",
        demo_expiration_date: "Date à laquelle les données s'arrêtent",
        associate_demo: "Ajouter la démo",
        demo_starting_date: "Date à laquelle les données commencent",
        add_demo: "Ajouter démo",
        errorAssociation: "Une erreur est survenue lors de l'association : ",

      },
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  display: flex;
  justify-content: center;
}
.containerDate {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.dialogDemo {
  width: 40%;
}
.btnstyle {
  margin-left: 5px;
  padding-right: 15px;
  padding-left: 15px;
}
.md-highlight-text {
  display: inline !important;
}

.uuid-margin {
  margin-left: 10px;
  margin-right: 10px;
}

.head-button {
  padding-top: 3px;
  padding-left: 5px;
  padding-right: 5px;
  height: 30px;

  span {
    margin-left: 3px;
    letter-spacing: 1px;
    font-weight: 500;
    font-family: Roboto;
  }
}
.dialog-content {
  padding-left: 24px;
  padding-right: 24px;
}
</style>
