<template>
  <div id="app" class="bg-hydrao">
    <router-view />
  </div>
</template>

<style lang="scss" scoped>
#app {
  height: 100vh;
}
</style>