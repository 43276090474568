<template>
  <span v-if="this.value!=null" class="flow" :style="'color:'+getColor(this.cacl_value)">
    {{ this.cacl_value }}
    <small>
      <small>L/min</small>
    </small>
  </span>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Flow",
  props: {
    value: null,
  },
  computed: {
    cacl_value() {
      if (this.value != null) {
        return Math.round((this.value + Number.EPSILON) * 10) / 10;
      }
      return null;
    },
  },
  methods: {
    getColor(value) {
      if (value > 7) value = 1;
      else if (value <= 4) value = 0;
      else value = (value - 4) / 3;
      //value from 0 to 1
      var hue = (value * 136).toString(10);
      return ["hsl(", hue, ",100%,40%)"].join("");
    },
  },
});
</script>

<style scoped>

</style>