<template>
  <span>
    <img class="type-icon" v-if="d_type == 'unknown_type'" src="../../assets/showerheads/unknown_type.svg" alt="unknown_type" />
    <img  class="type-icon" v-if="d_type == 'aloe'" src="../../assets/showerheads/aloe.svg" alt="aloe" />
    <img  class="type-icon" v-if="d_type == 'cereus'" src="../../assets/showerheads/cereus.svg" alt="cereus" />
    <img   class="type-icon" v-if="d_type == 'first'" src="../../assets/showerheads/first.svg" alt="first" />
    <img   class="type-icon" v-if="d_type == 'yucca'" src="../../assets/showerheads/yucca.svg" alt="yucca" />

    <img v-if="d_connectivity == 'lora'" src="../../assets/showerheads/lora.svg" :alt="d_connectivity" class="connectivity-icon"/>
    <img v-if="d_connectivity == 'bluetooth'" src="../../assets/showerheads/bluetooth.svg" :alt="d_connectivity"  />

    <img  v-if="d_connectivity == 'unknown_connectivity'" src="../../assets/showerheads/unknown_connectivity.svg" :alt="d_connectivity" />
<!--  
-->
  </span>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "ShowerHeadType",
  data: () => ({
    d_type: null,
    d_connectivity: null
  }),
  props: {
    type: String,
    connectivity: String,
  },
  mounted(){
    this.updateType()
    this.updateConnectivity()
  },
  methods:{
    updateType(){
      switch (this.type) {
        case "aloe":
          this.d_type = "aloe";
          break;
        case "cereus":
          this.d_type = "cereus";
          break;
        case "first":
          this.d_type = "first";
          break;
        case "origin":
          this.d_type = "first";
          break;
        case "yucca":
          this.d_type = "yucca";
          break;

        default:
          this.d_type = "unknown_type";
          break;
      }
    },
    updateConnectivity(){
      switch (this.connectivity) {
        case "BLE":
          this.d_connectivity = "bluetooth";
          break;
        case "LoRa":
          this.d_connectivity = "lora";
          break;

        default:
          this.d_connectivity = "unknown_connectivity";
          break;
      }
    }
  },
  watch: {
    type(){
      this.updateType()
    },
    connectivity() {
      this.updateConnectivity()
    }
  }
});
</script>

<style lang="scss" scoped>
.type-icon {
  height: 35px;
  width: 35px;
}

.connectivity-icon {
  height: 24px;
  width: 24px;
}
</style>