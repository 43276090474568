<template><div>
  <router-link class="grid-link" v-if="nb_users" :to="'/users?g=' + encoded_device">
   
    
    <v-btn variant="text" class="hydrao-blue-button small">
      <span class="users-span"> {{ nb_users }}</span>
      <svg-icon type="mdi" :path="pathArrowRight"></svg-icon>

    </v-btn>
  </router-link>
</div>
</template>

<script>
import { defineComponent } from 'vue';
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiArrowRight } from '@mdi/js'


export default defineComponent({
  name: "DeviceUsersButtonRenderer",
  components: {SvgIcon},
  data: () => ({
    nb_users: null,
    encoded_device: null,
    pathArrowRight : mdiArrowRight
  }),
  mounted() {
    if (
      this.params.value &&
      this.params.value.nb_users &&
      this.params.value.device
    ) {
      this.nb_users = this.params.value.nb_users;
      this.encoded_device = window.btoa(
        unescape(encodeURIComponent(this.params.value.device))
      );
    }
  },
});
</script>

<style lang="scss">
.detail-users-button .md-ripple .md-button-content {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.grid-link {
  display: grid;
  width: 100%;
  margin-top: 6px;
}

.users-span {
  width: calc(100% - 24px);
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

<style scoped>

.users-span {
  font-size: 14px;
  text-transform: none !important;
  padding-left: 18px;
  vertical-align: middle;
  display: inline-block;
  height: 16px;
}
.button-icon {
  font-size: 18px !important;
  padding-bottom: 2px;
}
</style>

<style>
.detail-users-button .md-ripple {
  padding: 0 !important;
}
</style>