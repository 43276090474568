<template>
  <span>
    <!-- Boutton ajouter demo-->
    <v-btn
      class="head-button hydrao-blue-button"
      :disabled="!userActionReady"
      @click="dialogDemo = true"
    >
      <v-progress-circular
        v-if="userActionLoading"
        class="dark-spinner"
        indeterminate
        width="2"
        size="13"
      >
      </v-progress-circular>
      <svg-icon type="mdi" :path="pathPlus"></svg-icon>

      <span v-bind:class="{ 'loading-span': userActionLoading }">
        {{ $t("add_demo") }}
      </span>
    </v-btn>

    <!--===================================-->

    <!-- boite de dialogue liée au button "ajouter demo"-->

    <v-dialog v-model="dialogDemo">
      <div style="display: flex; justify-content: center">
        <div>
          <v-card>
            <v-card-title>{{ $t("associate_demo_title") }}</v-card-title>

            <v-card-text class="dialog-content">
              <span
                v-html="$t('associate_demo_text', { email: this.email })"
              ></span>

              <v-text-field
                :label="$t('demo_nb_shower_per_day')"
                v-model="demo_nb_shower_per_day"
              >
                <!-- <span> {{ $t("demo_nb_shower_per_day_error") }}</span>-->
              </v-text-field>

              <div style="display: flex; justify-content: center">
                <div>
                  <div>
                    <label>{{ $t("demo_expiration_date") }}</label>
                  </div>
                  <div>
                    <VDatePicker
                      v-model="demo_expiration_date"
                      mode="date"
                      :attributes="attrs"
                    />
                  </div>
                </div>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn @click="dialogDemo = false" class="bad-color">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                style="color: rgb(52, 163, 213)"
                :disbled="
                  isNaN(demo_nb_shower_per_day) ||
                  demo_nb_shower_per_day < 1 ||
                  demo_nb_shower_per_day > 10 ||
                  demo_expiration_date == null ||
                  creatingDemo ||
                  addingSh
                "
                @click="addDemo(demo_nb_shower_per_day, demo_expiration_date)"
              >
                {{ $t("associate_demo") }}
              </v-btn>
            </v-card-actions>

            <v-progress-linear v-if="creatingDemo" indeterminate>
            </v-progress-linear>
          </v-card>
        </div>
      </div>
    </v-dialog>
    <!--===================================================-->

    <!-- bouton associer-->
    <v-btn
      class="head-button hydrao-blue-button"
      :disabled="!userActionReady"
      @click="dialog = true"
    >
      <v-progress-circular
        v-if="userActionLoading"
        class="dark-spinner"
        indeterminate
        width="2"
        size="13"
      >
      </v-progress-circular>
      <svg-icon type="mdi" :path="pathPlus"></svg-icon>

      <span v-bind:class="{ 'loading-span': userActionLoading }">
        {{ $t("associate") }}
      </span>
    </v-btn>

    <!--======================-->

    <!-- boite de dialogue liée au button "associer"-->
    <!---->
    <v-dialog v-model="dialog">
      <div class="containCard">
        <div>
          <v-card>
            <v-card-title>{{ $t("associate_title") }} </v-card-title>

            <v-card-text class="dialog-content">
              <span v-html="$t('associate_text', { email: this.email })"></span>

              <v-autocomplete
                v-if="loaded"
                v-model="associate_autocomplete_value"
                :items="uuid_list"
                :item-title="(item) => item.device_uuid"
                :loading="loading"
                @focus="getUUIDs"
                @input="getUUIDs"
                @change="getUUIDs"
                @select="updateSelectedUUID"
                @update:search="getUUIDs"
                dense
              >
              </v-autocomplete>
            </v-card-text>

            <v-card-actions>
              <v-btn class="bad-color" @click="dialog = false"> {{ $t("cancel") }}</v-btn>
              <v-btn style="color:#1c99d1" :disabled="!associate_autocomplete_value" @click="addSh(associate_autocomplete_value)">
                {{ $t("associate") }}</v-btn
              >
            </v-card-actions>

            <v-progress-linear v-if="addingSh" indeterminate>
            </v-progress-linear>
          </v-card>
        </div>
      </div>
    </v-dialog>
    <!--===================================================-->

    <v-snackbar :timeout="2000" v-model="errorTriggered" top left>
      <span style="color: white"
        >{{ $t("errorAssociation") }} {{ errorVal }}</span
      >
    </v-snackbar>
  </span>
</template>

<script>
//import * as Vue from 'vue';
//import ShowerHeadType from "./ShowerHeadType.vue";
import mitt from "mitt";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlus } from "@mdi/js";
//import { markRaw } from "vue";
//const ShowerHeadTypeNonReactive = markRaw(ShowerHeadType);

export default {
  name: "AssociateShowerHeadsButtons",
  inject: ["$apiHydrao", "$global_error"],
  components: {
    //ShowerHeadTypeNonReactive,
    SvgIcon,
  },

  created() {
    this.getUUIDs();
  },
  data: () => ({
    associate_autocomplete_value: "",
    showAssociate: false,
    addingSh: false,
    uuid_list: [],
    loaded: false,
    loading: false,
    selectedUUID: null,
    showAssociateDemo: false,
    demo_nb_shower_per_day: 1,
    demo_expiration_date: new Date(
      new Date().setMonth(new Date().getMonth() + 1)
    ),
    creatingDemo: false,
    dialog: false,
    dialogDemo: false,
    pathPlus: mdiPlus,
    errorVal: "",
    errorTriggered: false,

  }),
  props: {
    user_id: null,
    email: null,
    sh_grid_bus: mitt(),
  },
  computed: {
    userActionLoading: function () {
      return this.user_id == null;
    },
    userActionReady: function () {
      return !this.userActionLoading && !isNaN(parseInt(this.user_id));
    },
  },
  methods: {
    errorDetected(msg) {
      this.errorVal = msg;
      this.errorTriggered = true;
    },
    async addSh(uuid) {
      this.addingSh = true;

      let res = await this.$apiHydrao.associateShowerHead(this.user_id, uuid);
      if (!res.status || res.status != 200) {
        if (res.response && res.response.data && res.response.data.error) {
          switch (res.response.data.error) {
            case "AlreadyRegistered":
              this.errorDetected("AlreadyRegistered");
              this.$global_error.newError(
                this.$i18n.messages[this.$i18n.locale].already_registered
              );
              break;
            case "RegisterUnauthorized":
            this.errorDetected("RegisterUnauthorized");

              this.$global_error.newError(
                this.$i18n.messages[this.$i18n.locale].register_unauthorized
              );
              break;
            case "PlaceAccessDenied":
            this.errorDetected("PlaceAccessDenied");
              this.$global_error.newError(
                this.$i18n.messages[this.$i18n.locale].place_access_denied
              );
              break;

            default:
            this.errorDetected("default");
              this.$global_error.newError(res);
              break;
          }
        } else {
          this.$global_error.newError(res);
        }
      } else {
        this.sh_grid_bus.emit("shGridRefresh");
      }
      this.showAssociate = false;
      this.dialog = false;
      this.selectedUUID = "";
      this.associate_autocomplete_value = "";
      this.addingSh = false;
    },
    async addDemo(demo_nb_shower_per_day, demo_expiration_date) {
      this.creatingDemo = true;

      let res = await this.$apiHydrao.createDemo(
        demo_nb_shower_per_day,
        demo_expiration_date
      );
      if (!res) {
        this.$global_error.newError("empty response");
      } else if (!res.status || res.status != 200) {
        this.$global_error.newError(res);
      } else {
        await this.addSh(res.data.device_uuid);
      }
      this.showAssociateDemo = false;
      this.creatingDemo = false;
    },

    updateSelectedAccount(new_val) {
      window.setTimeout(() => {
        this.selectedAccount = new_val;
        this.add_child_account_autocomplete_value = new_val.email;
      }, 50);
    },

    async getUUIDs(event) {
      clearTimeout(this.getUUIDTimeout);
      this.getUUIDTimeout = setTimeout(async () => {
        this.loading = true;
        if(event && event.type =='input') {
          this.uuid_list = await this.$apiHydrao.getUUIDs(event.srcElement.value);
        } else {
          this.uuid_list = await this.$apiHydrao.getUUIDs();
        }
        
        this.loaded = true;
        this.loading = false;
      }, 800);
    },
    updateSelectedUUID(new_val) {
      window.setTimeout(() => {
      this.selectedUUID = new_val.device_uuid;
        this.associate_autocomplete_value = new_val.device_uuid;
      }, 50);
    },
  },
  i18n: {
    messages: {
      en: {
        associate: "Associate",
        associate_title: "Associate a new shower head",
        associate_text:
          "The shower head will be added in the main group of <strong>{email}</strong>",
        no_sh: "No results.",
        uuid_label: "UUID",
        cancel: "Cancel",

        already_registered: "Shower head already associated to this account",
        register_unauthorized:
          "Shower head already associated to another account",
        place_access_denied:
          "User cannot access place: it belongs to another user, or place does not exists.",

        associate_demo_title: "Associate a new demo shower head",
        associate_demo_text:
          "This shower head will generate new showers every day.",
        demo_nb_shower_per_day: "Number of showers per day",
        demo_nb_shower_per_day_error: "Must be a number (1 to 10)",
        demo_expiration_date: "Date on which the showers stop",
        associate_demo: "Add the demo",

        add_demo: "Add demo",
        errorAssociation: "Error occured during association  : ",

      },
      fr: {
        associate: "Associer",

        associate_title: "Associer un nouveau pommeau",
        associate_text:
          "Le nouveau pommeau sera ajouté dans le groupe principal de <strong>{email}</strong>",

        no_sh: "Aucun résultat.",
        uuid_label: "UUID",
        cancel: "Annuler",

        already_registered: "Pommeau déjà associer à ce compte",
        register_unauthorized: "Pommeau déjà associer à un autre compte",
        place_access_denied:
          "L'utilisateur n'a pas accès à l'emplacement: il appartient à un autre compte, ou n'existe pas.",

        associate_demo_title: "Associer un pommeau de démo",
        associate_demo_text:
          "Ce pommeau génèrera de nouvelles douches tous les jours.",
        demo_nb_shower_per_day: "Nombre de douches par jour",
        demo_nb_shower_per_day_error: "Doit être un nombre (1 à 10)",
        demo_expiration_date: "Date à laquelle les douches s'arrêtent",
        associate_demo: "Ajouter la démo",

        add_demo: "Ajouter démo",
        errorAssociation: "Une erreur est survenue lors de l'association : ",

      },
    },
  },
};
</script>
<style lang="scss" scoped>
.containCard {
  display: flex;
  justify-content: center;
}
.md-highlight-text {
  display: inline !important;
}

.uuid-margin {
  margin-left: 10px;
  margin-right: 10px;
}

.head-button {
  margin-left: 5px;
  margin-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
}
</style>
