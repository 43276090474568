<template>
  
  <v-btn variant="text" v-if="this.params.value" class="more-button"  style="color:#008DCC">
    <svg-icon type="mdi" :path="pathDotsHorizontal"></svg-icon>
  </v-btn>
</template>

<script>
import { defineComponent } from "vue";

import SvgIcon from "@jamescoyle/vue-icon";
import { mdiDotsHorizontal } from "@mdi/js";

export default defineComponent({
  name: "ShowerheadExpandRenderer",
  components: {
    SvgIcon,
  },
  data: function () {
    return {
      pathDotsHorizontal: mdiDotsHorizontal,
    };
  },
});
</script>

<style scoped>
.more-button {
  vertical-align: middle;
}
</style>
